﻿/*#
<div style="width:810px;">
    From productpage
    <div class="CardTeaser CardTeaser--image CardTeaser--right" style="background-image: url('/assets/images/Temp/bgDust.jpg');">
    <div class="CardTeaser-content CardTeaserContent--white CardTeaserContent--bottom">
        <a class="Card-linkWrapper" href="#">
            <h2 class="CardTeaser-heading Heading">Vår bäst-i-test dammsugare</h2>
            <p class="CardTeaser-paragraph">
                Vår Ultra One Green som är gjord av
                70 % återvunnen plast är vår miljö-vänligaste modell som tidigare i år blev utsedd till bäst-i-test av Testfakta.
                Vår Ultra One Green som är gjord av
                70 % återvunnen plast är vår miljö-vänligaste.
            </p>
            </a>
            <div class="Card-links">
                <a href="#" class="readMore">Läs mer <i class="Icon Icon--readMore"></i></a>

                <span class="Checkbox Card-checkbox">
                    <input id="cb14" type="checkbox" name="newsletter" class="Checkbox-input">
                    <label for="cb14" class="Checkbox-label">Jämför</label>
                </span>
            </div>
            <div class="Pricebox Pricebox--right u-borderBz">
                <ul class="List List--horizontal Pricebox-list u-marginAz">
                    <li class="List-item u-fontFamilyCondensed"><span class="Pricebox-price">2 490:-</span></li>
                    <li class="List-item"><button class="Button">Köp</button></li>
                </ul>
            </div>

    </div>
</div>
 From articlepage 
<div class="CardTeaser CardTeaser--image CardTeaser--right" style="background-image: url('/assets/images/Temp/elx_wellbeing_landing-visual.jpg');">
    <div class="CardTeaser-content CardTeaserContent--white CardTeaserContent--bottom">
        <a class="Card-linkWrapper" href="#">
            <h2 class="CardTeaser-heading Heading">Dammsugare för ren luft och roligare städning</h2>
            <p class="CardTeaser-paragraph">
                Bland vårt stora utbud kan du hitta en dammsugare som möter alla dina önskemål. Våra dammsugare är lättmanuvreade och håller en låg ljudnivår.
            </p>
        </a>
    </div>
</div>

</div>
<div style="width:395px;">
Text teaser

<div class="CardTeaser CardTeaser--text CardTeaser--center">
    <div class="CardTeaser-content CardTeaserContent--full">
        <a class="Card-linkWrapper" href="#">
            <h2 class="CardTeaser-heading Heading">HEPA filter eller microfilter?</h2>
            <p class="CardTeaser-paragraph">
                Olika typer av filter fyller olika funktioner. HEPA filter är specielt bra vid allergier. Microfilter kallas även hygien- eller miljöfilter. Fler frågor? Besök din butik och prata med våra experter.
            </p>    
            <span class="Button Button--dark Button--small parentHover">Din butik</span>  
       </a>
        
    </div>
</div>

</div>
*/
@import "../../common/variables.less";

.CardTeaser{
    width: 100%;
    background-size: cover;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
}

.CardTeaser--right{justify-content: flex-end;}
.CardTeaser--center{ justify-content:center;}

.CardTeaser--image{
    padding: @spaceXLarge;
}

.CardTeaser-content{
    width:100%;
    padding: @spaceLarge @spaceLarge @spaceMedium;
    align-self: center;

    @media @bpLarge{ 
        width:55%;
    } 
}

.CardTeaserContent--full{
    width:100%;
    padding: 0 @spaceMedium;

     @media @bpLarge{ 
          padding: 0 @spaceXLarge;
    }

}

.CardTeaserContent--white{   background: rgba(255, 255, 255, 0.8);}

.CardTeaserContent--bottom{
    align-self: flex-end;
}

.CardTeaser-paragraph{
    margin-bottom: @spaceXLarge;
}

.CardTeaser--text{
    text-align:center;
}