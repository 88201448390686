﻿/*#

The fixed box for compare
In styleguide the CompareIndicator is set to relative instead of fixed (inline) and top 200 is set to top 0 (inline)
Examples:
   
<div class="CompareIndicator" style="position:relative; top:0;">
    <a href="#" class="CompareIndicator-active">
        <div class="CompareIndicator-content inverted">
            <i class="Icon Icon--compare CompareIndicator-icon"></i>
            <h3 class="CompareIndicator-heading"><span class="u-hidden u-lg-block">Jämför</span></h3>
            <span class="CompareIndicator-text"><strong>5 produkter</strong></span>
        </div>
    </a>

    <a class="CompareIndicator-update" href="#">
        <div class="CompareIndicator-content inverted">
            <h3 class="CompareIndicator-heading"><span class="">Produkt tilllagd i jämför</span></h3>
            <span class="CompareIndicator-text"><strong>5 produkter</strong></span>
        </div>
    </a>

</div>
<br>
is-active
<div class="CompareIndicator is-active" style="position:relative; top:0;">
    <a href="#" class="CompareIndicator-active">
        <div class="CompareIndicator-content inverted">
            <i class="Icon Icon--compare CompareIndicator-icon"></i>
            <h3 class="CompareIndicator-heading"><span class="u-hidden u-lg-block">Jämför</span></h3>
            <span class="CompareIndicator-text"><strong>5 produkter</strong></span>
        </div>
    </a>

    <a class="CompareIndicator-update" href="#">
        <div class="CompareIndicator-content inverted">
            <h3 class="CompareIndicator-heading"><span class="">Produkt tilllagd i jämför</span></h3>
            <span class="CompareIndicator-text"><strong>5 produkter</strong></span>
        </div>
    </a>
</div>

*/

@import "../../common/variables.less";

.CompareIndicator {
    background: rgba(4,30,80,0.9);
    position: fixed;
    width: 90%;
    padding: @spaceXSmall 0;
    bottom: 0;
    z-index: 100;
    -webkit-transition: background ease-in-out 0.2s;
    -moz-transition: background ease-in-out 0.2s;
    -o-transition: background ease-in-out 0.2s;
    transition: background ease-in-out 0.2s;
    height: 45px;

    &:hover {
        background: rgba(4,30,80,1);

        .CompareIndicator-clear {
            opacity: 1;
        }
    }

    @media @bpMedium {
    }

    @media @bpXLarge {
        border-top: 1px solid @colorDarkGrey;
        padding: @spaceXSmall;
        height: auto;
    }

    .CompareIndicator-update {
        display: none;
    }

    &.is-active {
        opacity: .75;


        .CompareIndicator-update {
            display: block;
        }

        .CompareIndicator-active {
            display: none;
        }
    }
}

    .CompareIndicator-content {
        text-align: center;
    }

    .CompareIndicator-icon {
        font-size: 25/16em;
        margin-bottom: 15px;
    }

    .CompareIndicator-heading {
        font-size: 1em;
        margin-bottom: 5px;
        display: inline-block;

        @media @bpLarge {
            padding-bottom: @spaceXXSmall;
        }
    }

    .CompareIndicator-update {
    }

    .CompareIndicator-active {
    }

.CompareIndicator-clear {
    position: absolute;
    top: @spaceSmall;
    left: @spaceSmall;
    opacity: 1;

    @media @bpXLarge {
        padding: @spaceDefault;
    }

    @media @bpXLarge {
        left: -@spaceLarge;
        top: -@spaceLarge;
        opacity: 0;
    }

    &:hover {
        opacity: 1 !important;
    }


    .CompareIndicatorClear-trigger {
        padding: @spaceSmall;
        border-radius: 100%;
        background: #fd6b65;
        font-size: .5em;
        display: block;
        border: 2px solid @colorWhite;

        &:hover {
            border: 2px solid @colorOrange;
        }
    }
}



    @media all and (min-width: 1345px) {
        .CompareIndicator {
            width: 80px;
            top: 200px;
            right: 0;
            border: 1px solid @colorDarkGrey;
            border-right: 0;
            bottom: inherit;
            // background: @colorBlack;
            padding: @spaceSmall;
            border-radius: 6px 0 0 6px;
        }

        .CompareIndicator-icon {
            display: block;
            font-size: 30/16em;
        }

        .CompareIndicator-heading {
            display: block;
            border-bottom: @borderDotted @colorWhite;
            font-size: @textSizeMedium;
        }

        .CompareIndicator-text {
            font-size: @textSizeSmall;
        }
    }

    @media all and (min-width: 1445px) {
        .CompareIndicator {
            width: 120px;
        }

        .CompareIndicator-icon {
            display: block;
            font-size: 44/16em;
        }

        .CompareIndicator-heading {
            font-size: @textSizeLarge;
        }

        .CompareIndicator-text {
            font-size: @textSizeMedium;
        }
    }