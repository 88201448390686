﻿/*#
<div style="">
Main SubNav
 <nav class="SubNav u-hidden u-md-block u-marginGrid">
    <ul class="List List--horizontal List--gutterHlg">
        <li class="List-item">
            <a class="SubNav-link SubNavLink--transform" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNaviconElement--large SubNaviconElement--large">
                    <i class="subNav-icon Icon Icon--vacuum-original"></i>
                </div>
                <span>Golvdammsugare</span>
            </a>
        </li>
        
        <li class="List-item">
            <a class="SubNav-link SubNavLink--transform" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNaviconElement--large">
                    <i class="subNav-icon Icon Icon--vacuum-handheld subNavIcon--small"></i>
                </div>
                <span>Handdammsugare</span>
            </a>
        </li>
        
        <li class="List-item">
            <a class="SubNav-link SubNavLink--transform" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNaviconElement--large">
                    <i class="subNav-icon Icon Icon Icon--vacuum-central"></i>
                </div>
                <span>Centraldammsugare</span>
            </a>
        </li>
        
        <li class="List-item">
            <a class="SubNav-link SubNavLink--transform" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNaviconElement--large">
                    <i class="subNav-icon Icon Icon Icon--vacuum-pro"></i>
                </div>
                <span>Icon--vacuum-pro</span>
            </a>
        </li>

        <li class="List-item SubNavLink--transform">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNaviconElement--large">
                    <i class="subNav-icon Icon Icon Icon--vacuum-accessories subNavIcon--small"></i>
                </div>
                <span>Tillbehör</span>
            </a>
        </li>
    </ul>
</nav>

Main subnav in tablet:

    <nav class="SubNav SubNav--small u-hidden u-md-block">
    <ul class="List List--horizontal List--gutterHlg">
        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm u-transitionAsm SubNavIconElement--small SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon--vacuum-original"></i>
                    <span>Golvdammsugare</span>
                </div>
            </a>
        </li>
        

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small  SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon--vacuum-handheld"></i>
                    <span>Handdammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small  SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon Icon--vacuum-central"></i>
                    <span>Centraldammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small  SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon Icon--vacuum-pro"></i>
                    <span>Grovdammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small  SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon Icon--vacuum-accessories"></i>
                    <span>Tillbehör</span>
                </div>
            </a>
        </li>

    </ul>
</nav>

SubNav in footer:

    <div class="SubNav-footer">
    <nav class="SubNav SubNav--small u-hidden u-md-block">
    <ul class="List List--horizontal List--gutterHlg">
        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm u-transitionAsm SubNavIconElement--small SubNavIconElement--white SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon--vacuum-original"></i>
                    <span>Golvdammsugare</span>
                </div>
            </a>
        </li>
        

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small  SubNavIconElement--white SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon--vacuum-handheld"></i>
                    <span>Handdammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small  SubNavIconElement--white SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon Icon--vacuum-central"></i>
                    <span>Centraldammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small  SubNavIconElement--white SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon Icon--vacuum-pro"></i>
                    <span>Grovdammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small  SubNavIconElement--white SubNavIconElement--icon">
                    <i class="subNav-icon Icon Icon Icon--vacuum-accessories"></i>
                    <span>Tillbehör</span>
                </div>
            </a>
        </li>
       
      

    </ul>
</nav>
</div>


SubNav tags:
    <nav class="SubNav SubNav--small u-hidden u-md-block SubNav--boarderZ">
    <ul class="List List--horizontal List--gutterHlg">
        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm u-transitionAsm SubNavIconElement--small">
                    <i class="subNav-icon Icon Icon--vacuum-original"></i>
                    <span>Golvdammsugare</span>
                </div>
            </a>
        </li>
        

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small">
                    <i class="subNav-icon Icon Icon--vacuum-handheld"></i>
                    <span>Handdammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small">
                    <i class="subNav-icon Icon Icon Icon--vacuum-central"></i>
                    <span>Centraldammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small">
                    <i class="subNav-icon Icon Icon Icon--vacuum-pro"></i>
                    <span>Grovdammsugare</span>
                </div>
            </a>
        </li>

        <li class="List-item">
            <a class="SubNav-link" href="#">
                <div class="SubNav-iconElement u-transitionAsm SubNavIconElement--small">
                    <i class="subNav-icon Icon Icon Icon--vacuum-accessories"></i>
                    <span>Tillbehör</span>
                </div>
            </a>
        </li>
       
        

    </ul>
</nav>

    </div>
*/
@import "../../common/variables.less";
@import "../../components/atoms/Icon.less";

@subNavBGcolor: @colorSilkTint;
@subNavIconBorder: @colorMidGrey;
@subNavFontSize: 12/16em;


.SubNav {
	padding: 0;
	text-align: center;

	.List {
		margin: 0 0 @spaceMedium;

		@media @bpMedium {
			margin: 0 0 @spaceLarge;
		}

		.List-item {
			padding-left: @spaceSmall;
			padding-right: @spaceSmall;

			@media @bpLarge {
				padding-left: @spaceMedium;
				padding-right: @spaceMedium;
				padding-bottom: 0;
			}

			a {
				color: @colorText;

				&:hover {
					text-decoration: none;
				}


				span {
					display: block;
					text-align: center;
					
					font-family: @fontFamilyCondensed;
				}
			}
		}
	}
}

.SubNav--boarderZ {
	border: none;
}

.SubNavLink--transform {
	&:hover {
		.SubNav-iconElement {
			transform: scale(1.05);
		}
	}
}

.SubNav-iconElement {
	background-color: @subNavBGcolor;
	border: 1px solid @subNavIconBorder;
	border-radius: 100%;
	display: -webkit-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
	justify-content: center;
	height: 90px;
	width: 90px;
}

.SubNaviconElement--large {
	margin-bottom: 0.4em;
}

// smallLinks
.SubNav-footer {
	padding: @spaceLarge @spaceSmall @spaceSmall;
	background: @colorSilkTint;

	.List {
		margin-bottom: @spaceMedium;
	}

	@media print {
		display: none;
	}
}

.SubNav--small {
	.List {
		.List-item {
			padding-left: 0.2em;
			padding-right: 0.2em;
			padding-bottom: 0;
			vertical-align: bottom; 
		}
	}

	@media @bpMedium {
		.List {
			.List-item {
				padding-left: 0.2em;
				padding-right: 0.2em;
				padding-bottom: 0;
				vertical-align: bottom;
                a{
                    span{
                        font-size:11/16em;
                    }
                }
			}
		}
	}
}

.SubNavIconElement--small {
    border-radius: 2px;
    padding: 1.2em .6em;
    width: auto;
    height: auto;

    @media @bpMedium {
       // background-color: @subNavBGcolor;
       // border: 1px solid @subNavIconBorder;
        border-radius: 2px;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
        justify-content: center;
        width: auto;
        padding: 0 @spaceSmall;
        height: 38px;

        .subNav-icon {
            display: none;
        }

        &.SubNavIconElement--icon {
            height: 40px;

            .subNav-icon {
                display: flex;
            }
                span {
                    font-size: @subNavFontSize!important;
            }
        }
    }



    &:hover {
        transform: none;
        color: @colorWhite;
        background-color: @colorBlueRich;

    }

    .subNav-icon {
        font-size: 20/16em;
        margin-right: 0.4em;
        display: none;
    }

    span {
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
        align-self: center;
        line-height: 0;
    }
}


.SubNavIconElement--white {
	background-color: @colorWhite;

	&:hover {
	
	}
}


/* Sizes */
.subNav-icon {
	font-size: 55/16em;
	text-align: center;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-self: center;
}


.subNavIcon--small {
	font-size: 38/16em;
}

//

.is-no-flexwrap {
	.subNav-icon {
		display: inline-block;
	}


	.SubNavIconElement--small {
		* {
			margin-top: 8px;
		}

		span {
			display: inline-block !important;
		}
	}
}
