﻿/*#

See open hours and location

<div style="max-width:1260px; position:relative;">
    Desktop
        <div class="StoreInfo StoreInfo--bar u-marginGrid">
        <div class="StoreInfo-content">
            <ul class="List List--horizontal StoreInfo-List StoreInfoList--bar">
                <li class="List-item u-marginRmd"><a href="#"><h4 class="u-marginBz"><i class="Icon Icon--location u-marginRsm"></i>Din Butik: Götgatan 15, Stockholm</h4></a></li>
                <li class="List-item u-marginRmd"><i class="Icon Icon--clock"></i> <span class="StoreInfo-openHours">Öppet idag: 10-19</span></li>
                <li class="List-item u-marginRmd"><i class="Icon Icon--contact"></i> <span class="StoreInfo-phone">08-640 11 10</span></li>
                <li class="List-item">
                    <div class="StoreInfo-change StoreInfoChange--bar">
                        <span class="Select  Select--full">
                            <select class="Select-input">
                                <option>Byt butik</option>
                                <option>Stockholm/Sickla köpkvarter</option>
                                <option>Borås</option>
                                <option>Stockholm/Götgatan</option>
                                <option>Umeå</option>
                                <option>Göteborg</option>
                                <option>Falun</option>
                            </select>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
     
    </div>
     </div>
<div style="width:350px; position:relative;">
        Mobil
          <div class="StoreInfo">
            <i class="Icon Icon--close StoreInfo-close" data-module="toggler" data-toggler="contentElement: '.js-StoreInfoMob', cssClass: 'is-active'"></i>
            <div class="StoreInfo-content">
                <h3>Din butik</h3>
                <ul class="List StoreInfo-List">
                    <li class="List-item"><span class="StoreInfo-adress u-marginRmd">Götgatan 58, Stockholm</span><a class="StoreInfo-visit" href="#">Butikssida <i class="Icon Icon--readMore"></i></a></li>
                    <li class="List-item"><i class="Icon Icon--clock"></i> <span class="StoreInfo-openHours">Öppet idag: 10-19</span></li>
                    <li class="List-item"><i class="Icon Icon--contact"></i> <span class="StoreInfo-phone">08-640 11 10</span></li>
                </ul>
            </div>
            <div class="StoreInfo-change">
                <span class="Select  Select--full">
                    <select class="Select-input">
                        <option>Byt butik</option>
                        <option>Stockholm/Sickla köpkvarter</option>
                        <option>Borås</option>
                        <option>Stockholm/Götgatan</option>
                        <option>Umeå</option>
                        <option>Göteborg</option>
                        <option>Falun</option>
                    </select>
                </span>
            </div>
        </div>    

</div>

*/

@import "../../common/variables.less";

.StoreInfo-pop {
    position: absolute;
    background: @colorWhite;
    z-index: 20;
    left: -@spaceSmall;
    -webkit-box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
    width: 320px;
}

.StoreInfoPop--top {
    top: @spaceXLarge;
}


.StoreInfo {
    position: relative;
    padding: @spaceXSmall @spaceXSmall;

    @media print {
        display: none;
    }
}

.StoreInfo--bar {
    border-bottom: @borderDotted @borderColor;
    border-top: @borderDotted @borderColor;
    padding: 0;

    .StoreInfo-content {
        padding: 0;
    }
}

.StoreInfo-List {
    margin-top: 0;
    margin-bottom: @spaceMedium;

    @media @bpMedium {
        margin-top: @spaceSmall;
    }
}

.StoreInfoList-item {

    @media @bpMedium {
        vertical-align: middle;

        &:first-child {
            max-width: 50%;

            h4 {
                max-width: 100%; /* 1 */
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                word-wrap: normal !important; /* 2 */
            }
        }
    }


    @media @bpXLarge {
        &:first-child {
            max-width: 100%;
        }
    }

    @media @bpXLarge {
        max-width: 100%;
        margin-right: @spaceMedium !important;
    }
}


.StoreInfoList--bar {
    margin: 0;
    padding: @spaceXSmall;

    @media @bpLarge {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .List-item {

        @media @bpMedium {
            &:last-child {
                text-align: right;
                max-width: 50%;
            }
        }

        @media @bpLarge {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

    .StoreInfo-label {
        display: inline-block;
        margin-right: @spaceSmall;
    }

    .StoreInfo-content {
        font-family: @fontFamilyPrimary;

        @media @bpMedium {
            padding: @spaceMedium;
        }
    }

    .StoreInfo-heading {
        border-bottom: @borderDotted @borderColor;
    }

    .StoreInfo-change {
        width: 100%;

        @media @bpLarge {
            width: auto;
        }
    }

    .StoreInfoChange--bar {
        width: 100%;
        display: inline-block;

        @media @bpLarge {
            width: auto;
        }
    }


    .StoreInfo-visit {
        font-size: @textSizeMedium;
        color: @colorText;

        .Icon--readMore {
            font-size: @textSizeXSmall;
        }
    }

    .StoreInfo-adress {
        font-size: @textSizeMedium;
        margin-right: @spaceMedium;

        @media @bpMedium {
            margin-right: 0;
        }
    }

    .StoreInfo-openHours, .StoreInfo-phone {
        font-size: @textSizeMedium;

        @media @bpMedium {
            font-size: @textSizeMedium;
        }
    }

    .StoreInfo-close {
        position: absolute;
        top: @spaceXSmall;
        right: @spaceXSmall;
        cursor: pointer;
    }


    .is-no-appearance {
        .StoreInfo-label {
            position: relative;
            top: -15px;
        }
    }
