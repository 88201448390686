/*#

Group inputs together with a text label or button.

Examples:

<div style="max-width:600px;">
    <div class="InputGroup u-marginBsm">
        <span class="InputGroup-addon">@</span>
        <input class="InputGroup-input" type="text" placeholder="Username" />
    </div>
    
    <div class="InputGroup u-marginBsm"> 
        <input type="text" class="InputGroup-input" placeholder="Recipient's username" /> 
        <span class="InputGroup-addon">@example.com</span> 
    </div>
</div>

*/

@import "../../common/variables.less";

@InputGroup-addonBg: lighten(@colorBlueTint, 8%);
@InputGroup-border: @colorBlueTint;

.InputGroup { 
   
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.InputGroup-addon {
    display: inline-block; // ie10 requires i-b to work
 
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
}

// 1: uses important because inputs have been styled using attribute selector with higher specificity
.InputGroup-input {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    border: none !important; // (1) 
    border-radius: 0 !important; // (1)
}

.InputGroup-addon--button {
    -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
            align-self: stretch;
    border: none;
    border-radius: 0;
}

.vendor-ie-9, .vendor-ie-8 {
    .InputGroup { 
        display: table !important;
        width: 100%;
    }

    .InputGroup-addon, .InputGroup-input {
        display: table-cell !important;
        width: 1%;
    }

    .InputGroup-input {
        width: 100%;
    } 
}