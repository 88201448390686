/*#

Component for showing an accordion on page.
Can be used in conjunction with the "accordion" js-module.

<div class="Accordion" data-module="accordion" data-accordion="expandFirst: true">
    <h4 class="Accordion-header" data-name="panel1"><a href="#" class="Accordion-headerLink">Panel 1</a></h4>
    <div class="Accordion-panel">
        <p>
            Mauris mauris ante, blandit et, ultrices a, suscipit eget, quam. Integer
            ut neque. Vivamus nisi metus, molestie vel, gravida in, condimentum sit
            amet, nunc. Nam a nibh. Donec suscipit eros. Nam mi. Proin viverra leo ut
            odio. Curabitur malesuada. Vestibulum a velit eu ante scelerisque vulputate.
        </p>
    </div>
    <h4 class="Accordion-header" data-name="panel2"><a href="#" class="Accordion-headerLink">Panel 2</a></h4>
    <div class="Accordion-panel">
        <p>
            Sed non urna. Donec et ante. Phasellus eu ligula. Vestibulum sit amet
            purus. Vivamus hendrerit, dolor at aliquet laoreet, mauris turpis porttitor
            velit, faucibus interdum tellus libero ac justo. Vivamus non quam. In
            suscipit faucibus urna.
        </p>
    </div>
    <h4 class="Accordion-header" data-name="panel3"><a href="#" class="Accordion-headerLink">Panel 3</a></h4>
    <div class="Accordion-panel">
        <p>
            Sed non urna. Donec et ante. Phasellus eu ligula. Vestibulum sit amet
            purus. Vivamus hendrerit, dolor at aliquet laoreet, mauris turpis porttitor
            velit, faucibus interdum tellus libero ac justo. Vivamus non quam. In
            suscipit faucibus urna.
        </p>
    </div>
</div>

*/

@import "../../common/variables.less";
.Accordion {
    width: 100%;

    &.js-ready .Accordion-panel {
        display: none;
    }
}

.Accordion-header {
       &:after {
        font-size: 0.45em;
        content: "\e91a";
        font-family:HomeIcons;
        margin-right: @spaceXSmall;
        float: right;
        top: 6px;
        position: relative;
    }
    &.is-active {
        &:after {
            content: "\e91b";
        }
    }
    
    &:hover {
        text-decoration: none;
    }
}

.Accordion-headerLink {

}

.Accordion-panel {   
    &:after, &:before {
        content: '';
        display: table;
    }
    
    &.is-active {
        display: block;
    }
}

.AccordionPanel-absolute{
    position: absolute;
    width:100%;
    background: @colorWhite;
    z-index:99;
}

AccordionPanel-nav{
    margin:0;
}

@media print {
    .Accordion .Accordion-panel {
        display: block !important;
    }

    .Accordion-headerLink {
        &:before {
            display: none;
        }
    }
}


.AccordionHeader--hover {
    cursor: pointer;

    &:hover {
        color: @colorLinkHover;

        a {
            color: @colorLinkHover;
        }
    }

    &.is-active {
        &:hover {
            color: @colorLinkHover;

            a {
                color: @colorText;
            }
        }
    }
}

.Accordion--grey {
    .Accordion-header {
        background: @colorGrey;
        display: block;
        font-size: 18px;
        padding: 12px;

        &.Accordion-headerSub {
            font-size: 15px;
            padding: 4px 24px;
        }

        .Accordion-headerLink {
        }



        &.is-active {
            margin-bottom: 0;
        }
    }

    .Accordion-panel {
    }

    .Accordion-content {
        background: @colorGrey;
        padding: @spaceSmall;

        .Specification-List {
            font-size: 13px;
            padding-left: 14px;
        }
    }


    .Accordion-panel {
        &.is-active {
            margin-bottom: @spaceSmall;
        }
    }
}