@import "../../../../Assets/Styles/common/variables.less";

// Local variables
@checkoutMainHeader: 22px;

.Checkoutv3 {
	// General styling
	color: @colorBlueRich;

	a {
		color: @colorSoftBlack;
		text-decoration: underline;

		&:hover {
			border-bottom: none !important;
		}
	}

	hr {
		border-top: 1px solid @colorDarkGrey;
		margin: 16px 0 8px;
	}

	.Icon {
		margin-right: 6px;
		font-size: 20px;
	}

	.Checkout-header {
		.Checkout-header-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;

			h1, h2 {
				font-size: @checkoutMainHeader;
				color: @colorBlueRich;
			}

			span {
				color: @colorBlueRich;
			}
		}
	}

	.CheckoutAddress-infoBox {
		font-size: @textSizeSmall;
		color: @colorBlueRich;
		background-color: #DFE7EA;
		padding: @spaceDefault;
	}

	.Checkout-heading {
		font-size: 22px;
		padding-block: 0;
		color: @colorBlueRich;
	}

	.NextStepHeading {
		color: @colorBlueMid;
		display: flex;
		align-items: center;

		path {
			fill: @colorBlueMid;
		}
	}

	.InfoBlock {
		display: none;
		padding: 12px;
		margin-block: 32px;
		font-size: 13px;
	}

	.ArticleNumber {
		color: @colorDimGrey;
		font-size: 13px;
		display: inline-block;
		flex: 1;
	}

	.ArticleName {
		font-size: 15px;
	}

	input {
		border: 1px solid @colorBlueRich;
	}

	.Checkbox-label {
		font-weight: 600;
		font-size: 15px;
	}

	.Form-label {
		font-size: 14px;
		font-weight: 600;
		padding: 0 0 6px 0;
	}

	.ButtonWrapper {
		display: flex;
		justify-content: space-around;
		flex-direction: row;
		gap: 15px;

		@media @bpMedium {
			gap: 0px;
		}
	}

	.Button {
		font-weight: 600;
		width: 100%;

		&--grey {
			background-color: #EAEAEB;

			&:hover {
				background-color: @colorOrange;
			}
		}
	}

	.Form,
	.NextCheckoutStep,
	.PreviousCheckoutStep {
		border: 1px solid @borderColor;
		padding: 20px 16px;
		margin-bottom: 16px;
	}

	.PreviousCheckoutStep {
		display: flex;
		align-items: center;
		justify-content: space-between;

		span:hover {
			cursor: pointer;
		}
	}

	.field-validation-error {
		color: @colorOrange;
		font-weight: 600;
		font-style: initial;
		margin-top: 6px;
	}
	// Step specific styling

	.Checkout-Additions {

		.AdditionsImage-wrapper {
        
			padding-right: 15px;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-pack: center;
			justify-content: center;
			-ms-flex-align: center;
			align-items: center;
			
			img {   
				mix-blend-mode: multiply;
				filter: contrast(1);
				height: 56px;
				
			}
		}

		.AdditionsPrice {
			font-size: 15px;
		}
	}

	.Checkout-Shipping {
		.ShippingImage {
			height: 56px;
			margin-right: 15px;
		}

		.Checkout-ShippingDetails {
			display: block;
			margin-left: 18px;

			@media @bpSmall {
				display: inline-block;
				margin-left: 0;
			}
		}

		.Shipping-PickupInfo {
			margin-left: 18px;

			@media @bpSmall {
				margin-left: 0;
			}
		}
	}

	.Checkout-Payment {
		.List-item {
			color: @colorSoftBlack;
			font-size: 15px;
			background-color: @colorGrey;
			margin-bottom: 15px;
			padding: 8px !important;
		}

		.Radio-label:before,
		.Payment-Icon {
			margin-right: 12px;
		}

		a {
			color: @colorBlueRich;
		}

		.Checkbox-label {
			font-weight: 400;
			font-size: 16px;
		}

		#dropin-container.is-disabled {
			opacity:0.4;
			position: relative;
		}

			#dropin-container.is-disabled:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 1;
				background: transparent;
			}
		}
		// Aside/cart summary
		@phone: ~"only screen and (max-width: @{widthMedium}em)";


		.CheckoutSummary {
			width: 395px;
			z-index: 102;
			position: relative;
			-webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
			-moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
			-ms-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
			-o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
			transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);

			@media @phone {
				height: -webkit-calc(~"100dvh - 82px");
				height: -moz-calc(~"100dvh - 82px");
				height: -ms-calc(~"100dvh - 82px");
				height: calc(~"100dvh - 82px");
			}

			.CheckoutSummary-toggle {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 135px;
			}

			.CheckoutHeading,
			.CheckoutHeading-sub {
				margin-bottom: @spaceXLarge;
			}

			.CheckoutHeading:after {
				pointer-events: none;
				font-size: 0.45em;
				font-family: HomeIcons;
				margin-right: @spaceXSmall;
				float: right;
				top: 8px;
				position: relative;
				content: "\e91b";

				@media @bpMedium {
					content: "";
				}
			}

			.CheckoutSummary-contentWrapper {
				position: relative;

				@media @phone {
					display: flex;
					flex-direction: column;
					min-height: 100dvh;
					max-height: 100dvh;
				}
			}



			.CheckoutSummary-footer {
				margin-top: auto;
			}

			&--active {
				padding-top: 0 !important;
				transform: translateY(~"-webkit-calc(-100dvh + 116px)");
				transform: translateY(~"-moz-calc(-100dvh + 116px)");
				transform: translateY(~"-ms-calc(-100dvh + 116px)");
				transform: translateY(~"calc(-100dvh + 116px)");

				.CheckoutSummary-toggle {
					height: 72px;
				}

				.CheckoutHeading:after {
					content: "\e91a";
				}

				.CheckoutHeading {
					margin-bottom: @spaceLarge !important;
				}

				.CheckoutHeading-sub {
					display: none;
				}

				.Checkout-lineItems {
					overflow-y: auto;
				}
			}

			@media @phone {
				z-index: 2;
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				height: 116px;
				width: 100%;

				.CheckoutHeading {
					margin-bottom: @spaceXXSmall;
				}
			}

			.Accordion-header:after {
				@media @bpMedium {
					display: none;
				}
			}

			color: @colorSoftBlack;

			.Section--alt3 {
				padding: 12px 20px 20px 20px;
			}

			.CheckoutHeading {
				display: flex;
				justify-content: space-between;
				font-size: 22px;

				a {
					text-decoration: none;
				}
			}

			.Checkout-remove {
				color: @colorDimGrey;
			}

			.CheckoutSummary {
				position: fixed;
				bottom: 0;
			}

			.SummaryWrapper {
				display: flex;
				align-items: center;
			}

			.SummaryHeading {
				font-size: 15px;
				display: inline-block;
				margin-bottom: 0;
				margin-right: 4px;
			}

			.Summary-articleNr {
				color: @colorDimGrey;
				font-size: 15px;
				display: inline-block;
				flex: 1;
			}

			.SummaryImage {
				padding-right: 15px;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-pack: center;
				justify-content: center;
				-ms-flex-align: center;
				align-items: center;

				img {
					mix-blend-mode: multiply;
					filter: contrast(1);
					height: 56px;
				}
			}

			.Summary-numberOfArticlesWrapper {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				a {
					text-decoration: none;
					display: flex;
					align-items: center
				}

				.Icon {
					margin: 0;
				}

				.Summary-numberOfArticles {
					margin-inline: 10px;
				}
			}
		}

		.CheckoutSummary-footer {
			display: flex;
			flex-basis: 168px;
			align-items: flex-end;
		}

		.CheckoutSummary--hr {
			border-top: 1px solid @colorDarkGrey;
			margin: 0;

			@media @bpSmall {
				margin: 16px 0;
			}
		}
	}

	.Page-container--Receipt {
		max-width: 1420px;
	}

	.Receipt-header {
		width: 100%;
		height: 370px;
		position: relative;
		display: flex;

		img {
			min-width: 100%;
			height: 370px;
			visibility: visible !important;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}

		&--heading {
			position: absolute;
			margin: 0 auto;
			color: @colorWhite;
			bottom: 20px;
			left: 20px;
			max-width: 600px;
			padding: 16px;

			@media @bpMedium {
				top: 135px;
				left: 110px;
				padding: 0;
			}

			h2, p {
				color: @colorWhite;
			}
		}
	}

	.Receipt-content {
		max-width: 600px;
		margin: 0 auto;
	}

.Receipt-blocks {
	width: 600px;
	margin: 0 auto;
	padding: 12px;

	&--header {
		padding-left: 12px;
	}

	&--grey {
		background-color: @colorGrey;
	}

	&--space {
		width: 100%;
		height: 16px;
	}

	&--hr {
		border-top: 1px solid @colorDarkGrey;
	}

	p {
		a {
			border-bottom: 1px solid @colorBlack;

			&:hover {
				border-bottom: 1px solid @colorOrange;
				color: @colorBlack;
			}
		}
	}
}

.Receipt-block {
	position: relative;
	border-bottom: 1px solid @colorDarkGrey;
	margin-bottom: 16px;

	img {
		position: absolute;
		top: 1px;
		left: 0;
	}

	h5 {
		text-transform: none;
		margin-bottom: 0;
	}

	span {
		font-size: 13px;
		margin-top: 0;
	}

	&--header {
		border: none;
		margin-bottom: 0;

		p {
			font-size: 13px;
		}

		a {
			color: @colorBlack;
			font-size: 14px;
			font-weight: 400;
			text-decoration: underline;
		}
	}

	&--headerBlue {

		h4 {
			color: @colorBlueRich;
			font-size: 18px;
		}
	}

	&--articleDetails,
	&--articleDetailsNumbers {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: baseline;

		span {
			font-size: 14px;
			max-width: 160px;

			@media @bpSmall {
				max-width: 100%;
			}
		}
	}

	&--articleDetailsNumbers {
		span {
			font-size: 15px;
		}
	}

	&--articleDetailsRow {
		display: flex;
		justify-content: space-between;
		min-width: 100%;

		span {
			font-size: 14px;
		}
	}

	&--articleImage {
		padding-right: 15px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			mix-blend-mode: multiply;
			filter: contrast(1);
			height: 56px;
		}
	}

	&--articleName {
		font-size: 15px;
		display: inline-block;
		margin-bottom: 0;
		margin-right: 8px;
	}

	&--articleNumber {
		font-size: 13px;
	}

	&--text {
		font-size: 13px;
	}

	&--borderBottom {
		border-bottom: 1px solid @colorDarkGrey;
	}
}

	.Receipt-readMore {
		padding: 30px;
		background-color: @colorGrey;
	}
