/*#

Examples:

<div>
  <div class="Social">
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--facebook"></i> <span>Facebook</span></a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--instagram"></i><span>Instagram</span> </a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--pinterest"></i><span>Pinerest</span></a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--youtube"></i><span> Youtube</span></a>
    </div>
</div>

*/

@import "../../common/variables.less";

.Social {
    margin: 0 0 @spaceXSmall;
    display: inline-block;
    width: 100%;

    &.Social--share {
        display: inline-block;

        span {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }

    span {
        display: none;

        @media @bpMedium {
            display: inline;
        }
    }
}

.Social-link {
    padding: @spaceXSmall 0;
    align-self: center;
    margin: 0 @spaceSmall;

    @media @bpLarge {
        padding: 0;

        &:hover {
            text-decoration: none;

            * {
                color: @colorLinkHover !important;
            }
        }
    }
}

footer .Social-link {
    &:hover {
        text-decoration: none;

        * {
            color: inherit !important;
        }
    }
}

    .Social--menu {
        justify-content: space-around;
        margin: 0;

        .Social-link {
            span {
                display: none;

                @media @bpMedium {
                    display: inline-block;
                }
            }
        }
    }

    .Icon--social {
        font-family: HomeIcons;
        // font-size: 14/16em;
        color: @colorDimGrey;
        /*@media @bpMedium {
        font-size: 16/16em;
    }

    @media @bpLarge {
        font-size: 18/16em;
    }*/
        span {
            display: none;
            font-family: @fontFamilyPrimary;
            margin-left: .2rem;

            @media @bpMedium {
                display: inline;
            }
        }

        &:hover {
            color: @colorOrange;
        }
    }

    .Social-share {

        .Social-fade {
            padding: @spaceXXSmall;
            opacity: 0;
        }

        &:hover {
            .Social-fade {
                opacity: 1;
            }
        }
    }

    .is-no-flexbox {
        .Social {
            .Social-link {
                padding: 0 @spaceSmall;
            }
        }
    }

    .Social-wrapper {
        display: inline-block;
        float: right;

        @media @bpLarge {
            display: block;
            float: none;
        }

        @media print {
            display: none !important;
        }
    }

    .Social-list {
        margin-top: @spaceXSmall;
        display: inline;

        @media @bpLarge {
            display: block;
        }

        li {
            display: inline-block;
            margin-right: .4rem;
            margin-left: .4rem;

            &:last-child {
                margin-left: 0;
            }

            @media @bpLarge {
                margin-left: 0;
            }
        }

        .Social-hide {
            @media @bpLarge {
                display: none;
            }
        }
    }

    .Social-heading {
        margin-top: @spaceDefault;
        margin-right: @spaceXXSmall;

        @media @bpLarge {
            display: block;
            margin-right: 0;
        }

        span {
            display: none;

            @media @bpLarge {
                display: inline;
                padding-left: .35rem;
            }
        }
    }

    .Article {
        .Social-list {
            padding-left: 0;

            .Icon--social {
                color: @colorLink;

                &:hover {
                    color: @colorOrange;
                }
            }
        }
    }