/*#

The button classes are best applied to links and buttons.
These components can be used in forms, as calls to action, or as part of the general UI of the site/app.

Examples:

Default button
<div>
    <button class="Button">Button</button> 
    <a href="#" class="Button">Link Button</a>    
</div>
Default button Small
<div>
    <button class="Button Button--small">Button</button> 
    <a href="#" class="Button Button--small">Link Button</a>    
</div>

The dark side of the moon button

 <div>
    <button class="Button Button--dark">Button</button>
    <a href="#" class="Button Button--dark">Link Button</a>
</div>

The dark side of the moon button Small

 <div>
    <button class="Button Button--dark Button--small">Button</button>
    <a href="#" class="Button Button--dark Button--small">Link Button</a>
</div>

Disabled button with class

<div>
 <button class="Button Button--inactive Button--small">Button</button>
    <a href="#" class="Button Button--inactive Button--small">Class Inactive</a>
</div>

Disabled button with disabled attr

<div>
    <button class="Button Button--small">Disable</button>
</div>

Inverted Button

<div style="background-color: #222; padding:5px;">
    <button class="Button Button--inverted">Button</button> 
    <a href="#" class="Button Button--inverted">Link Button</a>    
</div>

Inverted Button Small

<div style="background-color: #222; padding:5px;">
    <button class="Button Button--inverted Button--small">Button</button> 
    <a href="#" class="Button Button--inverted Button--small">Link Button</a>    
</div>

Button Wide

<div style="background-color: #222; padding:5px;">
    <button class="Button Button--wide">Button</button> 
    <a href="#" class="Button Button--wide">Link Button</a>    
</div>
*/

@import "../../common/variables.less";

@Button-Color: @colorBlueRich;
@ButtonColor--hover: @colorOrange;
@Button-Padding: .5em 1.3em;

@Button-ColorDark: @colorSilkRich;
@ButtonColor--hoverDark: @colorWhite;


button, .Button {
    background: none;
    border: none;
    transition: background ease-in-out .3s, border ease-in-out .2s;

    &&--inverted {
        background-color: @colorWhite;
        border: 2px solid @Button-Color; // every button type have borders so that they are all in the same height
        color: @Button-Color;

        &:hover, &.is-active {
            border-color: @ButtonColor--hover;
            background-color: @ButtonColor--hover;
            color: @colorText;
        }
    }
}

.Button, .FormSubmitButton {
    background: transparent;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    font-size: @Button-textSize;
    line-height: 1em; // adjusts so that link button and <button> is equal height
    vertical-align: middle;
    -webkit-appearance: none;
    white-space: normal;
    border-radius: 2px;
    height: 45px;
    // styling
    color: @colorTextWhite;
    padding: 0 1.3em;
    background-color: @Button-Color;
    border: 2px solid @Button-Color; // every button type have borders so that they are all in the same height
    &:hover, &.is-active {
        background: @ButtonColor--hover;
        border-color: @colorOrange;
        text-decoration: none;
        color: @colorWhite;
    }

    &:focus {
        outline: none;
    }

    @media @bpMedium {
        padding: @Button-Padding;
    }
}

.Button--full {
    padding-left: .3em;
    padding-right: .3em;
    width: 100%;
}

.Button--small {
    font-size: @textSizeSmall;
}

.Button--xsmall{
  font-size:@textSizeSmall;
}

.Button--mini {
    font-size:@textSizeSmall;
    padding-left: 16px;
    padding-right: 16px;
    height: 32px;
}
.Button--narrow {
    height: 32px;
}

.Button--dark, .Button--dark {
    background-color: @Button-ColorDark;
    border: 2px solid @Button-ColorDark;
    &:hover, &.is-active {
        border-color: @colorSilkRich;
        background-color: @ButtonColor--hoverDark;
        color: @colorSilkRich;
    }
}

.Button--light {
    background-color: @colorWhite;
    border: 2px solid @colorWhite; // every button type have borders so that they are all in the same height
    color: @colorBlueRich;

    &:hover, &.is-active {
        border-color: @colorOrange;
        background-color: @colorOrange;
        color: @colorWhite;
    }
}

.Button--outlined {
    background-color: transparent;
    border: 2px solid @colorBlueRich;
    color: @colorBlueRich;
    &:hover {
        border-color: @colorOrange;
        color: @colorOrange;
    }
}

.Button--plain {
    background-color: transparent;
    border: none;
    font-family: @fontFamilyCondensed;
    color: @colorSoftBlack;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 36px 8px 16px;

    &:after {
        content: "";
        position: absolute;
        width: 2.2em;
        top: 17px;
        bottom: 0px;
        right: 16px;
        background-position: center;
        pointer-events: none;
        z-index: 2;
        font-family: HomeIcons;
        content: "\e91a";
        font-size: 7px;
    }
    
    &:hover, &.is-active {
        color: @colorSoftBlack;
        background: transparent;
        text-decoration: underline;
    }
    
    &.is-active {
        
        &:after {
            content: "\e91b";
        }
    }

}

.Button--moss {
    background-color: @colorMossMid;
    border: 2px solid @colorMossMid;


    &:hover, &.is-active {
        border-color: @colorMossMid;
        background-color: @colorWhite;
        color: @colorMossMid;
    }
}

.Button--filter {
    background-color: @colorGrey;
    color: @colorBlueRich;
    border: 2px solid @colorGrey;

    &:hover, &.is-active {
    }

    &--activeBlue {
        &:hover, &.is-active {
            border-color: @colorBlueRich;
            background-color: @colorBlueRich;
            color: @colorWhite;
        }
    }
}

.Button--grey {
    background-color: @colorGrey;
    color: @colorBlueRich;
    border: 2px solid @colorGrey;

    &:hover, &.is-active {
    }

    &--activeBlue {
        &:hover, &.is-active {
            border-color: @colorBlueRich;
            background-color: @colorBlueRich;
            color: @colorWhite;
        }
    }
}


.Button--wide {
    min-width: 300px;
}

a {
    span {
        &.Button {
            height: auto;
            min-height: 45px;
        }
    }

    &:hover {
        span {
            &.Button {
                background: @ButtonColor--hover;
                border-color: @colorBlueRich;
                text-decoration: none;
                color: @colorBlueRich;
            }
        }
    }
}

.Button--right {
    float: right;
}


.Button--inactive, button:disabled {
    background-color: #ccc;
    border: 2px solid #ccc; // every button type have borders so that they are all in the same height
    padding: .4em 0.3em;

    &:hover, &.is-active {
        border-color: #ccc;
        background-color: #ccc;
        cursor: default;
    }
}


.FormSubmitButton {
    float: right;
    clear: both;
}
/*needed to do this because of disabled padding*/
.Button-keep-padding {
    padding: @Button-Padding;
}

button:disabled.Button-keep-padding {
    padding: @Button-Padding;
}