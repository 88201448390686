﻿/*#
<div style="max-width:400px;">
    <details class="Saldo">
        <summary class="Saldo-header">
	        <i class="Icon Icon--location u-marginRxxsm"></i> <span class="u-">Välj butik</span>
        </summary>
        <div class="Saldo-details">
	        <ul class="List List--bordered List--gutterVmd Saldo-list">
		        <li class="List-item Saldo-listItem u-borderAz u-paddingTxsm u-paddingBxxsm">
			        <strong>Lagerstatus i annan butik</strong>
		        </li>
		        <li class="List-item Saldo-listItem Saldo-listItem--selectable">
			        <i class="Badges-symbol Badges-symbol--instock u-marginRxsm"></i> Alingsås <span class="Saldo-quantity">1 st</span>
		        </li>
		        <li class="List-item Saldo-listItem Saldo-listItem--selectable">
			        <i class="Badges-symbol Badges-symbol--outofstock u-marginRxsm"></i> Norrtälje <span class="Saldo-quantity Saldo-quantity--zero">slutsåld</span>
		        </li>
		        <li class="List-item Saldo-listItem Saldo-listItem--selectable">
			        <i class="Badges-symbol Badges-symbol--instock u-marginRxsm"></i> Stockholm, Götgatan <span class="Saldo-quantity">5 st</span>
		        </li>
		        <li class="List-item Saldo-listItem Saldo-listItem--selectable">
			        <i class="Badges-symbol Badges-symbol--outofstock u-marginRxsm"></i> Stockholm, Kronoberg <span class="Saldo-quantity Saldo-quantity--zero">slutsåld</span>
		        </li>
		        <li class="List-item Saldo-listItem Saldo-listItem--selectable">
			        <i class="Badges-symbol Badges-symbol--instock u-marginRxsm"></i> Stockholm, Sickla <span class="Saldo-quantity">3 st</span>
		        </li>
		        <li class="List-item Saldo-listItem Saldo-listItem--selectable">
			        <i class="Badges-symbol Badges-symbol--sooninstock u-marginRxsm"></i> Varberg <span class="Saldo-quantity">snart i lager</span>
		        </li>
	        </ul>
	        <ul class="List List--bordered List--gutterVmd Saldo-list">
		        <li class="List-item Saldo-listItem u-borderAz u-paddingTxsm u-paddingBxxsm">
			        <strong>Övriga butiker, kontakt för saldo</strong>
		        </li>
		        <li class="List-item Saldo-listItem">
			        <i class="Icon Icon--contact u-marginRxsm"></i> Borås <span class="Saldo-quantity">0322-12380</span>
		        </li>
		        <li class="List-item Saldo-listItem">
			        <i class="Icon Icon--contact u-marginRxsm"></i> Emmaboda <span class="Saldo-quantity">0331-27180</span>
		        </li>
		        <li class="List-item Saldo-listItem">
			        <i class="Icon Icon--contact u-marginRxsm"></i> Falun <span class="Saldo-quantity">0471-10291</span>
		        </li>
	        </ul>
        </div>
    </details>
</div>
*/
@import "../../common/variables.less";
@saldoBorder: #aaa;

.Saldo {
    margin-bottom: @spaceMedium;

    summary {
        cursor: pointer;
        position: relative;

        &::-webkit-details-marker {
            display: none;
        }

        &:before {
            display: none;
        }

        &:after {
            color: @colorOrange;
            content: "\e91a";
            font-family: HomeIcons;
            font-size: 8px;
            position: absolute;
            right: 18px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &[open] > summary {
        &:after {
            content: "\e91b";
        }
    }
}

.Saldo-header {
    border: 1px solid @borderColor;
    padding: @spaceXSmall 38px @spaceXSmall @spaceXSmall;

    @media @bpLarge {
        padding-left: @spaceSmall;
    }
}

.Saldo-headerText {
    color: @colorMidGrey;
}

.Saldo-details {
    border: 1px solid @borderColor;
    border-top: none;
    font-size: @textSizeSmall;
    max-height: 300px;
    overflow: auto;

    @media @bpLarge {
        max-height: 320px;
    }
}

.Saldo-list.List {
    margin-top: 0;

    > .Saldo-listItem {
        padding: @spaceXSmall 38px @spaceXSmall @spaceSmall;

        &:last-child {
            padding: @spaceXSmall 38px @spaceXSmall @spaceSmall;
        }

        &, &:last-child {
            @media @bpLarge {
                padding-left: @spaceMedium;
            }
        }
    }
}

.Saldo-listItem {
    &:extend(.u-cf all);

    &&--selectable {
        &:hover {
            cursor: pointer;
            background-color: @colorGrey;
        }
    }
}

.Saldo-quantity {
    float: right;
    font-size: @textXSmall;

    &--zero {
        color: #aaa;
    }

    .Saldo-header & {
        line-height: 1.55rem;
    }
}

/*.Saldo {
    margin-top: @spaceSmall;
    position: relative;
    z-index: 25;
    border-top: 1px solid @saldoBorder;
    border-bottom: none;
    min-height: 110px;
     @media @bpMedium{
           min-height: 115px;
    }
    @media @bpLarge{
           min-height: 85px;
    }
}

.Saldo-wrapper {
    @media @bpMedium {
        background: @colorWhite;
        width: 100%;
    }
}

.Saldo-accordion {
    position: relative;
}

.Saldo-header {
    border-bottom: 1px solid @saldoBorder;
    border-left: 1px solid @saldoBorder;
    border-right: 1px solid @saldoBorder;
    padding: 0.3em 0.4em;
    margin: 0;
    cursor: pointer;
    font-size: @textSizeMedium;

    &:hover {
        &:after {
            color: @colorLinkHover;
        }
    }

    &:last-child {
        border-bottom: none;
        background: red;
    }

    &:hover {
        .Accordion-headerLink {
            color: @colorLinkHover;
        }
    }

    .Icon {
        width: 15px;
        text-align: center;
        display: inline-block;
        position: relative;
        top: .15em;
    }
}

.Saldo-accordionPanel {
    border-left: 1px solid @saldoBorder;
    border-right: 1px solid @saldoBorder;
    position: absolute;
    z-index: 27;
    background: rgb(255, 255, 255);
    width: 100%;
}

.Saldo-text {
    margin: 0;
    padding: @spaceSmall;
    border-bottom: 1px solid @saldoBorder;
}

.Saldo-list {
    margin: 0;
    max-height: 450px;
    overflow-y: auto;
}

.Saldo-listItem {
    padding: .4em @spaceSmall !important;

    &:last-child {
        border-bottom: 1px solid @saldoBorder;
    }
}

.Saldo-quantity, .Saldo-phone {
    float: right;
    color: @colorTextLight;
    font-size: @textSizeSmall;
}

.Saldo-info {
    background: @colorWhite;
    background: @colorBlueTint;
    padding: @spaceXSmall;
    border-bottom: 1px solid @saldoBorder;
}*/
