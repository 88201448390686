﻿@import "../../../../Assets/Styles/common/variables.less";

.StorePage {
	.Page-container {
		padding-top: @spaceXLarge;
		padding-bottom: @spaceXLarge;

		@media @bpMedium {
			padding-top: @space4XL;
			padding-bottom: @space4XL;
		}
	}

	&--contacts {
		.owl-pagination {
			margin-top: 20px;
			padding: 16px 0;
		}

		.owl-page {
			width: 12px;
			height: 12px;
			border-radius: 6px;
			margin: 0 4px;
		}
	}

	&--heroBlock {
		.Page-container {
			@media @bpMedium {
				.u-paddingVmd;
			}
		}

		.StoreHeroBlock__Content {
			padding: 186px 20px 40px 20px;

			@media @bpMedium {
				padding: 0;
			}
		}
	}

	&--galleryImages .Gallery {
		.Grid-cell {
			aspect-ratio: 1;
		}
		a.Gallery-image {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	.StreetView, .StreetView iframe {
		width: 100%;
		height: 100%;
	}

	.Store-contact .Map {
		width: 100%;
		height: 100%;
	}

	&-contact {
		width: 100%;
		height: 100%;
		padding: 20px 0;

		> a, > a > .Map {
			height: 100%;
			width: 100%;
		}

		&--icon {
			width: 32px;
			margin-right: 16px;
		}
	}
}
