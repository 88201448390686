@import "../../common/variables.less";

.AspectRatio {
	position: relative;
}

.AspectRatio--square:before {
	content: "";
	display: block;
	padding-top: 100%;
}

.AspectRatio--sixteen-nine:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

.AspectRatio > * {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100% !important;
	width: 100% !important;
}

