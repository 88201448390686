﻿/*#

Generic card for displaying excerpts from other content pages.

Examples:

<div style="max-width:395px">
    Product card
   <div class="Card">
   <div class="Card-header">
        <img class="Card-logo" src="/Assets/Images/Logo/electroluxPlogo.png" />
        <i class="Icon Icon--wish-active Card-wish"></i>
    </div>
    <div class="u-flexAuto">
    <a class="Card-linkWrapper" href="#">
        <div class="CardMedia--center">

            <img src="/Assets/Images/Temp/vaccum.png" />
        </div>
        <div class="Card-content">

            <span class="Card-articleNr">zuoorgw+</span>
            <h3 class="Card-heading">Liten och lätthanterlig dammsugare med hygienfilter</h3>
            <span class="Card-energiClass"><img src="/Assets/Images/Temp/energy.png" /></span>
            <ul class="List List--vertical List-bullet Card-list">
                <li class="List-item u-textTruncate">Mjuka gummihjul</li>
                <li class="List-item u-textTruncate">AeroPro 3-i-1 munstycke</li>
                <li class="List-item u-textTruncate">Höjd x Bredd x Djup: 285 x 305 x 395 mm</li>
            </ul>
        </div>
    </a>
    </div>
    <div class="Card-links">
        <span class="Checkbox Card-checkbox">
            <input id="cb112" type="checkbox" name="check" class="Checkbox-input">
            <label for="cb112" class="Checkbox-label">Jämför</label>
        </span>
        </div>
     </div>
</div>

*/


@import "../../common/variables.less";

@Card-defaultBackground: @colorWhite;
@Card-padding: @spaceMedium;

.Card {
    padding: 0 @Card-padding @Card-padding @Card-padding;
    background: @Card-defaultBackground;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
}

.Card--campaign {
    box-shadow: 0px 0px 21px 2px rgba(0,0,0,0.65);
}

.Card-media {
    margin-top: -@Card-padding;
    margin-right: -@Card-padding;
    margin-left: -@Card-padding;
    margin-bottom: @Card-padding;

    img {
        width: 100%;
    }
}

.CardMedia--center {
    align-self: center;
    position: relative;

    img {
        display: block;
        margin: 0 auto;
        max-width: 180px;
        max-height: 180px;
        padding: @spaceSmall 0;
    }

    @media @bpMedium {
        .is-no-flexbox & img {
            display: block;
            left: 50%;
            margin: 0 auto;
            max-height: 180px;
            position: absolute;
            top: 50%;
            transform: perspective(1px) translateZ(0) translate(-50%, -50%);
        }

        img {
            padding: 0;
        }
    }
}

.Card-articleNr {
    font-size: @textSizeXSmall;
    text-transform: uppercase;
    display: inline-block;
}

/*.Card-prisjakt {
    position: absolute;
    right: -@spaceXSmall;
    top: 2*@spaceLarge;
    z-index: 1;
}*/

.Card-prisjakt {
    position: absolute;
    right: -@spaceLarge;
    top: @spaceXSmall;
    z-index: 1;

    .Card--campaign & {
        top: 108px;
    }
}

.Card-wifi {
  position: absolute;
  top: 0;
  width: 20px;
  top: -20px;
}

.Card-fiveYear {
    position: absolute;
    width: 45px;
    top: 3em;
    max-width: 12%;
    z-index: 10;

    @media @bpMedium {
        width: 50px;
        max-width: 20%;
    }

    @media @bpXLarge {
        width: 65px;
        max-width: 20%;
    }
}

.Card-ourFavourite {
    position: absolute;
    width:65px;
    top: 2.5em;
    max-width: 12%;
    z-index: 10;
    left: 0;
}

.Grid-cell {
    &.u-lg-size1of4 {
        .Card-fiveYear {
            @media @bpXLarge {
                max-width: 15%;
            }
        }

        .Card-logo {
            @media @bpXLarge {
                top: -5px;
                max-height: 16px;
            }
        }
    }
}
//
.Card-ProductPage {
    position: relative;
    padding: @spaceMedium;
    border: 1px solid @colorGrey;

    .Card-header {
        display: flex;
        justify-content: space-between;
    }


    .Card-prisjakt {
        position: absolute;
        top: @spaceXLarge;
    }

    &.promotionRibbonHugger {
        .Card-prisjakt {
            top: 8em;
        }
    }

    .CardLogo-link {
        position: relative;
        z-index: 10;
    }

    .Card-logo {
        margin: 0;
    }

    .Card-wish {
        margin-top: 0;
    }

    .Slider {
        @media print {
            display: block !important;
            height: 100%;
        }
    }

    .CardProductPage-printImage {
         display: none;

        @media print {
            display: block;
            height: 100%;
        }
    }
}

//

.Card-review {
    display: inline-block;
}

.CardReview-wrapper {
    position: relative;
    width: 60px !important;
}

.CardReview-stars {
    top: 3px;
    position: absolute;
    font-size: 14px !important;
    overflow: hidden;
    color: @colorBlueMid !important;

    &.is-active {
        color: @colorOrange !important;
    }
}

.CardReview-average {
    margin-left: 65px;
    font-size: @textSizeXSmall;
}

.Card-links {
    margin-bottom: @spaceXSmall;

    @media print {
        display: none;
    }
}

.Card-body {
    flex-direction: column;
    position: relative;

    > .Card-linkWrapper {
        align-self: center;

        @media @bpSmall {
            height: 180px;
        }
    }
}

.Card-content {
    position: relative;
}

.Card-header {
    position: relative;
}

.Card-wish, .Card-compare {
    font-size: @textSizeXXLarge;
    cursor: pointer;
    z-index: 10;
    position: relative;
    margin-left: @spaceXXSmall;
}

.Card-ProductPage {
    .Card-wish {
        margin-top: 0;
    }
}

.Card-logo {
    width: auto;
    float: left;
    position: relative;
    top: 2px;
    max-height: 22px;
    max-width: 80px;
    display: inline-block;
    margin: @spaceMedium @spaceMedium 0 0;
}

.CardLogo-link {
    &:hover {
        .Card-logo {
            opacity: .6;
        }
    }
}

.Card-energiClass{
  img.svg{
    max-height:40px;
    height:40px;
  }
}

.Card-linkWrapper {
  color: @colorText;

  &:hover {
    + .Card-links {
      .readMore {
        color: @colorLinkHover;
      }
    }

    text-decoration: none;
    color: @colorText;

    h3, .Heading {
      color: @colorText;
    }
  }

  @media @bpMedium {
    min-height: 180px;
  }
}

.Card-list {
    margin-top: 0.45em;
}

//
.is-no-flexbox {
    .Card {
        .Card-content {
            min-height: 245px;
        }
    }
}

.promotionRibbonHugger {
    position: relative;
}

.Card-AR-wrapper {
    background: @colorBlueRich;
    padding: @spaceSmall;

    .Card-AR {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor:pointer;


        .Card-AR-text {
            color: @colorWhite;
        }

        img {
            max-height: 30px;
        }
    }
}

.Card-AR-Pulse {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  max-width: 60px;

  span {
    text-align: center;
    font-size: 12px;
  }
}

.Card-AR-image {
  margin: 0 auto;
  border-radius: 80px;
  max-width: 40px;
  animation: pulse 2s infinite;
  -moz-animation: pulse 2s infinite; /* Firefox */
  -webkit-animation: pulse 2s infinite; /* Safari and Chrome */
  -o-animation: pulse 2s infinite; /* Opera */
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    }

    70% {
      transform: scale(1.1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}
