﻿/*#
Benefit

<div style="width:400px; position:relative;">
  
       <div class="ListHeader">
            <div class="Grid">
                <div class="Grid-cell u-md-size1of3 u-hidden u-md-flex">
                    <button class="Button Button--dark Button--small" data-module="menu" data-menu="contentElement: '.js-showFilter'"><i class="Icon Icon--filter"></i> Filtrera</button>
                </div>
                <div class="Grid-cell u-md-size1of3 u-size1of1">
                    <h2 class="Section-heading">23 produkter</h2>
                </div>

                <div class="Grid-cell u-size1of2 u-md-hidden">
                    <button class="Button Button--dark Button--small" data-module="menu" data-menu="contentElement: '.js-showFilter'"><i class="Icon Icon--filter"></i> Filtrera</button>
                </div>

                <div class="Grid-cell u-size1of2 u-md-size1of3">
                    <span class="Select ListHeader-select">
                        <select class="Select-input">
                            <option>Sortera efter</option>
                            <option>Lägsta pris</option>
                            <option>Högsta pris</option>
                            <option>Nyinkommet</option>
                        </select>
                    </span>
                </div>
            </div>
        </div>

</div>

*/

@import "../../common/variables.less";

.ListHeader {
    margin-bottom: @spaceMedium;
}

.ListHeader-select {
}

