﻿.Navigation-item {
    display: inline-flex;
    padding: 10px 10px;
    border:2px solid transparent;
    &:first-child{
        padding-left: 0;
    }

    &.is-active:hover {
        > .Navigation-subList {
            display: block;
        }
    }

    &.sectionDeviderLeft:before {
        border-left: 1px solid @colorMidGrey;
        padding-left: @spaceMedium;
        content: '';
    }

/*    &.sectionDeviderRight:after {
        border-right: 1px solid @colorMidGrey;
        padding-right: @spaceMedium;
        content: '';
    }*/
}

.Navigation-list {
    list-style: none;
    padding-left: 0;
    margin-top: 0;

    &.Navigation-listThumb {
        margin: 0;
    }
    //&:after {
    //    font-family: @Icon-fontFamily;
    //    content: "\e93f";
    //    position: absolute;
    //    top: 3px;
    //    right: 5px;
    //    font-size: 18px;
    //}
}

.Navigation-subCategoryTopLink {
    font-size: @textLarge;
    margin-bottom: 5px;
}

.Navigation-subCategoryLink {
    font-size: @textSmall;
    font-family: @fontFamilyPrimary;
    line-height: 22px;
}

.Navigation-subList {
    background: @colorWhite;
    padding: 18px 24px;
    padding-top: 15px;
     display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 47px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    z-index: 50;
    border: 1px dashed transparent;
    border-top-width: 7px;
}

.Navigation-category {
    a {
        color: @colorBlueRich;
        display: block;

        &:hover {
            color: @colorBlueMid;
        }
    }
}
//
.Navigation-highlightOverlay {
    transition: ease-in-out .3s;
  //  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 45%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
}


.Navigation-highlightText {
    transition: background-color ease-in-out .2s;
    background-color: transparent;
    position: absolute;
    bottom: 0px;
    padding: 5px;
    color: @colorWhite;
    z-index: 10;
    font-size: @textMedium;

    @media @bpLarge {
        
    }
}


.Navigation-highlight {
    position: relative;
    margin-bottom: 10px;
    transition: background ease-in-out .3s, color ease-in-out .2s;

    &:hover {
        .Navigation-highlightText {
            color: @colorBlueRich;
            background-color: rgba(255,255,255,0.8);
            border-top-right-radius: 6px;
        }

        .Navigation-highlightOverlay {
        }
    }
}

//
.ww{
    width: auto;
   padding: 0;
   aspect-ratio: unset;
}
.Navigation-subListCard {

    .ww {
        aspect-ratio: 195/82;
        width: 33%;
        display: inline-block;
        padding: 10px;

        .Navigation-offer, .Navigation-highlightTeaser {
            margin-bottom: 0;
        }

        .Navigation-highlightTeaser {
            aspect-ratio: 195/82;
            overflow: hidden;

            img {
                position: absolute;
            }

            .Navigation-highlightText {
                padding: 10px;
                left: 2px;
            }
        }
    }

    .Navigation-highlightText {
        font-size: @textLarge;
    }
}

.Navigation-offer {
    background: @colorBlueTint;
    padding: 18px 10px;
    margin-bottom: 10px;

    .Navigation-offerHeading {
        color: @colorBlueRich;
    }

    li {
        font-family: @fontFamilyPrimary;

        a {
            color: @colorBlueRich;
        }
    }
}

    .Navigation-subListCard {
        .Navigation-offer {
            aspect-ratio: 195/82;
        }
    }

.Navigation-menuThumbnail {
    opacity: 1;
    aspect-ratio: 195/82;
    position: relative;
    overflow: hidden;


    .Navigation-menuThumbnailImage {
        transition: transform ease-in-out .4s;
        background-position: top center;
        background-size: cover;
        transform: scale(1);
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
    }

    &.Navigation-highlight {
        margin-bottom: 0;
    }

    .Navigation-listThumbHeading {
        transition: background-color ease-in-out .4s;
        color: @colorWhite;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        z-index: 10;
        padding: 10px;
        background-color: transparent;
        text-transform: none;
        font-size: @textLarge;
    }

    &:hover {
        .Navigation-menuThumbnailImage {
            transform: scale(1.05);
        }

        .Navigation-highlightOverlay {
        }

        .Navigation-listThumbHeading {
            color: @colorBlueRich;
            background-color: rgba(255,255,255,0.8);
            border-top-right-radius: 6px;
        }
    }
}

.Navigation-highlight {
    .Navigation-highlightOverlay {
        transition: opacity ease-in-out .4s;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 45%);
        opacity: 1;
    }

    &:hover {
        .Navigation-highlightOverlay {
            opacity: 0;
        }
    }
}