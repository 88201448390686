﻿@import "../../common/variables.less";

.SearchResult-area {
    padding: @spaceDefault 0;
    display: block;

    @media @bpMedium {
    }

    @media @bpLarge {
        display: flex;
        justify-content: unset;
    }

    .SearchResult-TabList {
        text-align: left;

        @media @bpMedium {
            width: auto;
        }

        @media @bpLarge {
        }
    }

    .SearchResult-hitsInfo {
        font-size: @textSizeSmall;
        align-self: center;
    }

    .SearchResult-hitsCategory {
        // display: none;
        font-size: @textSizeSmall;
        margin-right: @spaceLarge;

        @media @bpSmall {
           // display: inline-block;
        }
    }

    .SearchResult-noHits {
        display: block;
        width: 100%;
    }
}


/**/

.SearchResult-listHeading {
    display: inline-block;
    font-family: @fontFamilyHeading--light;
    margin-bottom: @spaceXXSmall;
}

.SearchResult-description {
    display: none;

    @media @bpMedium {
        display: inline-block;
        font-size: @textSizeSmall;
        position: relative;
        top: -2px;
        left: 5px;
    }
}

/**/

.SearchResult {
    display: block;
    width: 100%;
    background: @colorWhite;
}

.SearchResult-product {
    .Card-logo {
        margin-bottom: 1em;
        margin-left: @spaceMedium;

        @media @bpMedium {
            margin-bottom: 0;
        }
    }
}

.SearchResult-contentLink {
    border-bottom: 2px solid @colorWhite;
    padding: 0 @spaceMedium @spaceSmall @spaceMedium;
    display: block;
    transition: border ease-in-out 0.2s;
    height: 100%;

    .Heading {
        transition: color ease-in-out 0.2s;
    }

    &:hover {
        border-bottom: 2px solid @colorBlueRich;

        p, span {
            color: #323338;
        }

        .PriceboxPrice--sale span {
            color: @colorOrange;
        }

        .Heading {
            color: @colorBlueRich;
        }

        .SearchResultProduct-image {
            opacity: 1;
        }
    }
}

    


.SearchResultProduct-image {
    margin: 0 auto @spaceXSmall;
    max-height: 100px;
    opacity: .8;
    clear: both;
    display: block;
    transition: opacity ease-in-out 0.4s;
}

.SearchResultContent-product {
    text-align: center;
}

.SearchResult-price {
    .Pricebox-price {
        font-size: @textSizeLarge;
    }

    .Pricebox-recPrice {
        font-size: .6em;
    }

    &:hover {
        color: @colorText;
    }
}

/*  */


.SearchResult-productList {
    background: @colorBlueRich;
    border-bottom: 2px solid @colorBlueRich;
    padding: @spaceSmall @spaceMedium;

    &:hover {

        .Heading {
            color: @colorBlueTint;
        }
    }
}


.SearchResultProductList-label {
    color: @colorWhite;
    font-family: @fontFamilyHeading--light;
    font-size: @textSizeSmall;
}

.SearchResultProductList-heading {
    color: @colorWhite;
}

.SearchResultProductList-text {
    color: @colorWhite;
    margin-bottom: 0;
    line-height: 20px;
}


/**/


.SearchResult-article {
    &:hover {
        .SearchResultArticle-label,
        .SearchResultArticle-text {
            color: @colorBlack;
        }

        .SearchResultArticle-heading {
            color: @colorMossMid;
        }
    }
}

.SearchResultArticle-image {
    width: 100%;
}

.SearchResultArticle-label {
    font-size: @textSizeSmall;
    font-family: @fontFamilyHeading--light;
}

.SearchResultArticle-text {
    margin-bottom: 0;
}

.SearchResultContent-article {
    padding: @spaceSmall @spaceMedium;
}

/* Store */

.SearchResult-store {
    background: @colorMahoganyMid;
    padding: @spaceMedium;
    position: relative;
    overflow: hidden;

    &:after {
        content: "\e901";
        font-family: HomeIcons;
        font-size: 10em;
        position: absolute;
        color: #222;
        bottom: -.15em;
        left: -.1em;
        z-index: 1;
        opacity: 0.3;
    }

    &:hover {
        .Heading {
            color: @colorMahoganyTint;
        }
    }
}

.SearchResultStore-link {
    position: relative;
    z-index: 5;
    display: block;
}

.SearchResultStore-list {
    position: relative;
    z-index: 5;

    li, a {
        color: @colorWhite;
        padding: 0;
    }
}


/**/
.SearchResult-common {
    position: relative;
    padding: @spaceSmall;
    background: @colorBlueMid;

    @media @bpMedium {
        padding: @spaceMedium;
    }

    &:hover {
        .SearchResultCommon-text {
            color: @colorSilkLight !important;
        }
    }
}

.SearchResultCommon-text {
    color: @colorLightOrange;
    text-align: right;
    transition: color ease-in-out 0.2s;
}

.SearchResultCommon-nr {
    display: block;
    font-size: 4em;
    line-height: 1;
    font-family: @fontFamilyHeading--light;

    @media @bpLarge {
        font-size: 6em;
    }
}

.SearchResultCommon-heading {
    &.SearchResultCommon-heading {
        font-size: @textSizeSmall;

        @media @bpLarge {
            font-size: @textSizeLarge;
        
        }
    }
}

.SearchResults-SectionWrapper{ 
    .Section{
        border: none;
    }

}