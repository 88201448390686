﻿/*#

Menu on productpage.
Can be used in conjunction with the "sticky" js-module.


<div class="ProductNav" data-module="sticky" data-sticky="autoHide: false">
<div class="Page-container">
<div class="Grid Grid--gutterH">
    <div class="Grid-cell u-md-size8of12">
        <div class="ProductNav-header u-transitionAmd">
            <h3 class="ProductNav-heading">Dammsugare  ESCLASSICP</h3>
            @RenderPage("PriceBox.cshtml", new { CssClassB = "u-borderAz u-inlineBlock u-pullRight u-paddingAz", CssClassC = "Button--small" })
        </div>
    </div>
    <div class="Grid-cell u-md-size4of12">
        <div class="ProductNav-header u-transitionAmd">
                
                <ul class="List List--horizontal u-marginAz u-marginTNxsm">
                    <li class="List-item PageNav-symbols">
                        <a href="#"><i class="Icon Icon--heart-filled"></i><span class="Quantity">2</span></a>
                    </li>
                    <li class="List-item PageNav-symbols">
                        <a href="#"><i class="Icon Icon--cart"></i><span class="Quantity">38</span></a>
                    </li>
                </ul>
                  
            </div>
        </div>
    </div>
</div>
<div class="ProductNav-menu">
<div class="Page-container">
    <nav class="PageNav PageNav--horizontal PageNav--large Header-primaryNav">
        <ul class="PageNav-list ProductNav-List">
            <li class="PageNav-item">
                <a class="PageNav-itemLink" href="#simular">Liknande produkter</a>
            </li>
            <li class="PageNav-item">
                <a class="PageNav-itemLink" href="#fits">Passar med</a>
            </li>
            <li class="PageNav-item">
                <a class="PageNav-itemLink" href="#description">Beskrivning</a>
            </li>
            <li class="PageNav-item">
                <a class="PageNav-itemLink" href="#gallery">Galleri</a>
            </li>
            <li class="PageNav-item">
                <a class="PageNav-itemLink" href="#opinion">Omdöme</a>
            </li>
                   
            <li class="PageNav-item">
                <a class="PageNav-itemLink" href="#accessories">Tillbehör</a>
            </li>
            <li class="PageNav-item">
                <a class="PageNav-itemLink" href="#specifications">Specifikationer</a>
            </li>
        </ul>
    </nav>
</div>
</div>
</div>
   

*/

@import "../../common/variables.less";

.ProductNav {
	width: 100%;
	background: @colorWhite;
	padding: 0.4em 0 0;
	border-bottom: @borderDotted @colorWhite;
	display:none;

	.ProductNav-content {
		display: flex;
		justify-content: space-between;

		.PageNav-symbols {
			font-size: 24px;
		}
	}

	.ProductNav-priceBox {
		display: inline-block;

		.ProductCard-priceBoxList {

			.Pricebox-price {
				display: inline-block;
			}
		}
	}

	.ProductNav-header {
		width: 100%;
		opacity: 0;
	}

	.ProductNav-menu {
		border-top: @borderDotted @borderColor;
		border-bottom: @borderDotted @borderColor;
		margin-bottom: @spaceLarge;
		position: relative;
		z-index: 5;
		text-align: center;
	}

	&.is-sticky {
		display:block;
		.ProductNav-menu {
			margin-bottom: 0;
		}

		.ProductNav-header {
			opacity: 1;
		}
	}

	&.ProductNavTight {
		.ProductNav-menu {
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	&.is-sticky {
		&.ProductNavTight {
			.ProductNav-menu {
				border-bottom: @borderDotted @borderColor;
			}
		}

		.Button {
			margin-bottom: .4em;
		}
	}
}


.ProductNav-heading {
	display: inline-block;
	font-size: 25/16em;
	margin-bottom: 0;
	margin-top: 0.3em;
}

.ProductNav-List {
	font-size: 18px;
	width: 100%;
	padding: 0.2em 0;

	@media @bpLarge {
		font-size: 22px;
	}

	.PageNav-item {
		padding-bottom: 0.1em;

		&.is-active {
			.PageNav-itemLink {
				border-bottom: 3px solid @brandColor;
			}
		}

		.PageNav-itemLink {
			padding-bottom: 0;
			text-transform: none;
		}
	}
}

.ProductNav-icon {
	display: none;
	font-size: 10px;
}

.ProductNav-PageContainer {
	height: 0;
	position: relative;
	z-index: 3;
}

.ProductNav.Sticky-element.is-sticky {
	.ProductNav-PageContainer {
		height: auto;
	}
}

.is-sticky .ProductNav-icon {
	display: none; /* temporery delete*/
	// display: inline-block; /* temporery change back */
	vertical-align: top;
	margin-top: @spaceMedium;
	position: relative;
	z-index: 100;

	@media @bpMedium {
		display: none;
	}
}
//
@media all and (max-width: 544px) {
	.ProductNav-nav {
		display: inline-block;

		.ProductNav-List {
			font-size: 16px;

			.PageNav-item {
				display: none;
				&.is-active {
					display: inline-block;
				}
				&.is-active {
					.PageNav-itemLink {
						border-bottom: none;
						color: @colorText;
					}
				}

				.PageNav-itemLink {
				}
			}
		}
	}
}

.is-sticky {
	.ProductNav-nav {
		&.is-active {
			.ProductNav-List {
				.PageNav-item {
					display: block;

					&.is-active {
						display: block;
					}

					&.is-active {
						.PageNav-itemLink {
							border-bottom: none;
						}
					}

					.PageNav-itemLink {
					}
				}
			}
		}
	}
}
