﻿.Admin-box {
    border-left: 4px solid transparent;
    width: 100%;

    .Grid-cell {
        padding: @spaceDefault;
    }

    &.Theme--grey {
        border-color: @colorMidGrey;
    }

    &.Theme--blue {
        border-color: @colorBlueLight;
    }


    &.Theme--moss {
        border-color: @colorMossLight;
    }

    &.Theme--umber {
        border-color: @colorUmberLight;
    }

    li {
        a, p {
            font-size: @textLarge;
        }
    }

    a {
        &:hover {
            color: @colorOrange;
        }
    }
}


.Admin-back {
    font-family: @fontFamilyCondensed;
    display: block;
    margin-bottom: @spaceLarge;
    position: relative;
    top: -@spaceLarge;
    span{
        margin-left: 5px;
    }
    &:hover{
        color: @colorOrange;
    }
}


.Theme--grey {
    background: @colorGrey;
    color: @colorSoftBlack;

    .Heading {
        color: @colorSoftBlack;
    }

    &:hover {
        color: @colorSoftBlack;
    }
}
//
.Theme--mahogany {
    background: @colorMahoganyTint;
    color: @colorMahoganyRich;

    .Heading {
        color: @colorMahoganyRich;
    }

    &:hover {
        color: @colorMahoganyRich;
    }
}
//
.Theme--moss {
    background: @colorMossTint;
    color: @colorMossRich;

    .Heading {
        color: @colorMossRich;
    }

    &:hover {
        color: @colorMossRich;
    }
}

.Theme--umber {
    background: @colorUmberTint;
    color: @colorUmberRich;

    .Heading {
        color: @colorUmberRich;
    }

    &:hover {
        color: @colorUmberRich;
    }
}

.Theme--blue {
    background: @colorBlueTint;
    color: @colorBlueRich;

    .Heading {
        color: @colorBlueRich;
    }
}

.Theme--blueRich {
    background: @colorBlueRich;
    color: @colorWhite;

    .Heading {
        color: @colorWhite;
    }
}