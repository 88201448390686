﻿/*#
<div>
        <div class="Badges">
        <div class="Grid Grid--gutterAmd Grid--fit">
            <div class="Grid-cell u-size1of3">
                <div class="Badges-item">
                    <i class="Badges-symbol Badges-symbol--instock"></i>
                    <span class="Badges-type">I lager</span>
                </div>
            </div>
            <div class="Grid-cell u-size1of3">
                <div class="Badges-item">
                    <i class="Badges-symbol Badges-symbol--sooninstock"></i>
                    <span class="Badges-type">Snart i lager</span>
                </div>
            </div>
            <div class="Grid-cell u-size1of3">
                <div class="Badges-item">
                    <i class="Badges-symbol Badges-symbol--outofstock"></i>
                    <span class="Badges-type">Inte i lager</span>
                </div>
            </div>
            <div class="Grid-cell u-size1of3">
                <div class="Badges-item">
                    <i class="Icon Icon--delivery Badges-icon"></i>
                    <span class="Badges-type">Fri frakt online</span>
                </div>
            </div>
            <div class="Grid-cell u-size1of3">
                <div class="Badges-item">
                    <i class="Badges-symbol Badges-symbol--five"></i>
                    <span class="Badges-type">5 års garanti</span>
                </div>
            </div>
        </div>
    </div>
    </div>
*/
@import "../../common/variables.less";


.Badges {
    padding-top: @spaceSmall;  
}

.Badges-container {
    margin-left: 2px;
}

.Badges-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;

          &.BadgesItem--first{
              justify-content:flex-start;
          }

           &.BadgesItem--last{
              justify-content:flex-end;
          }

}

.Badges-type {
    font-size: @textSizeXSmall;
    line-height: 1em;
    margin-left: @spaceXSmall;
    color: @colorText;
}

.Badges-image {
    height: 46px;
}

.Badges-icon {
    color: @colorText;
    font-size: 1.3em;

    &.Icon--instock {
        color: @colorGreen;
    }
}

.Badges-symbol {
    display: inline-block;
    height: 8px;
    width: 8px;

    &&--instock,
    &&--sooninstock, 
    &&--default {
        background-color: @colorGreen;
        border-radius: 100%;
        margin-top: 2px;
        aspect-ratio: 1;
    }

    &&--sooninstock {
        background-color: @colorYellow;
    }

    &&--default {
        background-color: @colorSoftBlack;
    }

    &&--outofstock,
    &&--unavailable {
        margin-top: 1px;
        position: relative;

        &:after, &:before {
            background: #EC0000;
            content: "";
            height: 1.5px;
            left: -1px;
            position: absolute;
            top: 50%;
            transform: rotate(45deg);
            width: 10px;
        }

        &:before {
            transform: rotate(-45deg);
        }
    }

    &&--unavailable {
        &:after, &:before {
            background: @colorDimGrey;
        }
    }

    &&--five {
        background-color: @colorSoftBlack;
        color: @colorWhite;
        height: 18px;
        text-align: center;
        transform: rotate(45deg);
        width: 18px;

        &:before {
            content: "5";
            display: block;
            font-size: @textSizeXSmall;
            font-style: normal;
            font-weight: 600;
            left: -1px;
            position: relative;
            top: -1px;
            transform: rotate(-45deg);
        }
    }
}

//
.is-no-flexbox{
    .Badges{
         .Grid-cell{
        display: inline-block;

        .Badges-item{
            display:block;
            padding-left:20px;
        }

        .Icon{
            float:left;
            padding-top: 0.3em;
        }

       }
    }
}