﻿/*#
<div style="width:390px;">
    <div class="BrandTeaser">
    <div class="BrandTeaser-header">
        <img class="BrandTeaser-logo" src="~/Assets/Images/Logo/sentens.png" width="51" />
        <i class="Icon Icon--wish-active Card-wish"></i>
    </div>
    <div>
        <a href="#" class="Teaser-link u-linkText">
            <img class="u-marginBsm" src="~/Assets/Images/Temp/kottkvaern.png" />
            <div class="BrandTeaser-content u-transitionAlg">
                <span class="Card-articleNr">zuoorgw+</span>
                <h5 class="BrandTeaser-heading">Tips! Så använder du din köttkvarn</h5>
                <p class="BrandTeaser-text">Upptäck vårt urval av dammsugare speciellt anpassade för hem med håriga vänner.</p>
            </div>
        </a>
    </div>
    @RenderPage("PriceBox.cshtml", new { CssClassB = "u-borderBz u-paddingBz" })
</div>
</div>
*/
@import "../../common/variables.less";

.BrandTeaser{
    border: 1px solid @colorBlueTint;
    padding: @spaceSmall;
    width: 100%;
}

.BrandTeaser-header{ 
    position:relative;
    overflow: hidden;
    margin-bottom: @spaceSmall;
}

.BrandTeaser-logo{
    float: left;
}

.BrandTeaser-heading{
    text-transform: none;
}

.BrandTeaser-text{
    font-size: @textSizeSmall;
}
.BrandTeaser .Gallery-thumb{
    margin: 0 auto;
    display: block;
}