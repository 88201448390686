/*#


*/

@import "../../common/variables.less";
@colorcampaignCode: lighten(@brandColor, 41%);

.campaignCode{
    background:@colorBlueTint;
  border: 1px dashed @colorMidGrey;
  width: 100%;
  min-height:20px;
  text-align: center;
  margin-bottom: @spaceXSmall;
  padding: @spaceSmall 0;
}
