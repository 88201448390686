﻿.ProductCard {
    background: @colorWhite;
    padding: @spaceSmall;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ProductCard-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: @spaceSmall;
    border-bottom: 1px solid @colorGrey;
}

.ProductCard-headLeft{
    display: flex;
}

.ProductCard-headTop {
    height: 100%;
    overflow: hidden;
}
    .ProductCard-headCenter {
        display: flex;
        flex-direction: column;
        padding-left: @spaceSmall;
        justify-content: space-between;
    }

    .ProductCard-headRight {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    .ProductCard-info {
        display: flex;
    }

.ProductCard-productImage{
    width: 116px;
    height: 116px;
    text-align: center;
    img{
        max-height: 100%;
    }
}

.ProductCard-logoLink {
    line-height: 0;
    img{

    }
}

    .ProductCard-logo {
        width: auto;
        position: relative;
        top: 2px;
        max-height: 22px;
        max-width: 95px;
        display: inline-block;
        margin: 0 0;
    }

    .ProductCard-wifi {
        width: 12px;
        display: block;
        margin-top: @spaceXSmall;
    }
    //


.ProductCard-icon{
    cursor: pointer;
    font-size: 20px;

    &:hover{
        color: @colorOrange;
    }
}

.ProductCard-linkWrapper {
    display: block;
    height: 100%;

    &:hover {
        .ProductCard-campagnText {
            color: @colorOrange;
        }
    }
}
    //
    .ProductCard-content {
        padding-top: @spaceSmall;
        height: 100%;
    }

    .ProductCard-heading {
        font-size: @textSizeLarge;
        display: inline-block;
        margin-bottom: @spaceSmall;
    }

    .ProductCard-articleNr {
        font-family: @fontFamilyPrimary;
        font-size: @textSizeLarge;
        color: @colorDimGrey;
        margin-right: @spaceXSmall;
    }

    .ProductCard-reviewRating {
        img {
            position: relative;
            top: -1px;
            filter: invert(70%) sepia(23%) saturate(3508%) hue-rotate(359deg) brightness(99%) contrast(94%);
        }
    }

.ProductCard-campagnText {
    color: @colorOrange;
    font-family: @fontFamilyPrimary;
    font-size: @textSizeMedium;
}


    .ProductCard-list {
        margin-top: 0;
        padding-left: @spaceXSmall;

        li {
            font-size: @textSizeMedium;
        }
    }
    //

    .ProductCard-priceBoxList {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0;

        .List-item {
            padding: 0;
        }



        .Pricebox-price {
            font-family: @fontFamilyCondensed;

            &.Pricebox-recPrice {
                text-decoration: line-through;
                font-size: @textSizeXSmall;
                font-family: @fontFamilyPrimary;
            }
        }

        .Badges-type {
            margin-left: 0;
        }

        .Pricebox-stockInfo {
            span {
            }
        }

        .Badges-item {
            display: inline-block;
            margin-left: .2rem;
        }
    }


    .ProductCard-priceBox {
        .Pricebox .List--horizontal {
            display: flex;
            justify-content: space-between;
        }
    }

.List {
    .Pricebox-stockBuy {
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .Button {
            font-size: @Button-textSize;
        }
    }
}

    .Pricebox-stockInfo {
        text-align: right;
    }
    //

.ProductCard-campaign {
    text-align: center;
    margin: -@spaceSmall -@spaceSmall @spaceSmall -@spaceSmall;
    padding: .2rem @spaceXXXSmall;

    .ProductCard-campaignText {
        display: block;
        font-family: @fontFamilyCondensed;
        font-size: @textSmall;
    }

    &--solid {
        margin: 0;
    }

    &--left{
        text-align: left;
        padding-left: @spaceSmall;
    }
}