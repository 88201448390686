﻿@import "../../common/variables.less";

.Partner {
    .Partner-teaser {
        height: 130px;

        @media @bpMedium {
            height: 170px;
        }

        @media @bpLarge {
            height: 130px;
        }

        .Teaser-link {
            max-width: 75%;

            @media @bpXLarge {
                max-width: 70%;
            }

            .Teaser-content {
                padding: .4em;
            }

            .Teaser-heading {
                font-size: @textSizeSmall;
                color: @colorBlack;
            }
        }
        img{
            max-height: 80px;
        }
    }
}
