/*#

Dispaly how many items there is

Examples:
 <div class="ReadMorebox">
    <a href="#" class="ReadMorebox-link">Alla v�ra kataloger</a>
  </div>
*/

@import "../../common/variables.less";

.ReadMorebox{
    margin-top: @spaceMedium;
    text-align:right;
    border-top: @borderDotted @borderColor;
    padding-top:@spaceSmall;
}

.ReadMorebox-link{
    border-bottom: @borderDotted @borderColor;
    &:after{
         font-family: 'HomeIcons';
         content: "\e921";
         margin-left: .4em;
         font-size: .7em;
    }
}