/*#
Component used to display status messages.

<div class="Status"><p>This is plain status message</p></div>
<div class="Status Status--success"><p>This is a success message</p></div>
<div class="Status Status--info"><p>This is an info message</p></div>
<div class="Status Status--warning"><p>This is a warning message</p></div>
    
*/

@import "../../common/variables.less";

.Status {
    padding: 0.1em 1em;
    margin: 1em 0;
    background-color: lighten(@colorBlueTint, 8%);
    border-radius:4px;
}

.Status--rounded {
    border-radius: 1em;
}

.Status--fixedBottom {
    margin: 0;
    position: fixed;
    backface-visibility: hidden; /* 1 */
    bottom: 0;
    right: 0;
    z-index: 10;
}

.Status--center {
    text-align: center;
}

.Status--transparent {
    background-color: transparent;
}

.Status--tight {
    padding-right: 0;
    padding-left: 0;
}

.Status--condensed {
    padding-right: 0.4em;
    padding-left: 0.4em;
}

.Status--success {
    background-color: #E2F9BE;
}

.Status--info {
    background-color: #FFF6CB;
}

.Status--warning {
       background-color: @colorRed;
        color: @colorTextWhite;
}
/*Had to add this to make success box in modal look nicer*/
.Status--extra-padding-no-margin {
    padding: 1em;
    margin:0;
}
