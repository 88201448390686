﻿
.u-flexGrowNone {
	flex-grow: 0 !important;
}

.u-flexJustifyCenter{
	justify-content:center;
}

.u-flexJustifyRight{
	justify-content:flex-end;
}

.u-flexJustifySpaceBetween {
	justify-content: space-between;
}

.u-flexJustifySpaceAround {
	justify-content: space-around;
}

.u-flexAlignItemsCenter{
	align-items:center;
}

.u-flexDirectionColumn {
	flex-direction: column;
}

.u-sm-flexDirectionColumn {
	flex-direction: column;
	@media @bpMedium {
        flex-direction: inherit;
    }
}

.u-sm-flexColumnReverse {
	flex-direction: column-reverse;

	@media @bpMedium {
		flex-direction: inherit;
	}
}

.u-flexGap {
	gap: @spaceMedium
}