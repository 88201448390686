﻿.Banner {
    &.ColorTeaser--large {
        .ColorTeaser-content {
            .ColorTeaser-heading {
                .Heading--h1Size;
            }

            .ColorTeaser-subHeading {
            }

            .ColorTeaser-text {
                font-size: @textMedium;

                @media @bpLarge {
                    font-size: @textLarge;
                }
            }
        }
    }
}
