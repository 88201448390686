/*#

<div>
</div>

*/

@import "../../common/variables.less";

.Dropmenu {
	display: none;
	width: 100%;
	position: absolute;
	z-index: 45;
	background: @colorWhite;
	border: 1px solid @colorBlueTint;
	width: auto;
	padding: @spaceSmall;
	border-top-width: 4px;
	margin-top: -2px;
	min-width: 220px;
	-webkit-box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
	-moz-box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
	box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
}

.DropMenu-list {
	margin-bottom: 0;
}

.DropMenuList-item {
    border-bottom: @borderDotted @borderColor;

    &:last-child {
        border: none;
    }

    a {
        display: block;
        font-size: @textSizeXSmall;
        letter-spacing: -0.015em;

        &:hover {
            color: @colorBlueRich;
        }
    }
}
