/*#

Examples:

<div>
  <div class="Social">
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--facebook"></i> <span>Facebook</span></a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--instagram"></i><span>Instagram</span> </a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--pinterest"></i><span>Pinerest</span></a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--youtube"></i><span> Youtube</span></a>
    </div>
</div>

*/

@import "../../common/variables.less";

.Promotionlabel{
    position: absolute;
    top:@spaceMedium;
    left:0;
    background: @colorBlack;
    padding: @spaceXSmall @spaceSmall;
    border-radius:0 8px 8px 0;
}

.Promotionlabel-text{
    text-transform:uppercase;
    font-size: @textSizeMedium;
    color: @colorTextWhite;
}