﻿@import "../../common/variables.less";

.SearchMenu-startSearchText{
    text-align: center;
    p{
        margin-bottom: 0;
    }
}

.SearchMenu {
    border: 5px solid red;
    .Grid {
        .Grid {
            flex-basis: 100%;
        }
    }
}

.SearchMenu-item {
    background: @colorWhite;
    padding: @spaceMedium;
    overflow: hidden;
    width: 100%;
    transition: background ease-in-out 0.8s;

    .Heading, .SearchMenu-text {
        margin-bottom: 0;
        transition: color ease-in-out 0.4s;
    }

    &:hover {
        background: @colorBlueRich;

        .Heading {
            color: @colorWhite;
        }

        .SearchMenu-text {
            color: @colorWhite;
        }
    }
}


.SearchMenuItem-product {
    text-align: center;
    padding: @spaceMedium @spaceXSmall;
    display: flex;
    justify-content: center;
}


.SearchMenuItem-category {
    display: block;
}

.SearchMenu-categoryHeading {
    font-size: @textSizeLarge;
}

.SearchMenu-productHeading {
    font-size: @textSizeXSmall;
    align-self: center;
}

.SearchResult-modelNr{
    font-size: @textSizeXSmall;
    margin-bottom: 0;
}

.SearchMenu-text {
}
.SearchMenu-icon {
    display: block;

    &:before {
        display: block;
        font-size: 3em;

        @media @bpLarge {
            font-size: 3.6rem;
        }
    }
}


/**/

.SearchMenu--vaccum {
    .SearchMenu-item {
        background: @colorBlueMid;

        .Heading, p {
            color: @colorWhite;
        }

        &:hover {
            opacity: .9;
            .Heading, p {
                /*color: blue;*/
            }
        }
    }
}