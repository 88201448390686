/*#

Hero

<div>
    <div class="Hero u-marginGrid" style="background-image: url('/assets/images/Temp/hero.jpg');">
    <div class="Page-container">
        <div class="Hero-headingWrapper">
            <h1 class="Hero-mainHeading">Dammsugare</h1>
        </div>
    </div>
</div>
</div>

*/

@import "../../common/variables.less";

.Hero {
    min-height: 120px;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    margin: 0;
    overflow: hidden;
    margin: -.7em 0;

    @media @bpMedium {
        min-height: 180px;
    }

    @media @bpLarge {
        margin: -1.6em 0;
    }

    @media @bpXLarge {
        min-height: 330px;
        margin: -1.65em 0;
    }
}

.Hero--video {
    min-height: unset;
    max-height: 200px;
    background-color: @colorMidGrey;
    
    @media @bpMedium {
        max-height: 220px;
    }

    @media @bpLarge {
        min-height: unset;
        max-height: 330px;
        margin: -1.65em 0;
    }

    video {
        object-fit: cover;
        width: 100%;
        max-height: 200px;

        @media @bpMedium {
            max-height: 220px;
        }

        @media @bpLarge {
            max-height: 332px;
        }
    }
}

.Hero-wrapper {
    padding: @spaceLarge 0 @spaceMedium;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;

    @media @bpXLarge {
        position: absolute;
        bottom: @spaceSmall;
        width: 100%;
        max-width: @widthContainer;
    }
}

.HeroWrapper-right {
    justify-content: flex-end;
}

.Hero-content {
    padding: @spaceMedium @spaceSmall;
    background: @colorWhiteTransparent;
    position: relative;


    @media @bpMedium {
        width: 50%;
    }

    @media @bpLarge {
        width: 45%;
        padding: @spaceLarge @spaceLarge;
    }

    @media @bpXLarge {
        width: 37%;
    }
}

.Hero-heading {
}

.HeroHeading--link {
    color: @brandColor;
}

.Hero-text {
    font-size: @textSizeLarge;
    margin-bottom: 0;
    line-height: 1.5em;

    @media @bpMedium {
        font-size: @textSizeLarge;
    }
}

.Hero-Link {
    &:hover {
        .Hero-heading {
            color: @colorSilkLight;
        }
    }
}

.HeroWrapper-mobile {
    margin-top: -@spaceXLarge;
    padding: 0;
    margin-bottom: @spaceMedium;
}

.HeroContent-mobile {
    background: @colorWhite;
    width: 100%;
    margin: 0 auto;
    border-bottom: @borderDotted @borderColor;
    padding: @spaceMedium @spaceLarge;
}

.Hero-headingWrapper {
    padding: @spaceSmall;
    background: @colorWhiteTransparent;
    display: inline-block;
    position: absolute;
    bottom: @spaceXLarge;
    left: 0;
    border-radius: 0 6px 6px 0;
    max-width: 96%;

    @media @bpMedium {
        left: inherit;
        text-align: center;
    }

    &:before {
        @media @bpMedium {
            content: "";
            display: block;
            background: @colorWhiteTransparent;
            width: 100vH;
            height: 100%;
            position: absolute;
            right: 100%;
            top: 0;
        }
    }

    @media @bpMedium {
        padding-right: @spaceMedium;
        width: auto;
        text-align: left;
    }
}

.HeroContent-image {
    position: absolute;
    left: -@spaceXLarge;
    top: -3.7em;
    width: 40%;
    left: -@spaceMedium;
    background: @colorWhite;
    padding: @spaceXSmall @spaceMedium @spaceXSmall @spaceLarge;
    border-radius: 0 30px 0px 0;

    @media @bpSmall {
        left: 0;
        top: -@spaceXLarge;
        width: 20%;
    }

    @media @bpMedium {
        background: transparent;
        padding: 0;
        margin: 0;
        display: block;
        width: 40%;
        left: -@spaceXLarge;
        top: -@spaceMedium;
        border-radius: 0;
    }

    @media @bpXLarge {
        width: auto;
        left: -@spaceXLarge;
        top: -3em;
    }
}

.Hero-mainHeading {
    font-size: 1.6em;
    line-height: 1em;
    margin-bottom: 0;

    @media @bpMedium {
        font-size: 2em;
        line-height: 30px;
    }

    @media @bpLarge {
        font-size: 45/16em;
        line-height: 50px;
        top: -4px;
        position: relative;
    }
}

/*test form link*/
.Hero-link {
    position: relative;
    display: block;

    .Hero {
        border-bottom: 4px solid #fff;  
    }

    .Hero-kitchenLink {
        display: none;
    }

    &:hover {
        .Hero-overlay {
            opacity: .3;
        }

        .Hero {
            border-color: @brandColor;
        }

        .HeroLink-explanation {
            opacity: 1;
        }

        .Hero-wrapper {
            z-index: 30;
        }

        .Hero-kitchenLink {
            display: block;
        }

        .HeroContent-main {
            display: none;
        }
    }
}

a:hover {
    .Hero-kitchenLink {
        text-align: right;
        h2, h5, p {
            color: @colorSoftBlack !important;
        }
    }
}
     

.HeroLink-explanation {
    background-color: @brandColor;
    text-align: center;
    position: absolute;
    bottom: 13px;
    right: 0;
    padding: @spaceSmall;
    border-radius: 6px 0px 0 0;
    opacity: 0;
    z-index: 20;
    left: inherit;

    @media @bpMedium {
        left: 0;
        padding: @spaceSmall @spaceMedium @spaceSmall @spaceXLarge;
        border-radius: 0px 6px 0 0;
        bottom: 0;
        right: inherit;

    }

    span {
        color: #fff;
    }
}


.Hero-overlay {
    background: #fff;
    opacity: 0;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 4px;
}
/* end test form link*/
.is-no-flexbox {
    .HeroWrapper-right {
        .Hero-content {
            float: right;
        }
    }
}
