@import "../../common/variables.less";

.Header-transparent {
    .DeviceNav-headerList, .Header-mobile {
        background-color: transparent !important;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
   }
}

.is-sticky {
    .Header-mobile {
        background: @colorWhite;
    }
}


.Header-transparent {
    .Header-search-icon {
        visibility: hidden;
    }
}

.DeviceNav-wrapper {
    width: 100%;
    z-index: 10;

    &:hover {
        .Header-search-icon {
            filter: none;
            visibility: visible;
            color: @brandColor;
        }
    }

    .DeviceNav-header {
        height: 100px;
        display: flex;
    }

    .DeviceNav-headerList {
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        position: relative;
        width: 100%;
        align-items: center;
        padding: 0 @spaceMedium;
        background: @colorWhite;

        .DeviceNav-togglerBack {
            grid-column-start: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            .Header-search-icon {
                margin-left: 12px;
            }
        }

        .Header-logo {
            grid-column-start: 2;
            margin: auto;
        }

        .BookMeeting {
            justify-content: right;
            align-items: center;
            grid-column-start: 3;
            display: flex;
            a:hover {
                color: @brandColor;
                background-color: @colorWhite;
            }
        }
    }



    .DeviceNav-togglerBack {
        line-height: 0;

        i {
            font-size: 20px;
            color: @colorBlueRich;
        }
    }

    .DeviceNav {
        .Header-logo {
            top: 35%;
        }
    }

    .DeviceNav-cart {
        line-height: 0;

        i {
            font-size: 22px;
            color: @colorBlueRich;
        }

        .Quantity {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .DeviceNav-productIcon {
        &[class*="Icon--"] {
            width: 28px;
            display: inline-block;
            text-align: center;
        }
    }

    nav.DeviceNav {
        top: 0;
        left: 0;
        width: 100%;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        z-index: 50;

        &.is-visible {
            background-color: @colorWhite;
            position: absolute;

            .DeviceNav-header {
            }

            .BookMeeting {

            }
        }
    }

    .DeviceNav-container {
    }

    .DeviceNav-icons {
        span {
            font-size: 24px;
            color: @colorWhite;
        }
    }

    .DeviceNav {
        .DeviceNav-list {
            overflow-y: auto;
            overflow-x: hidden;
            height: 100vh;
            padding-bottom: 10rem;
            padding-top: 0;
        }

        &.view-submenu {
            .DeviceNav-list {
                overflow: visible;
            }

            .Header-logo, .Toggle-wrapper {
                display: none;
            }
        }

        &.view-submenu {
            ul {
                li {
                    ul {
                        overflow: auto;
                    }
                }
            }
        }

        .Navigation-listThumbHeading {
            font-size: 22px;

            @media @bpLarge {
                font-size: 18px;
            }
        }
    }

    .menu-container {
        position: relative;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: -webkit-transform 0.25s ease-out;
        transition: -webkit-transform 0.25s ease-out;
        transition: transform 0.25s ease-out;
        transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
    }

    .view-submenu .menu-container {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }


    .DeviceNav-subNav {
        height: 100vh;
        padding-bottom: 8rem;
    }

    .DeviceNav-list, .DeviceNav-subNav {
        padding-left: @spaceMedium;
        padding-right: @spaceMedium;
    }

    ul {
        position: absolute;
        top: 0;
        padding: 6px;
        width: 100%;
        background-color: @colorWhite;
        margin: 0;
        list-style: none;

        li {
            padding: 6px 0;

            a, .DeviceNav-parent {
                opacity: 0;
                display: block;
                position: relative;
                cursor: pointer;
                -webkit-transform: translateY(12px);
                transform: translateY(12px);
                -webkit-transition: opacity 0.15s 0.1s ease-out, -webkit-transform 0.15s 0.1s ease-out;
                transition: opacity 0.15s 0.1s ease-out, -webkit-transform 0.15s 0.1s ease-out;
                transition: opacity 0.15s 0.1s ease-out, transform 0.15s 0.1s ease-out;
                transition: opacity 0.15s 0.1s ease-out, transform 0.15s 0.1s ease-out, -webkit-transform 0.15s 0.1s ease-out;
                color: @colorBlueRich;
                font-size: 22px;
            }

            &.has-children .DeviceNav-dive {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                padding-left: 25%;

                i {
                    font-size: 18px;
                    color: @colorBlueRich;
                }
            }

            &.has-children {
            }

            &:first-child {
            }
        }
    }

    .DeviceNav-linkList {
        ul {
            li {
                border: none;
            }
        }
    }

    .menu {
        opacity: 0;
        -webkit-transition: background-color 0.25s ease-out, opacity 0.15s ease-out;
        transition: background-color 0.25s ease-out, opacity 0.15s ease-out;

        &.is-visible {
            opacity: 1;

            li {
                .DeviceNav-parent, a {
                    opacity: 1;
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                }
            }
        }
    }



    .view-submenu .menu {
    }

    .view-submenu .menu > li > a {
        opacity: 0;
        -webkit-transition: opacity 0.15s ease-out;
        transition: opacity 0.15s ease-out;
    }

    .submenu {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        // overflow: auto;
    }


    .hide-submenu {
        position: relative;
        display: inline;
        cursor: pointer;
        -webkit-transition: background-color 0.25s ease-out;
        transition: background-color 0.25s ease-out;
        display: none;

        i.Icon--readMore {
            transform: rotate(180deg);
            display: block;
        }
    }

    .DeviceNav-parentName {
        display: none;

        span {
            a {
                color: @colorBlueRich;
             
            }
        }
    }

    .view-submenu {
        .hide-submenu {
            display: inline-block;

            &:before {
                opacity: 1;
                -webkit-transition: opacity 0.15s 0.3s ease-out;
                transition: opacity 0.15s 0.3s ease-out;
            }
        }

        .DeviceNav-parentName {
            display: inline-block;

            span {
                font-size: 22px;
            }
        }

        .DeviceNav-icons {
            display: none;
        }
    }


    .Toggle-wrapper {
        width: 26px;
        height: 26px;
        position: relative;
    }

    .toggle {
        position: absolute;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;

        span {
            position: relative;
            -webkit-align-self: center;
            -ms-flex-item-align: center;
            align-self: center;
            font-size: 0;
            width: 26px;
            height: 4px;
            background-color: @colorBlueRich;
            border-radius: 100px;
            -webkit-transition: background-color 0.2s ease-out;
            transition: background-color 0.2s ease-out;

            &:after, &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: @colorBlueRich;
                border-radius: 100px;
                -webkit-transition: -webkit-transform .2s ease-out;
                transition: -webkit-transform .2s ease-out;
                transition: transform .2s ease-out;
                transition: transform .2s ease-out, -webkit-transform .2s ease-out;
            }

            &:before {
                bottom: 8px;
            }

            &:after {
                top: 8px;
            }
        }
    }

    .is-visible {
        .toggle {
            span {
                background-color: transparent;

                &:before {
                    bottom: auto;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                &:after {
                    top: auto;
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }

        .Device-Search {
            display: none;
        }
    }

    .visually-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

.Device-Search {
    padding: 0 @spaceMedium;

    .Search-field {

        &:after {
            font-size: 16px;
            top: 7px;
            right: 10px;
        }

        .Search-input {
            height: 40px;
            -webkit-appearance: none;
            border-radius: 0;
            font-size: 16px;
        }
    }

    .Search-close {
        &.Device-SearchClose {
            position: absolute;
            right: 0px;
            top: 12px !important;
            background: @colorGrey;
            z-index: 100;
            padding: 10px;
            display: none;
        }
    }

    .input-SearchClose {
        display: none !important;
    }

    .Search-close {
        right: 8px;
        display: none;
        z-index: 10;
        top: 36px;
        z-index: 10;
    }

    &.is-active {
        .Search-close {
            &.Device-SearchClose {
                display: block;
            }
        }

        .Search-field {

            &:after {
                display: none;
            }
        }
    }
}
    //

    .Search-close.u-inlineBlock.js-searchBox-close.kalle {
        width: 10px;
        height: 10px;
        background: pink;
        right: 15px;
        z-index: 20;
    }
    //
    .has-childrenList {
        position: relative;

        i.Icon--readMore {
            transform: rotate(90deg);
            color: @colorBlueRich;
            display: inline-block;
            position: relative;
            top: 8px;
            right: 4px;
        }

        .DeviceNav-childrenTrigger {
            padding-left: 25%;
            position: absolute;
            right: 0;
            top: 6px;
            bottom: 6px;

            &.is-open {
                i.Icon--readMore {
                    transform: rotate(270deg);
                }
            }
        }
    }

    ul.DeviceNav-childrenList {
        position: relative;
        display: none;
        padding: 0 0 0 36px;


        li {
            padding: 3px 0;

            a {
                font-weight: normal;
                font-size: 18px;
                font-family: @fontFamilyPrimary;
            }
        }

        &.is-open {
            max-height: 800px;
            overflow: visible;

            li {
            }
        }
    }

    .DeviceNav-wrapper {
        .DeviceNav-fixList {
            border-top: 1px solid @colorBlueTint;
            padding: 0;
            padding-top: 16px;
            margin-top: 10px;
            margin-bottom: @spaceXLarge;

            li {
                a {
                    font-size: 14px;
                    line-height: 22px;

                    i {
                        width: 30px;
                        display: inline-block;
                        text-align: center;
                        font-size: 16px;
                    }
                }
            }

            .Navigation-offer {
                margin-bottom: @spaceLarge;
            }

            .Navigation-offerList {
                position: relative;
                background: @colorBlueTint;
                padding: 0;

                li {
                    padding: 0;
                }
            }
        }
    }
