﻿@import "../../common/variables.less";

@modalBlockPadding: 76px;
@modalInlinePadding: 26px; 
@innerPadding: 32px;
@productModalWidth: 660px; // modal width - padding values (needed to avoid changing other modals)

.ProductInfoModal {
    color: @colorSoftBlack;
    padding: @modalBlockPadding @modalInlinePadding; // padding + 1,25rem from parent elem to align with design

    @media @bpMedium {
        min-width: @productModalWidth;
    }

    &__imageWrapper {
        @media @bpMedium {
            padding-right: @innerPadding;
        }
    }

    &__textWrapper {
        margin-top: 24px;

        @media @bpMedium {
            margin-top: 0;
            padding-left: @innerPadding;
        }
    }

    &__relatedProducts {
        li {
            list-style: none;
            display: inline;

            &:after {
                content: " | ";
            }

            &:last-child:after {
                content: none;
            }
        }
    }

    th, td {
        padding: 8px;
        font-weight: @fw-400;
        border-bottom: 1px solid @colorGrey;
    }

    dt {
        font-weight: @fw-600;
    }

    dt::after {
        content: ": ";
    }
}
