/*#

Component for containing the entire page. Useful for setting page wide styling.  
It also has a .Page-container that is used for containing the site within the max-width.

*/

@import "../../common/variables.less";

.Page {
    padding: 0;
    overflow-x: hidden;
}

.Page-container {
    max-width: @widthContainerLarge;
    margin-left: auto;
    margin-right: auto;
    padding: 0 @spaceMedium;

    @media @bpXLarge {
        padding: 0;
    }

    &--zero-padding {
        padding: 0;
    }

    &--wide {
        max-width: @widthContainerXLarge;
        padding: @spaceLarge 0;
    }

    &-sm--wide {
        padding: 0;
        max-width: 100%;

        @media @bpMedium {
            max-width: @widthContainerLarge;
            padding: 0 @spaceMedium;
        }

        @media @bpXLarge {
            padding: 0;
        }
    }
}

.Page-main {
    position: relative;
    margin-top: 3rem;
    &.DrawnToTop {
        margin-top: -147px  ;
    }


    @media @bpMedium {
        &.DrawnToTop {
            margin-top: -148px;
            &.withStatusMsg {
                margin-top: -147px;
            }
        }
    }
    @media @bpLarge {
        &.DrawnToTop {
            margin-top: -156px;
            &.withStatusMsg {
                margin-top: -117px;
            }
        }
    }

    @media @bpXLarge {
        &.DrawnToTop {
            margin-top: -156px;
            &.withStatusMsg {
                    margin-top: -117px;
            }
        }
    }
}