﻿/*#

Styling for custom checkboxes. Supports focus and disabled states.

Examples:

<div>
    <span class="Checkbox u-marginRsm">
        <input id="cb11" type="checkbox" name="newsletter" class="Checkbox-input">
        <label for="cb11" class="Checkbox-label">Tech newsletter</label>
    </span>

    <span class="Checkbox u-marginRsm">
        <input id="cb33" type="checkbox" name="newsletter" class="Checkbox-input" disabled>
        <label for="cb33" class="Checkbox-label">Disabled newsletter</label>
    </span>
</div>

*/

@import "../../common/variables.less";

.Checkbox {
    position: relative;
    display: inline-block;
    
    @media print {
        display: none !important;
    }

    .Checkbox-input:disabled + input[type="hidden"] + .Checkbox-label,
		.Checkbox-input:disabled + .Checkbox-label,
    &.is-disabled .Checkbox-label {
        opacity: 0.5;
        cursor: not-allowed !important;
    }
}

.Checkbox-input {
    opacity: 0;
    position: absolute;

    &:disabled + .Checkbox-label:before,
		&:disabled + input[type="hidden"] + .Checkbox-label {
			cursor:not-allowed;
			opacity:0.5;
    }
    
    // checked styling for box
    &:checked  + .Checkbox-label:before,
		&:checked  + input[type="hidden"] + .Checkbox-label:before {
        
    }
    
    // checked styling for checkmark
		&:checked  + .Checkbox-label:after,
		&:checked  + input[type="hidden"] + .Checkbox-label:after {
        -ms-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 1;
    }
    
    // focus styling for box
		&:focus + .Checkbox-label:before,
    &:focus + input[type="hidden"] + .Checkbox-label:before {
      
    }
}

.Checkbox-input.checkbox-inverted {
    opacity: 1;
}

.Checkbox-label {
  padding-left: 1.5em;
  position: relative;
  cursor: pointer;
  font-size: @textSizeSmall;
  line-height: 1.55;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: .2em;
    width: 1em;
    height: 1em;
    display: block;
    background: white;
    border: 1px solid @colorSoftBlack;
  }

  &:after {
    content: '';
    position: absolute;
    left: .15em;
    top: 0;
    width: 1em;
    height: 1em;
    display: inline-block;
    z-index: 1;
    background: url('/Assets/Images/Icons/check--orange.svg');
    background-repeat: no-repeat;
    -ms-transition: all .15s ease;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
.Checkbox-label.isNonComparable:after {
	background: none;
	}
.Checkbox-label.isComparable:after {
	background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTVweCIgdmlld0JveD0iMCAwIDE2IDE1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCAzLjYuMSAoMjYzMTMpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPlVudGl0bGVkIDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNMiw4LjIzOTQ1MjY4IEw2Ljg4ODEzMzk1LDEyLjI4NTcxNDMgTDE0LDIiIGlkPSJQYXRoLUNvcHkiIHN0cm9rZT0iI0YxNzMzMCIgc3Ryb2tlLXdpZHRoPSIzIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==') no-repeat center center;
	}