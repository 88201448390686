﻿/*#

Custom styling for Select dropdowns. Supports focus and disabled states.

Examples:

<div>
    <span class="Select u-sm-size1of3X">
        <select class="Select-input">
            <option>Select an option</option>
            <option>Invoice</option>
            <option>Emergency</option>
            <option>Question</option>
            <option>A very long item is here that will never end</option>
            <option>Other</option>
        </select>
    </span>
</div>

*/

@import "../../common/variables.less";

.Select, .Form__Element select {
    position: relative;
    padding: 0;
    display: inline-block;
    background: @colorWhite;
    border: 1px solid #aaa;
    border-radius: 2px;

    &:after {
        content: "";
        position: absolute;
        width: 2.2em;
        top: 15px;
        bottom: 0px;
        right: 5px;
        background-position: center;
        pointer-events: none;
        z-index: 2;
        font-family: HomeIcons;
        content: "\e91a";
        color: @colorOrange;
        font-size: 10px;
    }
}


.Select--clear{
    border: none;
}

.Select--inverted{
    background: @colorBlack;
    .Select-input{
         color: @colorTextWhite;
         padding:0;
    }
   
}

.Select--plain {
    border: none;

    &:after {
        font-size: 8px;
        color: @colorBlueRich;
        transform: translate(0, 3px);
    }

    .Select-input {
        font-size: 15px;
        font-family: @fontFamilyCondensed;
        color: @colorBlueRich;
        margin-left: @spaceMedium;
        width: var(--select-size);
    }

    option {
        font-family: @fontFamilyPrimary;
    }
}

.Select--plain2 {
    border: none;
    color: @colorSoftBlack;
    background: transparent;

    &:after {
        transform: translate(0, 3px);
    }

    .Select-input {
        font-size: 15px;
        font-family: @fontFamilyCondensed;
        color: @colorSoftBlack;
        margin: 0;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }
    }

    option {
        font-family: @fontFamilyPrimary;
    }

    &:after {
        content: "";
        position: absolute;
        width: 2.2em;
        top: 15px;
        bottom: 0px;
        right: 0;
        background-position: center;
        pointer-events: none;
        z-index: 2;
        font-family: HomeIcons;
        color: @colorSoftBlack;
        content: "\e91a";
        font-size: 7px;
    }
}

.SelectInput--fixedWidth{
    width: 75px!important;
}

.Select--full{
    width:100%;
}

.Select-input {
    width: 100%;
    margin: 0;
    background: none;
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    vertical-align: middle;
    padding: .4em;
    padding-right: 2em;
    font-family: @fontFamilyPrimary;
    height: 45px;
    
    &:hover {
        /*box-shadow: 0 0 1px 1px darken(@colorBlueTint, 15%);*/        
    }
    
    &:focus {
        outline: none;
        /*box-shadow: 0 0 1px 1px @colorFocus;*/
    }
    
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        
        &:hover {
            box-shadow: none;
        }
    }
}
// x-form
 .Form__Element select{
    padding: .4em;
    padding-right: 3em;
    font-family: @fontFamilyPrimary;
 }

//
.is-no-appearance {
    .Select {
        overflow: hidden;
    }
    
    .Select-input {
        width: ~"calc(100% + 60px)";
    }
}


.is-no-flexbox {
     .Select-input {
        width: ~"calc(100% + 70px)";
        position: relative;
        z-index:5;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance, 
    // IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5
    .Select-input::-ms-expand {
        display: none;
    }

    // This removes the odd blue bg color behind the text in the select button in IE 10/11 
    // and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box
    .Select-input:focus::-ms-value {
        background: transparent;
        color: @colorText;
    }
}