/*#

Examples:

<div>
     <div class="PageNavItem-parent IconToggler IconToggler-plusMinus"></div>
</div>

*/

@import "../../common/variables.less";

.IconToggler {
    &:before {
        content: 'IconOne';
        font-family:HomeIcons;
    }

    &.is-active {
        &:before {
            content: 'IconTwo';
           
        }
    }
    
    &:hover {
        text-decoration: none;
    }
}

.IconToggler--plusMinus {
    &:before {
        content: '\e90c';
    }

    &.is-active {
        &:before {
            content: '\e90f';
           
        }
    }
}

