﻿/*#
<div style="width:390px;">
    <div class="Teaser Teaser--animated Teaser--Image" style="background-image: url('/assets/images/Temp/dog.jpg');">
        <a href="#" class="Teaser-link u-linkText">
            <div class="Teaser-content u-transitionAlg">
                <h3 class="Teaser-heading">Har du husdjur som hårar?</h3>
                <p class="u-hidden u-lg-block">Upptäck vårt urval av dammsugare speciellt anpassade för hem med håriga vänner. Dessa ser till att din matta blir ren från hår, lukt och allergener.</p>
            </div>
        </a>
    </div>
</div>
*/
@import "../../common/variables.less";


.Teaser {
	position: relative;
	overflow: hidden;
}

.Teaser--plain {
	height: 170px;

	@media @bpLarge {
		height: 265px;
	}

	.Teaser-Icon {
		display: none;
	}

	.Teaser-content {
		position: absolute;
		bottom: 0;

		.Teaser-heading {
			margin-bottom: 0;
			max-width: 100%;
			overflow: hidden;
			font-family: @fontFamilyPrimary;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;

			@media @bpLarge {
				overflow: visible;
				text-overflow: inherit;
				white-space: inherit;
			}
		}
	}
	.Teaser-trueImage {
		display: none;
	}
}

.Teaser-content {
	background: @colorWhiteTransparent;

	p {
		margin-bottom: 0;
	}
}



.Teaser--campaign {
	height: 250px;

	@media @bpMedium {
		height: auto;
	}
}

.Teaser--Image {
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: cover;
	display: flex;
	width: 100%;

	&.Teaser--transform {
		display: block;

		img {
			width: 100%;
		}

		@media @bpMedium {
			background-repeat: no-repeat;
			background-position: 50% 0;
			background-size: cover;
			display: flex;
			width: 100%;
		}

		.Teaser-content {
			background: @colorWhite;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: @spaceMedium;

			@media @bpMedium {
				padding: @spaceMedium;
				background: @colorWhiteTransparent;
				position: absolute;
				bottom: 0;
			}
		}
	}
}


.Teaser-white {
    background: @colorWhite;
    display: flex;
    justify-content: center;

    img {

    }

    a.Teaser-link, div.Teaser-link {
        height: auto;
        width: auto;
        align-self: center;
    }

    .Teaser-content {
        background: @colorMossLight;
        left: 0;
        text-align: center;
    }
}

.Slider-wrapper {
	.Teaser--Image {
		position: relative;

		&.Teaser--transform {
			.Teaser-content {
				position: absolute;
				bottom: 0;
				padding-bottom: @spaceSmall;

				@media @bpMedium {
					background: @colorWhiteTransparent;
					padding-left: @spaceMedium;
					padding-right: @spaceMedium;
				}
			}

			.Teaser-text {
				line-height: 1.5em;
				display: none;

				@media @bpLarge {
					display: block;
				}
			}
		}
	}
}

.noWish {
	.Card-wish {
		display: none;
	}
}

.Teaser--ImageHsm {
    height: 120px;

    @media @bpMedium {
        height: 200px;
    }
}

    .Teaser--ImageHmd {
        height: 170px;

        @media @bpMedium {
            height: 300px;
        }

        &.Teaser--Image {
            &.Teaser--transform {
                height: auto;

                @media @bpMedium {
                    height: 300px;
                }
            }
        }
    }

    .Teaser--ImageHlg {
        @media @bpMedium {
            height: 400px;
        }
    }

    .Teaser-Icon {
        padding: 0.3em;
        background: rgba(255, 255, 255, 0.65);
        border-radius: 100%;
        position: absolute;
        top: @spaceXSmall;
        right: @spaceXSmall;
        z-index: 9;

        &:hover {
            background: rgba(255, 255, 255, 0.85);
            cursor: pointer;
        }
    }

    .Teaser-link {
        height: 100%;
        width: 100%;

        .Teaser-content {
            width: 100%;
            padding: @spaceSmall @spaceMedium;

            @media @bpLarge {
                padding: @spaceSmall @spaceMedium;
            }
        }
    }


    .Teaser-heading {
        margin-bottom: 0;
    }

    .Teaser-text {
        margin-bottom: 0;
    }


    .TeaserContent-left {
        position: relative;
        width: 100%;

        @media @bpLarge {
            width: 70% !important;
            height: 100%;
        }

        @media @bpXLarge {
            width: 50% !important;
            height: 100%;
        }
    }


    .TeaserContent-right {
        position: relative;
        width: 100%;

        @media @bpMedium {
        }

        @media @bpLarge {
            width: 50% !important;
            height: auto;
            right: @spaceLarge;
            bottom: @spaceLarge !important;
            position: absolute;
        }

        &.TeaserContentRight-xl {
            @media @bpLarge {
                width: 80% !important;
            }

            @media @bpXLarge {
                width: 60% !important;
            }
        }
    }


    .TeaserContent-center {
        position: absolute;
        width: auto !important;
        right: @spaceMedium;
        left: @spaceMedium;
        bottom: @spaceMedium !important;

        @media @bpMedium {
            height: auto;
            right: @spaceLarge;
            left: @spaceLarge;
            bottom: @spaceLarge !important;
        }
    }
    // animated
    .Teaser--animated {
        .Teaser-content {
            position: absolute;
            bottom: 2.8em; // height of heading
            transition: all 1s;
            backface-visibility: hidden;
            -ms-transform: translateY(100%);
            -webkit-transform: translateY(100%);
            transform: translateY(100%);

            @media @bpSmall {
                bottom: 2.5em; // height of heading
            }

            @media @bpMedium {
                bottom: 2.65em; // height of heading
            }

            @media @bpLarge {
                bottom: 2.7em; // height of heading
            }

            @media @bpXLarge {
            }
        }

        @media @bpLarge {
            .Teaser-heading {
                top: -4px;
                position: relative;
            }
        }
    }


    .TeaserAnimated-all {
        .Teaser-content {
            bottom: 0;
            position: absolute;

            @media @bpXLarge {
                -ms-transform: translateY(100%);
                -webkit-transform: translateY(100%);
                transform: translateY(100%);
            }
        }
    }


    .Teaser--animated {
        &:hover {
            .Teaser-link {
                .Teaser-content {
                    bottom: 0;
                    -ms-transform: translateY(0);
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                    width: 100%;
                }
            }
        }
    }


    .TeaserAnimated-all {

        &:hover {
            .Teaser-link {
                .Teaser-content {
                    -ms-transform: translateY(0);
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                }
            }
        }
    }


    .TeaserFollowSlider {
        .Teaser {
            @media @bpMedium {
                height: 440px;
            }
        }
    }


    .Teaser-double {
        .Teaser {
            &:first-child {
                margin-bottom: @spaceMedium;
            }
        }
    }

    .TeaserDouble-child {
        .Teaser {
            @media @bpLarge {
                height: 552px;
            }
        }
    }
    /* different teasers in list */
    .Teaser-heightEqualmd {
        .Teaser {
            height: 300px;

            &.Teaser--Image {
                &.Teaser--transform {
                    height: auto;

                    @media @bpMedium {
                        height: 300px;
                    }
                }
            }
        }
    }
