@import "../../common/variables.less";

.Holiday {
    .Holiday-frame {
        display: none;
    }

    .HolidayFrame--left {
        background: url(../../../Images/Temp/christmas-frame-left--fade.png) left 0 no-repeat;
        left: 0;
    }

    .HolidayFrame--right {
        right: 0;
        background: url(../../../Images/Temp/christmas-frame-right--fade.png) right 0 no-repeat;
    }
}

@media screen and (min-width: 1545px) {
    .Holiday {
        .Holiday-frame {
            display: block;
            position: absolute;
            width: 15%;
            z-index: 1;
            top: 0;
        }

        .Footer, .Topfooter {
            position: relative;
            z-index: 4;
        }


        .Topfooter {
            /*-webkit-box-shadow: -4px -6px 41px -9px rgba(0,0,0,0.5);
            -moz-box-shadow: -4px -6px 41px -9px rgba(0,0,0,0.5);
            box-shadow: -4px -6px 41px -9px rgba(0,0,0,0.5);*/
        }
    }
}
