﻿@import (reference) "../common/variables.less";

html {
	box-sizing: border-box;
	font-size: 100%;
	font-family: @fontFamilyPrimary;
	font-size: @bodyFontSizeBase;
	line-height: 1.8;
	// increase font sizes for wider screen widths
	@media only screen and (min-width: unit((@widthSmall * @baseFontSize), px)), print {
		font-size: @bodyFontSizeSm;
		line-height: 1.55;
	}

	@media only screen and (min-width: unit((@widthMedium * @baseFontSize), px)), print {
		font-size: @bodyFontSizeMd;
	}

	@media only screen and (min-width: unit((@widthLarge * @baseFontSize), px)), print {
		font-size: @bodyFontSizeLg;
	}

	@media only screen and (min-width: unit((@widthXLarge * @baseFontSize), px)), print {
		font-size: @bodyFontSizeXLg;
	}
}

*, *::before, *::after {
	box-sizing: inherit; // https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
}

body {
	background-color: @colorWhite;
	color: @colorText;
	height: 100%;
}
/*body{
        background:red;
             @media @bpXSmall{    background:blue;}
          @media @bpSmall{    background:yellow;}
              @media @bpMedium{    background:orange;}
               @media @bpLarge{    background:green;}
               @media @bpXLarge{    background:pink;}
                 
}*/
/** 
 * Typography
 */

// NOTE: replace this with custom Web Fonts

/*@font-face {
    font-family: 'DIN-Next';
    src: url('/Assets/Fonts/Din/DINNextLTPro-Medium.eot');
    src: url('/Assets/Fonts/Din/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'), url('/Assets/Fonts/Din/DINNextLTPro-Medium.woff') format('woff'), url('/Assets/Fonts/Din/DINNextLTPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}*/

@font-face {
	font-family: 'elux-light';
	src: url('/Assets/Fonts/ElectoluxSans/light/electroluxsans_2_light-webfont.eot');
	src: url('/Assets/Fonts/ElectoluxSans/light/electroluxsans_2_light-webfont.eot?#iefix') format('embedded-opentype'), url('/Assets/Fonts/ElectoluxSans/light/electroluxsans_2_light-webfont.svg') format('svg'), url('/Assets/Fonts/ElectoluxSans/light/electroluxsans_2_light-webfont.ttf') format('truetype'), url('/Assets/Fonts/ElectoluxSans/light/electroluxsans_2_light-webfont.woff') format('woff'), url('/Assets/Fonts/ElectoluxSans/light/electroluxsans_2_light-webfont.woff2') format('woff2');
}


@font-face {
	font-family: 'elux-regular';
	src: url('/Assets/Fonts/ElectoluxSans/regular/electroluxsans_3_regular-webfont.eot');
	src: url('/Assets/Fonts/ElectoluxSans/regular/electroluxsans_3_regular-webfont.eot?#iefix') format('embedded-opentype'), url('/Assets/Fonts/ElectoluxSans/regular/electroluxsans_3_regular-webfont.svg') format('svg'), url('/Assets/Fonts/ElectoluxSans/regular/electroluxsans_3_regular-webfont.ttf') format('truetype'), url('/Assets/Fonts/ElectoluxSans/regular/electroluxsans_3_regular-webfont.woff') format('woff'), url('/Assets/Fonts/ElectoluxSans/regular/electroluxsans_3_regular-webfont.woff2') format('woff2');
}

@font-face {
	font-family: 'elux-semiBold';
	src: url('/Assets/Fonts/ElectoluxSans/semiBold/electroluxsans_4_semibold-webfont.eot');
	src: url('/Assets/Fonts/ElectoluxSans/semiBold/electroluxsans_4_semibold-webfont.eot?#iefix') format('embedded-opentype'), url('/Assets/Fonts/ElectoluxSans/semiBold/electroluxsans_4_semibold-webfont.svg') format('svg'), url('/Assets/Fonts/ElectoluxSans/semiBold/electroluxsans_4_semibold-webfont.ttf') format('truetype'), url('/Assets/Fonts/ElectoluxSans/semiBold/electroluxsans_4_semibold-webfont.woff') format('woff'), url('/Assets/Fonts/ElectoluxSans/semiBold/electroluxsans_4_semibold-webfont.woff2') format('woff2');
}


// NOTE: heading stylings can be found in Heading.less
p {
	margin-top: 0;
	margin-bottom: 1em;
	font-size: @textSizeMedium;
	line-height: 25px;

	@media @bpSmall {
	}
}

/*b {
	font-family: @fontFamilyHeading--light;
	line-height: 25px;
	font-size: @textSizeMedium;
}*/

small {
	font-size: @textSizeSmall;
	line-height: 20px;
}

ol, ul, dl {
	// margin-top: 0;
	margin-bottom: 1em;
}
// reset margin-bottom on nested combinations of "ol ul"
ol, ul {
	& & {
		margin-bottom: 0;
	}
}

code {
	background: @colorBlueTint;
	padding: 0 3px;
	border-radius: 4px;
}


*:focus {
	outline: none;
}
/** 
 * Links
 */
a {
	color: @colorText;
	text-decoration: none;
}

p {
	a {
		border-bottom: 1px solid @borderColor;

		&:hover {
			border-bottom: 1px solid @colorOrange;
		}
	}
}

a:hover {
	color: @colorLinkHover;
}

.inverted {
	* {
		color: @colorTextWhite;

		a {
			&:hover {
				color: @colorBlueTint !important;
			}
		}
	}
}


.readMore {
	color: @colorText;
	font-size: @textSizeSmall;

	&:hover {
		text-decoration: none;
		color: @colorOrange;
	}

	i {
		position: relative;
		font-size: 0.6em;
	}
}

.ReadMore-link {
	&:after {
		font-family: 'HomeIcons';
		content: "\e921";
		margin-left: .4em;
		font-size: .7em;
	}
}
/**
 * Elements  
 */
img {
	max-width: 100%;
	vertical-align: middle;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid @colorBlueTint;
	margin: @spaceMedium 0;
	padding: 0;
}

figure {
	margin: 0;
}
/**
 * Tables
 */
table {
	width: 100%;
	max-width: 100%;
	margin: 0 0 1em 0;

	th, td {
		padding: .5em;
		vertical-align: top;
		text-align: left;
	}

	th {
		border-bottom: 2px solid @colorBlueTint;
	}
}


.no-scroll {
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: hidden;
}
/**
 * Form  
 */
input[type=text],
input[type=password],
input[type=number],
input[type=search],
input[type=tel],
input[type=email],
input[type=url],
textarea {
	font-family: @fontFamilyPrimary;
	border: 1px solid #aaa;
	box-sizing: border-box;
	border-radius: 2px;
	width: 100%;
	-webkit-box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
	box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
	color: @colorText !important;

	&:focus {
		outline: none;
	}

	&:disabled {
		background: lighten(@colorBlueTint, 10%);
		opacity: 0.8;
		color: @colorTextLight;
		cursor: not-allowed;
	}
}

textarea {
	height: 5.8em; // three rows of text
	resize: vertical;
}

label {
	display: inline-block;
}

mark {
	background-color: transparent;
}
/**
 * Form Validation
 */
.field-validation-valid,
.field-validation-error {
	display: block;
	font-size: @textSizeSmall;
	font-style: italic;
	color: @colorRed;
}

.field-validation-valid {
	display: none;
}
//.valid {
//  border-color: green !important;
//}
.input-validation-error {
	border-color: @colorRed !important;
}


.validation-summary-valid {
	display: none;
}

.validation-summary-errors {
	display: block;
	width: 100%;
}
/** 
 * Print styles.
 * Inlined to avoid required HTTP connection.
 */
@media print {
	@page {
		margin: 0.5cm;
	}

	*,
	*:before,
	*:after {
		background: transparent !important;
		color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	pre {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	/*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
	// remove Episerver bar from print
	#epi-quickNavigator {
		display: none !important;
	}
}

.u-printShow {
	display:none;
}
