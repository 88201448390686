﻿.Theme {
}

.Theme--white {
    background: @colorWhite;
    color: @colorBlueRich;

    .Heading {
        color: @colorBlueRich;

        &:hover {
            color: @colorBlueRich;
        }
    }
}



.Theme--grey {
    background: @colorGrey;
    color: @colorSoftBlack;

    .Heading {
        color: @colorSoftBlack;
    }

    &:hover {
        color: @colorSoftBlack;
    }
}
//
.Theme--mahogany {
    background: @colorMahoganyTint;
    color: @colorMahoganyRich;

    .Heading {
        color: @colorMahoganyRich;
    }

    &:hover {
        color: @colorMahoganyRich;
    }

    &--rich {
        background: @colorMahoganyRich;
        color: @colorWhite;

        .Heading {
            color: @colorWhite;
        }

        &:hover {
            color: @colorWhite;
        }
    }
}
//
.Theme--moss {
    background: @colorMossTint;
    color: @colorMossRich;

    .Heading {
        color: @colorMossRich;
    }

    &:hover {
        color: @colorMossRich;
    }

    &--rich {
        background: @colorMossRich;
        color: @colorWhite;

        .Heading {
            color: @colorWhite;
        }

        &:hover {
            color: @colorWhite;
        }
    }
}

.Theme--umber {
    background: @colorUmberTint;
    color: @colorUmberRich;

    .Heading {
        color: @colorUmberRich;
    }

    &:hover {
        color: @colorUmberRich;
    }

    &--rich {
        background: @colorUmberRich;
        color: @colorWhite;

        .Heading {
            color: @colorWhite;
        }

        &:hover {
            color: @colorWhite;
        }
    }
}

.Theme--blue {
    background: @colorBlueTint;
    color: @colorBlueRich;

    .Heading {
        color: @colorBlueRich;
    }

    &--rich {
        background: @colorBlueRich;
        color: @colorWhite;

        .Heading {
            color: @colorWhite;
        }

        .Button {
            .Button--light;
        }

        &:hover {
            color: @colorWhite;
        }
    }
}

.Theme--light {
    background-color: transparent;
    color: @colorWhite;
    .Heading {
        color: @colorWhite;
    }
    .Button--outlined {
        .Button--outlined;
        border-color: @colorWhite;
        color: @colorWhite;
        &:hover {
            border-color: @colorDarkGrey;
            color: @colorDarkGrey;
        }
    }
}

.Theme--dark {
    background-color: transparent;
    color: @colorBlack;

    .Heading {
        color: @colorBlack;
    }

    .Button--outlined {
        .Button--outlined;
        border-color: @colorBlack;
        color: @colorBlack;

        &:hover {
            border-color: @colorDarkGrey;
            color: @colorDarkGrey;
        }
    }
}
