@import (reference) "../common/variables.less";

/**
* Add classes for common colors.
*/

.u-colorText {
    color: @colorText!important;
}

.u-colorTextLight {
    color: @colorTextLight;
}

.u-colorTextWhite {
    color: @colorTextWhite;
}

.u-colorLightOrange{
	color: @colorOrange !important;
}
.u-colorTextDimGrey {
    color: @colorDimGrey !important;
}

.u-colorSoftBlack {
    color: @colorSoftBlack !important;
}

.u-colorWhite {
    color: @colorWhite;
}

// Examples Text Colors:
// .u-colorWhite {
// 	color: #fff !important;
// }
//

.u-colorBGblack {
    background-color: @colorBlack !important;
}

.u-colorBGsteel {
    background-color: @colorSteel !important;
}

.u-colorSteel{
  color:@colorSteel!important;
}

.u-colorBGlightBlue {
    background-color: @colorLightBlue !important;
}

.u-colorBGWhite {
    background-color: @colorWhite !important;
}

.u-colorBGBlueRich {
  background-color: @colorBlueRich !important;
}
//
.u-colorGreen {
  color: @colorGreen !important;
}

.u-colorBglightGray {
    background: @colorBlueTint;
}

.u-colorRed {
    color: @colorRed !important;
}

.u-colorDimGrey {
    color: @colorDimGrey;
}

.u-colorBlueRich {
    color: @colorBlueRich !important;
}
.u-colorSoftBlack {
    color: @colorSoftBlack !important;
}
// Example Background Colors:

// .u-colorBgRed {
// 	background-color: @colorRed !important;
// }
