.KitchenCollectionBlock {
    &-content {
        display: flex;
        flex-direction: column;
        padding-bottom: @spaceXXXLarge;
        
        @media @bpMedium {
            padding-bottom: 0;
        }

        .KitchenBlockLink;
    }
}