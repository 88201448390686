﻿/**
	Flowbox style
*/

@import "../../common/variables.less";

.Flowbox {
	.FlowboxFilter {
		@media @bpMedium {
			margin-bottom: 8px;
		}

		.FlowboxButton {
			margin: 5px;
			font-size: @Button-textSize;
			font-weight: 600;
			color: @colorBlack;
			padding: @spaceXXSmall @spaceSmall;
			border: 2px solid @colorBlack;
			width: fit-content;

			&:hover,
			&.active {
				border-color: @colorOrange;
				color: @colorOrange;
			}

			&.FlowboxButton--big {
				margin-top: 26px;
				font-size: 14px;
				padding: 11px 32px;
			}
		}
	}
}
