/*#

Dispaly how many items there is

Examples:
<div style="width:50px; position:relative; height:60px;">
    <span class="Quantity">38</span>
</div>
*/

@import "../../common/variables.less";

.Glossory{}

.glossary-word {
    border-bottom: 2px dotted @colorBlueMid;
    cursor: pointer;

    &:hover {
        border-color: @brandColor;
    }

    @media print {
        border: none;
    }
}