@import "../../common/variables.less";

// Local variables
@checkoutMainHeader: 22px;

.Checkoutv2 {
  // General styling
  color: @colorBlueRich;

  a {
    color: @colorSoftBlack;
    text-decoration: underline;

    &:hover {
      border-bottom: none !important;
    }
  }

  hr {
    color: @colorDarkGrey;
    margin: 32px 0;
  }

  .Icon {
    margin-right: 6px;
    font-size: 20px;
  }

  .Checkout-header {
    .Checkout-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1, h2 {
        font-size: @checkoutMainHeader;
        color: @colorBlueRich;
      }

      span {
        color: @colorBlueRich;
      }
    }
  }

  .Checkout-heading {
    font-size: 22px;
    padding-block: 0;
    color: @colorBlueRich;
  }

  .NextStepHeading {
    color: @colorBlueMid;
    display: flex;
    align-items: center;

    path {
      fill: @colorBlueMid;
    }
  }

  .InfoBlock {
    display: none;
    padding: 12px;
    margin-block: 32px;
    font-size: 13px;
  }

  .ArticleNumber {
    color: @colorDimGrey;
    font-size: 15px;
    display: inline-block;
    flex: 1;
  }

  .ArticleName {
    font-size: 15px;
  }

  input {
    border: 1px solid @colorBlueRich;
  }

  .Checkbox-label {
    font-weight: 600;
    font-size: 15px;
  }

  .Form-label {
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 6px 0;
  }

  .ButtonWrapper {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 15px;

    @media @bpSmall {
      gap: 0px;
      flex-direction: row;
    }
  }

  .Button {
    font-weight: 600;

    &--grey {
      background-color: #EAEAEB;

      &:hover {
        background-color: @colorOrange;
      }
    }
  }

  .Form,
  .NextCheckoutStep,
  .PreviousCheckoutStep {
    border: 1px solid @borderColor;
    padding: 20px 16px;
    margin-bottom: 16px;
  }

  .PreviousCheckoutStep {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:hover {
      cursor: pointer;
    }
  }

  .field-validation-error {
    color: @colorOrange;
    font-weight: 600;
    font-style: initial;
    margin-top: 6px;
  }
  // Step specific styling

  .Checkout-Additions {

    .AdditionsImage {
      height: 56px;
    }

    .AdditionsPrice {
      font-size: 15px;
    }
  }

  .Checkout-Shipping {
    .ShippingImage {
      height: 56px;
      aspect-ratio: 1;
      margin-right: 15px;
    }

    .Checkout-ShippingDetails {
      display: block;
      margin-left: 18px;

      @media @bpSmall {
        display: inline-block;
        margin-left: 0;
      }
    }

    .Shipping-PickupInfo {
      margin-left: 18px;

      @media @bpSmall {
        margin-left: 0;
      }
    }
  }

  .Checkout-Payment {
    .List-item {
      color: @colorSoftBlack;
      font-size: 15px;
      background-color: @colorGrey;
      margin-bottom: 15px;
      padding: 8px !important;
    }

    .Radio-label:before,
    .Payment-Icon {
      margin-right: 12px;
    }

    a {
      color: @colorBlueRich;
    }

    .Checkbox-label {
      font-weight: 400;
      font-size: 16px;
    }
  }
  // Aside/cart summary
  .CheckoutSummary {

    .Accordion-header:after {
      @media @bpSmall {
        display: none;
      }
    }

    color: @colorSoftBlack;

    .Section--alt3 {
      padding: 20px;
    }

    .CheckoutHeading {
      display: flex;
      font-size: 22px;
      margin-bottom: 20px;

      a {
        text-decoration: none;
      }
    }

    .Checkout-remove {
      color: @colorDimGrey;
    }

    .SummaryWrapper {
      display: flex;
      align-items: center;
    }

    .SummaryHeading {
      font-size: 15px;
      display: inline-block;
      margin-bottom: 0;
      margin-right: 8px;
    }

    .Summary-articleNr {
      color: @colorDimGrey;
      font-size: 15px;
      display: inline-block;
      flex: 1;
    }

    .SummaryImage {
      padding-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center
    }

    .SummaryImage {
      mix-blend-mode: multiply;
      padding-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center
    }

    .Summary-numberOfArticlesWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      a {
        text-decoration: none;
        display: flex;
        align-items: center
      }

      .Icon {
        margin: 0;
      }

      .Summary-numberOfArticles {
        margin-inline: 10px;
      }
    }
  }
}
