﻿/*#

Component for products energy information.

*/

@import "../../common/variables.less";

.Energy {
	//display: flex;
	text-align: left;

	img.svg {
		max-height: 40px;
		height: 40px;
	}
}

.Energy-badge {
	flex-shrink: 0;

	img {
		min-height: 35px;
	}

	img.svg {
		max-height: 40px;
		height: 40px;
	}
}

.Energy-label {
    align-self: flex-start;
    display: block;
    @media print {
        display: none !important;
    }
}

.Product-info {
	.Energy{
		margin-bottom: @spaceSmall;
		@media @bpMedium{
			margin-bottom: 0;
		}
	}
}