﻿/*#
Pager

<div style="width:960px; position:relative;">
  
      <div class="Pager">
            <div class="Pager-more">
                <button class="Button Button--dark Button--small u-paddingHxlg">Visa fler</button>
                <span class="Pager-shows">Visar 12 av 23</span>
            </div>
            <button class="Button Button--dark Button--small Pager-all">Visa alla</button>
           
        </div>
</div>

*/

@import "../../common/variables.less";

.Pager {
   margin: @spaceLarge 0 @spaceSmall;
   position:relative;
}

.Pager-more{
    @media @bpSmall{
    text-align: center;
    }

&.Pager-singleButton{
    text-align: center;
}

}

.Pager-shows{
  display:block;
  margin-top: @spaceXSmall;
  text-align:center;
  }
.Pager-all{
    position: absolute;
    right:0;
    top:0;
}