﻿/*#

Generic card for displaying excerpts from other content pages.

Examples:
<div style="background: #e6eef5">
<div style="max-width:395px">
    Product card
   <div class="Card">
    <div class="Campaign">
    <span class="Campaign-text">Kampanj</span>
    </div>
   <div class="Card-header">
        <img class="Card-logo" src="/Assets/Images/Logo/electroluxPlogo.png" />
        <i class="Icon Icon--wish-active Card-wish"></i>
    </div>
    <div class="u-flexAuto">
    <a class="Card-linkWrapper" href="#">
        <div class="CardMedia--center">

            <img src="/Assets/Images/Temp/vaccum.png" />
        </div>
        <div class="Card-content">

            <span class="Card-articleNr">zuoorgw+</span>
            <h3 class="Card-heading">Liten och lätthanterlig dammsugare med hygienfilter</h3>
            <span class="Card-energiClass"><img src="/Assets/Images/Temp/energy.png" /></span>
            <ul class="List List--vertical List-bullet Card-list">
                <li class="List-item u-textTruncate">Mjuka gummihjul</li>
                <li class="List-item u-textTruncate">AeroPro 3-i-1 munstycke</li>
                <li class="List-item u-textTruncate">Höjd x Bredd x Djup: 285 x 305 x 395 mm</li>
            </ul>
        </div>
    </a>
    </div>
    <div class="Card-links">
        <a href="#" class="readMore">
            Läs mer <i class="Icon Icon--readMore"></i>
        </a>

        <span class="Checkbox Card-checkbox">
            <input id="cb112" type="checkbox" name="check" class="Checkbox-input">
            <label for="cb112" class="Checkbox-label">Jämför</label>
        </span>
        </div>
     </div>
</div>
</div>

*/
@import "../../common/variables.less";


.Campaign {
    position: relative;
    top: 14px;
    left: @spaceMedium;
}

.Campaign .Campaign-text {
    display: block;
    background: @colorOrange; /* Old browsers */
    background: -moz-linear-gradient(top, @colorOrange 1%, #f28846 51%, #f4721f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, @colorOrange 1%,#f28846 51%,#f4721f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, @colorOrange 1%,#f28846 51%,#f4721f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@colorOrange', endColorstr='#f4721f',GradientType=0 ); /* IE6-9 */
    border: 0 solid rgba(0,0,0,0.2);
    color: #fff;
    font-size: @textSizeMedium;
    font-weight: 500;
    position: relative;
    text-shadow: -1px -1px 1px rgba(0,0,0,0.2);
    border-width: 1px 1px 2px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.5);
    margin: 0;
    padding: @spaceXXXSmall @spaceLarge;
    position: absolute;
    right: -14px;
    top: 12px;

    &.CampaignText--gray {
        background: #444;
    }
}


div.Campaign h2 {
}


div.Campaign .Campaign-text:after {
    border-width: 7px;
    border-style: solid;
    border-color: #134 transparent transparent #134;
    bottom: -15px;
    content: "";
    position: absolute;
    right: -1px;
}


div.Campaign--left .Campaign-text {
    left: -@spaceLarge;
    right: auto;
    top: 34px;
}

@media @bpLarge {
  div.Campaign-disclaimer {
    margin-left: 200px;
    margin-right: 200px;
  }
}

div.Campaign--left .Campaign-text:after {
  border-color: #134 #134 transparent transparent;
  left: 0;
  right: auto;
}


/* Ribbon */

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 148px;
    height: 148px;
    text-align: right;

    @media print {
        display: none !important;
    }
}

.ribbon span {
    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 30px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 200px;
    display: block;
    background: @colorRed;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 47px;
    right: -37px;
}

.ribbon--orange {
    background: @colorOrange !important;
    color: @colorWhite;
}

.ribbon--bluerich {
    background: @colorBlueRich !important;
    color: @colorWhite;
}

.ribbon--silklight {
    background: @colorSilkLight !important;
    color: @colorWhite;
}

.ribbon--umbermid {
    background: @colorUmberMid !important;
    color: @colorWhite;
}

.ribbon--black {
    background: @colorBlack;
    color: @colorOrange;
}

.ribbon--mossmid {
    background: @colorMossMid !important;
    color: @colorWhite;
}

.ribbon--bluemid {
    background: @colorBlueMid !important;
    color: @colorWhite;
}

.ribbon--mahoganymid {
    background: @colorMahoganyMid !important;
    color: @colorWhite;
}

.ribbon--red {
    background: @colorRed !important;
    color: @colorWhite;
}

.ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid @colorRed;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid @colorRed;
}

.ribbon--red span::before {
    border-left: 3px solid @colorRed !important;
    border-top: 3px solid @colorRed !important;
}

.ribbon--orange span::before {
    border-left: 3px solid @colorOrange !important;
    border-top: 3px solid @colorOrange !important;
}

.ribbon--bluerich span::before {
    border-left: 3px solid @colorBlueRich !important;
    border-top: 3px solid @colorBlueRich !important;
}

.ribbon--silklight span::before {
    border-left: 3px solid @colorSilkLight !important;
    border-top: 3px solid @colorSilkLight !important;
}

.ribbon--black span::before {
    border-left: 3px solid @colorSteel !important;
    border-top: 3px solid @colorSteel !important;
}

.ribbon--bluemid span::before {
    border-left: 3px solid @colorBlueMid !important;
    border-top: 3px solid @colorBlueMid !important;
}

.ribbon--mahoganymid span::before {
    border-left: 3px solid @colorMahoganyMid !important;
    border-top: 3px solid @colorMahoganyMid !important;
}

.ribbon--mossmid span::before {
    border-left: 3px solid @colorMossMid !important;
    border-top: 3px solid @colorMossMid !important;
}

.ribbon--umbermid span::before {
    border-left: 3px solid @colorUmberMid !important;
    border-top: 3px solid @colorUmberMid !important;
}

.ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid @colorRed;
    border-bottom: 3px solid transparent;
    border-top: 3px solid @colorRed;
}

.ribbon--red span::after {
    border-right: 3px solid @colorRed !important;
    border-top: 3px solid @colorRed !important;
}

.ribbon--orange span::after {
    border-right: 3px solid @colorOrange !important;
    border-top: 3px solid @colorOrange !important;
}

.ribbon--bluerich span::after {
    border-right: 3px solid @colorBlueRich !important;
    border-top: 3px solid @colorBlueRich !important;
}

.ribbon--silklight span::after {
    border-right: 3px solid @colorSilkLight !important;
    border-top: 3px solid @colorSilkLight !important;
}

.ribbon--black span::after {
    border-right: 3px solid @colorSteel !important;
    border-top: 3px solid @colorSteel !important;
}

.ribbon--bluemid span::after {
    border-right: 3px solid @colorBlueMid !important;
    border-top: 3px solid @colorBlueMid !important;
}

.ribbon--mahoganymid span::after {
    border-right: 3px solid @colorMahoganyMid !important;
    border-top: 3px solid @colorMahoganyMid !important;
}

.ribbon--mossmid span::after {
    border-right: 3px solid @colorMossMid !important;
    border-top: 3px solid @colorMossMid !important;
}

.ribbon--umbermid span::after {
    border-right: 3px solid @colorUmberMid !important;
    border-top: 3px solid @colorUmberMid !important;
}