/*#

Display badges with optional arrow pointing in any of following directions: top, bottom, right, left. 
The badge can display borders using Badge--bordered.

Examples:

<div>
    <a href="#" class="Tag">Produkter (36)</a> <a href="#" class="Tag">Inspiration (54)</a> <a href="#" class="Tag">Butiker (4)</a>
</div>

*/

@import "../../common/variables.less";
@tagBoarder: @colorMidGrey;
@tagFontSize: 15/16em;
@tagPadding: 0.5em;

.Tag {
    color: @colorText;
    border: 1px solid @tagBoarder;
    font-family: @fontFamilyCondensed;
    font-size: @tagFontSize;
    border-radius: 25px;
    padding: @tagPadding @spaceMedium;
    background: @colorWhite;
    display: inline-block;
    margin-bottom: @spaceXSmall;

    &:hover {
        background: @colorBlueTint;
        border-color: @colorMidGrey;
        color: inherit;
    }

    &.is-active {
        background: @colorBlack;
        color: @colorTextWhite;
    }

    &--smallOrange {
        display: inline;
        color: @colorOrange;
    }
}
