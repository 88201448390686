/*#

Dispaly how many items there is

Examples:
<div style="width:50px; position:relative; height:60px;">
    <span class="Quantity">38</span>
</div>
*/

@import "../../common/variables.less";

.Article {
    h2, h3, h4 {
        margin-top: @spaceMedium;
    }

    p {
        margin-bottom: @spaceLarge;

        a {
            border-bottom: 1px solid @borderDarkColor;

            &:hover {
                border-bottom: 1px solid @colorOrange;
            }
        }
    }

    ul, ol {
        padding-left: 1em;
        margin-bottom: @spaceMedium;
    }

    li {
        margin-bottom: @spaceXSmall;
    }

    iframe {
        margin-bottom: @spaceLarge;
        max-width: 100%;
    }

    .u-textLeft {

        .List--gutterHlg {
            padding-left: 0;
            margin-bottom: 0;
        }
    }
}

.Article-mainImage, .Article-mainVideo {
    width: 100%;
    margin-bottom: @spaceMedium;

    @media @bpMedium {
        margin-bottom: @spaceLarge;
    }
}



    .Article-mainVideo {
        iframe {
            margin-bottom: 0;
        }
    }

    .Article-author {
        font-size: @textSizeSmall;
        clear: both;
    }

    .Article-share {
        border-top: @borderDotted @borderColor;
        padding-top: @spaceMedium;
        margin-top: @spaceLarge;
        overflow: hidden;
        margin-bottom: @spaceXLarge;
        clear: both;
    }


.ArticleShare--small {
    display: inline-block;
    margin-left: @spaceMedium;

    .st_email {
        position: relative;
        top: -1px;
    }
}

.ArticleShare-heading {
    position: relative;
    top: -10px;
    margin-top: 0;
    display: inline;
    margin-right: @spaceSmall;

    &.ArticleShareHeading--small {
        top: 0;
        margin-right: 0;
        
        font-size: @textSizeSmall;
    }
}


blockquote {
    font-size: @textSizeXXLarge;
    font-style: italic;


    &:before, &:after {
        content: "\e90d";
        font-family: HomeIcons;
        position: relative;
        top: -5px;
        font-size: 0.8em;
    }

    &:before {
        margin-right: @spaceXSmall;
    }

    &:after {
        margin-left: @spaceXSmall;
    }
}

