﻿@import "../../common/variables.less";

.inStore {

    .Loader {
        display: none;


        &.Loader-inStore {
            display: block;
            position: relative;
            margin: 0 0 0 -50px;

            .Icon {
                font-size: 15rem;
                position: absolute;
                z-index: 10;
                top: -43px;
                //font-size: 15rem;
                font-size: 250px;
                color: #fafafa;

                &:before {
                    color: #fafafa;
                }
            }

            .Loader-image {
                font-size: 15rem;
                position: absolute;
                z-index: 10;
                top: -33px;
                left: -10px;
                width: 220px;
            }

            .Loader-eyes {
                background: @colorSteel;
                height: 200px;
                width: 90px;
                position: absolute;
                top: -27px;
                left: 10px;
            }
        }

        @keyframes lds-disk {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @-webkit-keyframes lds-disk {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        .lds-disk {
            position: relative;
        }

        .lds-disk > div div:nth-child(1) {
            position: absolute;
            top: 30px;
            left: 30px;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            background: #d3e7ef;
        }

        .lds-disk > div div:nth-child(2) {
            position: absolute;
            top: 34.2px;
            left: 79px;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background: #ff5d21;
            -webkit-animation: lds-disk 1s linear infinite;
            animation: lds-disk 1s linear infinite;
            -webkit-transform-origin: 21px 65.8px;
            transform-origin: 21px 65.8px;
        }

        .lds-disk {
            width: 200px !important;
            height: 200px !important;
            -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
            transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
        }
    }
}