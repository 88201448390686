.KitchenBlock {
    padding-left: 20px;
    padding-right: 20px;
    max-width: @widthContainerLarge;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    @media @bpXLarge {
        padding: 0;
    }

    &--verticalSpaceLarge {
        padding-top: 48px;
        padding-bottom: 48px;

        @media @bpXLarge {
            padding: 72px 0;
        }
    }

    &--wide {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }

    &--grey {
        background-color: @colorGrey;
    }

    h2 {
        font-size: 22px;
        
        @media @bpMedium {
            font-size: 32px;
            line-height: 38px;
        }
    }
}

.KitchenBlockLink {
    a {
        margin-top: 26px;
        font-size: 14px;
        font-weight: 600;
        color: @colorBlack;
        padding: 11px 32px;
        border: 2px solid @colorBlack;
        width: fit-content;

        &:hover {
            border-color: @colorOrange;
            color: @colorOrange;
        }
    }
}

.KitchenImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
}


