﻿/*#

Compare products in this table

<div style="max-width:1200px;">
  <div class="scrollable has-scroll">
    <div>
        <table class="Compare">
            <thead class="Compare-head">
                <tr>
                    <th class="col-1 u-hidden u-md-tableCell">
                    </th>
                    <th class="col-1">
                        <div class="Compare-imageWrapper">
                            <a href="#" class="Compare-remove">
                                <i class="Icon Icon--close"></i>
                            </a>
                            <figure class="Compare-figure">
                                <img class="compare-image" src="/Assets/Images/Temp/P040661_755085954.png" width="130" />
                            </figure>
                            <h3 class="Compare-heading">Ekx1354tr</h3>
                        </div>
                    </th>
                    <th class="col-1">
                        <div class="Compare-imageWrapper">
                            <a href="#" class="Compare-remove">
                                <i class="Icon Icon--close"></i>
                            </a>
                            <figure class="Compare-figure">
                                <img class="compare-image" src="/Assets/Images/Temp/P040661_755085954.png" width="130" />
                            </figure>
                            <h3 class="Compare-heading">t6x1994ka</h3>
                        </div>
                    </th>

                    <th class="col-1">
                        <div class="Compare-imageWrapper">
                            <a href="#" class="Compare-remove">
                                <i class="Icon Icon--close"></i>
                            </a>
                            <figure class="Compare-figure">
                                <img class="compare-image" src="/Assets/Images/Temp/P040661_755085954.png" width="130" />
                            </figure>
                            <h3 class="Compare-heading">t6x1994ka+</h3>
                        </div>
                    </th> 
                </tr>
            </thead>
            <tfoot>
            </tfoot>
            <tbody>
                <tr class="Compare-header">
                    <th class="header--fixed">
                        <div class="header--fixed__item">
                            <h4> Mått och installation</h4>
                        </div>
                    </th>
                    <th></th>
                    <th></th>
                </tr>

                <tr class="u-tableRow u-md-hidden">
                    <th class="header--fixed">
                        <div class="header--fixed__item">Mått HXBXD, mm</div>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                <tr class="Compare-different">
                    <th class="u-hidden u-md-tableCell">Mått HXBXD, mm</th>
                    <td>893-912x596x600</td>
                    <td>850-939x696x600</td>
                    <td>850-939x696x600</td>
                </tr>
                <tr class="u-tableRow u-md-hidden">
                    <th class="header--fixed">
                        <div class="header--fixed__item">Höjd, mm</div>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th class="u-hidden u-md-tableCell">Höjd, mm</th>
                    <td>900</td>
                    <td>900</td>
                    <td>900</td>
                </tr>
                <tr class="u-tableRow u-md-hidden">
                    <th class="header--fixed">
                        <div class="header--fixed__item">Bredd, mm</div>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                <tr class="Compare-different">
                    <th class="u-hidden u-md-tableCell">Bredd, mm</th>
                    <td>596</td>
                    <td>900</td>
                    <td>900</td>
                </tr>
                <tr class="u-tableRow u-md-hidden">
                    <th class="header--fixed">
                        <div class="header--fixed__item">Djup, mm</div>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th class="u-hidden u-md-tableCell">Djup, mm</th>
                    <td>600</td>
                    <td>600</td>
                    <td>600</td>
                </tr>
                <tr class="u-tableRow u-md-hidden">
                    <th class="header--fixed">
                        <div class="header--fixed__item">Nettovikt, kg</div>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                <tr class="Compare-different">
                    <th class="u-hidden u-md-tableCell">Nettovikt, kg</th>
                    <td>72</td>
                    <td>54</td>
                    <td>96</td>
                </tr>
              
             
            </tbody>
        </table>
    </div>
</div>

</div>
*/

@import "../../common/variables";

.Compare {
    display: table;
    border-collapse: collapse;

    th, td {
        border-right: @borderDotted @borderColor;
        border-bottom: @borderDotted @borderColor;
        font-family: @fontFamilyPrimary;
        font-weight: normal;
        padding: @spaceXSmall;



        &:last-child {
            border-right: none;
        }
    }

    @media @bpMedium {
        width: 100%;

        th {
            padding-left: 0;
        }
    }
}

.Compare-figure {
}

.Compare-imageWrapper {
    text-align: center;
    background: @colorWhite;
    padding: @spaceMedium @spaceSmall;
    position:relative;
}

.Compare-remove{
    position:absolute;
    top: @spaceSmall;
    right: @spaceSmall;
}
.Compare-header {
    padding: @spaceXSmall;

    @media @bpMedium {
        background: none;
        padding: 0;
    }
}

.Compare-figure {
    margin-bottom: @spaceSmall;
}
.compare-image{
    max-width: inherit;
}
.Compare-heading {
    text-transform: uppercase;
    font-size: @textSizeMedium;
    margin-bottom: 0;
}

.Compare-addAnotherProduct {
    border: 1px solid @borderColor;
    padding: @spaceMedium @spaceSmall;
    text-align:center;
}

.Compare-head {
    th {
        border: none;
    }
}

.scrollable {
    position: relative;
    overflow: hidden;

    > div {
        overflow-x: auto;
    }
}

.Compare-header {
    &:first-child {
        th {
            border-right: none;
        }
    }

    h4 {
        margin-bottom: 0;
    }
}

.Compare-different {
    td {
        background: @colorWhite;
    }
}


.Compare-head th {
  width: 20%;
}

.header--fixed {
    height: 35px;
}

.header--fixed__item {
    position: absolute;
    font-family:@fontFamilyHeading;
     h4 {
        font-size: 1.5em;
    }
    @media @bpMedium {
        position: relative;
          font-family:@fontFamilyPrimary;
    }

    h4 {
     font-size:@textSizeLarge;
    }

  
}
