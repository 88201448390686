﻿@import (reference) "../common/variables.less";

/* 
    * Generates spacing classes with a bootstrap inspired naming convention
    * with a base modifier of the theme spacing, i.e. 8px
    * example: pb-1 --> padding-bottom: 8px
    * for both bottom and top, or left + right use pv (padding vertial) or ph (padding horizontally)
*/

@multipliers: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9;
@themeSpacing: 8;

.generate-spacing-classes(@property; @dir; @prefix) {
    .generate-classes(@multiplier) when (@multiplier >= 0) {
        .u-@{prefix}@{multiplier} {
            @{property}-@{dir}: unit(@multiplier * @themeSpacing, px) !important;
        }

        .generate-classes(@multiplier - 1);
    }

    .generate-classes(length(@multipliers));
}

/* Margin and padding classes */
.generate-spacing-classes(margin; top; mt);
.generate-spacing-classes(margin; bottom; mb);
.generate-spacing-classes(margin; left; ml);
.generate-spacing-classes(margin; right; mr);
.generate-spacing-classes(margin; block; mv);
.generate-spacing-classes(margin; inline; mh);
.generate-spacing-classes(padding; top; pt);
.generate-spacing-classes(padding; bottom; pb);
.generate-spacing-classes(padding; left; pl);
.generate-spacing-classes(padding; right; pr);
.generate-spacing-classes(padding; block; pv);
.generate-spacing-classes(padding; inline; ph);
