/*#

Display tooltips

Examples:
js
<span data-module="tooltip" class="Tooltip-text TooltipText-border" title="Dammsug som ett proffs">3-1</span>

<div class="Tooltip Tooltip--arrowE">
    <div class="Tooltip-content">
        The content of the tooltip.
    </div>
    <div class="Tooltip-arrow"></div>
</div>

*/

@import "../../common/variables.less";

@Tooltip-background: #222; 
@Tooltip-color: #fff;
@Tooltip-arrowSize: 6px;

.Tooltip {
    //min-width: 150px;
    max-width: 320px;
    padding: (@Tooltip-arrowSize - 1px);
    position: absolute;
    z-index: 1000;
}

.Tooltip-text{ 
 cursor: help;
}

.TooltipText-border{ 
    border-bottom: @borderDotted @borderColor;
}

.Tooltip-content {
    display: block;
    font-size: @textSizeXSmall;
    color: @Tooltip-color;
    background-color: @Tooltip-background;
    padding: @spaceXSmall;
}

.Tooltip-arrow {
    position: absolute;
    line-height: 0;
    border: @Tooltip-arrowSize solid transparent;
}
    
.Tooltip--arrowN .Tooltip-arrow {
    border-bottom: 0;
    border-top-color: @Tooltip-background;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
}

.Tooltip--arrowS .Tooltip-arrow {
    border-bottom-color: @Tooltip-background;
    border-top: 0;
    left: 50%;
    margin-left: -5px;
    top: 0;
}

.Tooltip--arrowW .Tooltip-arrow {
    border-left-color: @Tooltip-background;
    border-right: 0;
    margin-top: -5px;
    right: 0;
    top: 50%;
}

.Tooltip--arrowE .Tooltip-arrow {
    border-left: 0;
    border-right-color: @Tooltip-background;
    left: 0;
    margin-top: -5px;
    top: 50%;
}


.Tooltip--arrowNW .Tooltip-arrow {
    border-bottom: 0;
    border-left: 0;
    border-top-color: @Tooltip-background;
    bottom: 0;
    left: 5px;
}

.Tooltip--arrowNE .Tooltip-arrow {
    border-bottom: 0;
    border-right: 0;
    border-top-color: @Tooltip-background;
    bottom: 0;
    right: 5px;
}

.Tooltip--arrowSW .Tooltip-arrow {
    border-bottom-color: @Tooltip-background;
    border-bottom-style: solid;
    border-left: 0;
    border-right-color: transparent;
    border-top: 0;
    left: 5px;
    top: 0;
}

.Tooltip--arrowSE .Tooltip-arrow {
    border-bottom-color: @Tooltip-background;
    border-bottom-style: solid;
    border-left-color: transparent;
    border-right: 0;
    border-top: 0;
    right: 5px;
    top: 0;
}