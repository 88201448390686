﻿/*#

Component for product page adjustments on child components.

*/

@import "../../common/variables.less";

.ProductPage {
}

.ProductPage-informationTabs{
    display: none;
    @media @bpMedium{
        display: block;
    }
}
.ProductPage-informationAccordion {
    @media @bpMedium {
        display: none;
    }
}

.ProductPage-part{
    margin-bottom: @spaceMedium;
    padding-bottom: @spaceMedium;
    border-bottom: 1px solid @colorGrey;
}
