/*#

Display figure captions to images and other media.

Examples:

<div>
    <figure class="Figure">
        <img src="assets/images/temp/nansen-office-flowers.jpg" class="Figure-object" />
        <figcaption class="Figure-caption">Flowers in the Nansen Chicago office.</figcaption>
    </figure>    
</div>

*/

@import "../../common/variables.less";

.Figure { 
    margin: 0;
    display: inline-block;
}

.Figure-object { }

.Figure-caption {
    font-size: @textSizeXSmall;
    color: @colorTextLight;
    margin: .5em 0;
    
    &.Figure-caption--right {
        text-align: right;
    }
}

.Figure--right {
    float: right;
    margin-left: @spaceMedium;
}

.Figure--left {
    float: left;
    margin-right: @spaceMedium;
}