﻿@import "../../common/variables.less";

.SeoHeading {
    text-align: center;

    h1 {
 
    }

    p {
        margin-bottom: @spaceXSmall;
    }

    @media @bpMedium {
        margin: 0 auto;
        max-width: 80%;
    }

    @media @bpLarge {
        max-width: 66%;
        /*margin: -2em auto 0 auto;*/
    }
}