/*#

Examples:

<div>
  <div class="Pulse" style="width:100px; height:100px; background: #FF5D21;">
                
    </div>
</div>

*/

@import "../../common/variables.less";

.Pulse {
    -webkit-animation: pulsate 0.9s ease-out;
    -webkit-animation-iteration-count: infinite; /* Chrome, Safari, Opera */
    animation-iteration-count: infinite;
}
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(1, 1);}
    50% {-webkit-transform: scale(1.2, 1.2);}
    100% {-webkit-transform: scale(1, 1);}
}

