﻿.Overlay {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 998;
	background: rgba(0,0,0,.6);
	display: none;

	&.is-active {
		display:block;
	}
}
