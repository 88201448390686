﻿/*#

Styling for lead text in an article.

Examples:

<div style="width:450px; background: #000; padding:20px;">
    Left:
   <div class="SplitBorder">
                <h4 class="u-colorBGblack u-colorTextWhite SplitBorder-text Footer-heading">Kontakta Kundtjänst</h4>
                <span class="SplitBorder-line SplitBorderLine--white SplitBorderLine--h4"></span>
    </div>
 

</div>
 Center:
<div style="width:450px; background: #e6eef5; padding:20px;">
    <div class="SplitBorder SplitBorder--center u-marginBsm">
            <h3 class="u-colorBGlightBlue SplitBorder-text u-marginBz u-textUppercase">Aktuellt just nu</h3>
            <span class="SplitBorder-line SplitBorderLine--h3"></span>
        </div>
</div>

<div style="width:450px; padding:20px;">
    <div class="SplitBorder SplitBorder--center u-marginBsm">
            <h3 class="u-colorBGWhite SplitBorder-text u-marginBz u-textUppercase">Liknande produkter</h3>
            <span class="SplitBorder-line SplitBorderLine--h3"></span>
        </div>
</div>

*/

@import "../../common/variables.less";


.SplitBorder {
    line-height: 0.5;
    text-align: left;
    position: relative;
}

.SplitBorder-text {
    position: relative;
    z-index: 5;
    display: inline-block;
    padding-right: @spaceSmall;
}

.SplitBorder-line {
    display: block;
    position: relative;
    border-top: 4px solid;
    width: 100%;
    z-index: 1;
}

.SplitBorderLine--h3{ top: -12px;}
.SplitBorderLine--h4{ top: -10px;}

.SplitBorderLine--white{
    border-color: @colorWhite;
}

.SplitBorder--center {
    text-align: center;

    .SplitBorder-text {
        padding-right: @spaceSmall;
        padding-left: @spaceSmall;
    }
}
