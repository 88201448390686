/*#

Dispaly how many items there is

Examples:
<div style="max-width:100px;">
      <div class="Circle">
          <span class="Circle-text">1</span>
      </div>
    <br>
       <div class="Circle">
          <span class="Circle-text CircleText--large">1</span>
      </div>
    <br>
     <div class="Circle Circle--dark">
          <span class="Circle-text CircleText--large">1</span>
      </div>
</div>
*/

@import "../../common/variables.less";

.Circle {
    background: @brandColor;
    justify-content: center;
    border-radius: 100%;
    display: flex;
    height: 50px;
    width: 50px;


    @media @bpMedium {
        height: 60px;
        width: 60px;
    }

    &--small {
        width: 32px;
        height: 32px;
    }

    &.ScrollTop {
        border: 2px solid @colorWhite;
    }
}



.Circle-text{
    align-self:center;
    color: @colorTextWhite;
    font-size: 1em;
    font-family: @fontFamilyCondensed;
}

.CircleText--large {
    font-size: 1em;

    @media @bpMedium {
        font-size: 2em;
    }
}