@import "../../common/variables.less";

.FavoriteItemList {
    &__buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: @widthContainerLarge;

        &__button {
            max-width: 300px;

            .Button {
                min-width: 100%;
            }
        }



        .Button--isActive {
            &:hover,
            &:focus {
                cursor: pointer;
                border-color: @colorBlueRich;
                background-color: @colorBlueRich;
                color: @colorWhite;
            }
        }
    }
}
