/*#

Dispaly how many items there is

Examples:
<div style="width:50px; position:relative; height:60px;">
    <span class="Quantity">38</span>
</div>
*/

@import "../../common/variables.less";

.Topfooter{
    background: @colorBlueTint;
    border-top:1px solid @colorBlueTint;
    padding-bottom: @spaceMedium;
}

.Topfooter--border{
     border-top:1px solid #e3e3e3;
}