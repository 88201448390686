/*#

Component for dividing content into different tab panels on page.
Uses the "tabs" js-module.

<div class="Tabs" data-module="tabs">
    <ul class="Tabs-list">
        <li class="Tabs-item" data-name="tab-one"><a href="#" class="Tabs-link">Tab 1</a></li>
        <li class="Tabs-item" data-name="tab-two"><a href="#" class="Tabs-link">Tab 2</a></li>
    </ul>

    <div class="Tabs-panel">
        Panel 1
    </div>

    <div class="Tabs-panel">
        Panel 2
    </div>
</div>

*/

@import "../../common/variables.less";

.Tabs {
    margin-bottom: @spaceMedium;
}

.Tabs-list {
    margin: 0;
    padding-left: 0!important;
    width: 100%;
    text-align: left;
    margin-left:0;
}

.Tabs-item {
    vertical-align: bottom;
    display: inline-block;
    padding: 0;
    margin: 0;
    padding-right: @spaceMedium;
    cursor: pointer;

    &:before {
        content: none;
    }

    &.is-active .Tabs-link {
        border-bottom-color: @brandColor;
    }
}

.Tabs-link {
    display: block;
    text-decoration: none;
    padding-bottom: .2em;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    border-bottom: 4px solid @colorBlueTint;
    color: @colorText;
    font-family: @fontFamilyHeading;
   

    &:hover {
        color: @colorText;
        text-decoration: none;
        border-bottom-color: @brandColor;
    }
}

.Tabs-panel {
    display: none;

    &.is-active {
        display: block;
    }
}

.Tabs--equalWidth {
    .Tabs-list {
        display: table;
        table-layout: fixed;
    }
    
    .Tabs-item {
        display: table-cell;
    }
}


//

.Tabs-grey {
    background: @colorMidGrey;

    .Tabs-list {
        .Page-container;
        display: flex;
        justify-content: space-between;

        .Tabs-item {
            padding: 8px @spaceMedium;
            font-family: @fontFamilyCondensed;
            width: 100%;
            text-align: center;

            &.is-active {
                background: @colorGrey;
            }
        }
    }
}

.Tabs-greySection {
    background: @colorGrey;
    padding-top: @spaceLarge;
    border: none;
}