@import '../../common/variables.less';

.Kitchen-booking-container {
  display: flex;
  flex-direction: column;

  .Flex-container-flex-end {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  .Flex-column {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .Flex-column-reverse {
    flex-direction: row;

    @media @bpXSmall {
      flex-direction: column-reverse;
    }

    @media @bpSmall {
      flex-direction: column-reverse;
    }

    @media @bpMedium {
      flex-direction: row;
    }

    @media @bpLarge {
      flex-direction: row;
    }

    @media @bpXLarge {
      flex-direction: row;
    }
  }

  .Flex-container-center {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    justify-items: center;
  }

  .Button-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    
    @media @bpMedium {
      width: 50%;
      padding-left: 15px;
    }
    
  }

  .Store-card {
    color: @colorBlack;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: @spaceSmall;
    background-color: @colorGrey;
    text-align:left;
  }

  .Store-card:hover {
    background-color: @colorBlueRich;
    color: @colorWhite;
  }

  .Store-card h4{
    margin-bottom:0;
  }

  .Store-card:hover h4 {
    color: @colorWhite;
  }

  .Show-more-button {
    width: max-content;
  }

  .Map {
    width: 100%;
    height: 50vh;
    grid-column-start: 3;
  }

  .Content-wrapper {
    margin: @spaceXLarge;
  }

  .Info-window-template {
    padding: @spaceSmall;
  }

  .Info-window-template h4 {
    margin-bottom: @spaceXSmall;
  }

  .Info-window-content {
    margin-bottom: @spaceXSmall;
  }

  .Choose-store-button {
    height: min-content !important;
  }

  .Booking-calendar-container {
    overflow-x: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .Booking-calendar {
      width: @widthContainerLarge;
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin-bottom: @spaceXLarge;
      margin-top: @spaceMedium;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .Date-column {
    border: 0.5px solid @borderColor;
    min-width: @widthContainerLarge / 7;
    text-align: center;
  }

  .Date {
    padding: @spaceSmall;
    background-color: @colorGrey;
    text-transform: uppercase;
    margin: 0;
    color: @colorBlueRich;
    border-bottom: 1px solid @borderColor;
  }

  .Time-slots {
    list-style: none;
    padding: 0;
  }

  .Time-slot {
    border: 0.5px solid @borderColor;
    text-align: center;
    margin: @spaceXSmall / 2;
    padding: @spaceXSmall;
    width: 90%;
  }

  .Time-slot:disabled {
    color: @colorTextLight;
    border: 0.5px solid @borderColor;
    background-color: white;
  }

  .Time-slot:disabled:hover {
    background-color: white;
  }

  .Time-slot:hover:enabled {
    border: 0.5px solid @colorBlueRich;
    background-color: @colorBlueRich;
    color: @colorWhite;
  }

  .Week {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Round-button {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 0;
    margin: @spaceXSmall;
  }

  .Round-button:hover:enabled {
    background-color: @colorLightOrange;
  }

  .Arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Booking-confirmation {
    display: block;
    border: 0.5px solid @borderColor;
    color: @colorBlack;
    width: 100%;
    height: 100%;
  }

  .Booking-confirmation h3 {
    padding: @spaceSmall;
    background-color: @colorGrey;
    margin: 0;
    color: @colorBlueRich;
    border-bottom: 1px solid @borderColor;
  }

  .Confirm-meeting-form {
    justify-self: left;
  }

  .Selected {
    border: 0.5px solid @colorBlueRich;
    background-color: @colorBlueRich;
    color: @colorWhite;
  }

  .Selected h4 {
    color: @colorWhite;
  }

  .Disabled {
    color: @colorTextLight;
    padding: @spaceXSmall;
  }
}
