﻿/*#

Component for the footer section.

Examples:

<div style="">
 <footer class="Footer inverted">
<div class="Page-container">
    <div class="Grid Grid--gutterAxlg">
        <div class="Grid-cell u-lg-size2of4">
            <div class="SplitBorder">
                <h4 class="u-colorBGblack SplitBorder-text Footer-heading">Kontakta Kundtjänst</h4>
                <span class="SplitBorder-line"></span>
            </div>


            <p class="Property u-marginTsm">
                <span class="Property-key">Öppettider:</span>
                <span class="Property-value">måndag - torsdag 09:00 - 17:00, fredag 10:00 - 16:00<br />Julafton Stängt</span>
            </p>


            <p class="Property  u-marginBxlg">
                <span class="Property-key">Support E-handel:</span>
                <span class="Property-value">0770 77 01 03<br /><a href="mailto:kundservice@electroluxhome.com">kundservice@electroluxhome.com</a></span>
            </p>


            <div class="SplitBorder">
                <h4 class="u-colorBGblack SplitBorder-text Footer-heading">Följ oss</h4>
                <span class="SplitBorder-line"></span>
            </div>
            <p class="u-marginTsm">Prenumerera på vårat nyhetsbrev och ta del av unika erbjudanden, köksinspiration, tips och guider.</p>
            <div class="oldBrowser"><span>Fyll i din epost-adress</span></div>
            <div class="Grid Grid--gutterA">
                <div class="Grid-cell u-sm-size2of3">
                    <input class="Input Input--small newsLetter" type="text" placeholder="Fyll i din epost-adress" />
                </div>
                <div class="Grid-cell u-sm-size1of3">
                    <button class="Button Button--small Button--full newsLetter">Prenumerera</button>
                </div>
            </div>

            <div class="Social">
                 <a class="Social-link" href="#"><i class="Social-icon Icon Icon--facebook"></i> <span>Facebook</span></a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--instagram"></i><span>Instagram</span> </a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--pinterest"></i><span>Pinerest</span></a>
                <a class="Social-link" href="#"><i class="Social-icon Icon Icon--youtube"></i><span> Youtube</span></a>
            </div>

        </div>

        <div class="Grid-cell u-xsm-size1of2 u-lg-size1of4">
            <div class="SplitBorder">
                <h4 class="u-colorBGblack SplitBorder-text Footer-heading">Om oss</h4>
                <span class="SplitBorder-line"></span>
            </div>
            <ul class="List List-vertical">
                <li class="List-item"><a href="#">Lediga jobb</a></li>
                <li class="List-item"><a href="#">Om Electrolux home</a></li>
                <li class="List-item"><a href="#">Om Electroluxhome.se</a></li>
                <li class="List-item"><a href="#">Hjälp hela vägen</a></li>
                <li class="List-item"><a href="#">Butiker och öppettider</a></li>
                <li class="List-item"><a href="#">Pressinfo</a></li>
                <li class="List-item"><a href="#">Våra reklamfilmer</a></li>
            </ul>
        </div>

        <div class="Grid-cell u-xsm-size1of2  u-lg-size1of4">
            <div class="SplitBorder">
                <h4 class="u-colorBGblack SplitBorder-text Footer-heading">Hjälp</h4>
                <span class="SplitBorder-line"></span>
            </div>

            <ul class="List List-vertical">
                <li class="List-item"><a href="#">Kundtjänst</a></li>
                <li class="List-item"><a href="#">Kontakta oss</a></li>
                <li class="List-item"><a href="#">Allmänna villkor</a></li>
                <li class="List-item"><a href="#">Köpvillkor</a></li>
                <li class="List-item"><a href="#">Betalning</a></li>
                <li class="List-item"><a href="#">Leverans</a></li>
                <li class="List-item"><a href="#">Service och support</a></li>
            </ul>

        </div>
    </div>

  
  

</div>


<div class="Footer-payment">
    <div class="Page-container">
        <div class="FooterPayment-icons">
            <img src="/Assets/Images/Payment/partners-handelsbanken.png" />
            <img src="/Assets/Images/Payment/partners-mastercard.png" />
            <img src="/Assets/Images/Payment/partners-nordea.png" />
            <img src="/Assets/Images/Payment/partners-payex.png" />
            <img src="/Assets/Images/Payment/partners-schenker.png" />
            <img src="/Assets/Images/Payment/partners-seb.png" />
            <img src="/Assets/Images/Payment/partners-swedbank.png" />
            <img src="/Assets/Images/Payment/partners-verisign.png" />
            <img src="/Assets/Images/Payment/partners-visa.png" />
            <img src="/Assets/Images/Payment/trygg-ehandel.png" />
        </div>
     </div>
</div>  
    </footer>
</div>

*/

@import "../../common/variables.less";

.Footer {
	padding: @spaceXLarge 0 0;
	background: @colorGrey;
}

.Footer-heading {
	font-family: @fontFamilyHeading;
	padding-bottom: @spaceXXSmall;
	text-transform: uppercase;
	border-bottom: 1px solid @colorBlack;
}


.Footer-payment {
	background: @colorGrey;
	padding: @spaceSmall 0;
	margin-top: 1em;
}

.newsLetter-heading {
	color: @colorSilkLight;
}

.FooterPayment-icons {
	display: -webkit-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
	justify-content: space-between;
	flex-wrap: wrap;

	img {
		//padding:1em;
		margin: 0.2em 0.4em;
		align-self: center;
		width: 8%;

		@media @bpLarge {
			width: auto;
			padding: 0;
		}

		&.estore {
			width: 15%;
			padding: 0 !important;

			@media @bpMedium {
				display: flex;
				width: auto;
			}
		}
	}
}

.estore {
	width: 15%;
	padding: 0 !important;

	@media @bpMedium {
		display: flex;
		width: auto;
	}
}


.is-no-flexbox {
	.FooterPayment-icons {
		img {
			padding: 0 @spaceXSmall;
		}
	}
}
