﻿.ColorTeaser {
	overflow: hidden;
	width: 100%;
	position: relative;
	display: block;

	.ColorTeaser-heading {
		word-break: break-word;
		hyphens: auto;
	}

	&.ColorTeaser--contentLeft {
		.Grid {
			flex-direction: row-reverse;
		}
	}

	.ColorTeaser-subHeading {
		.Heading--h3Size;
	}
}

a {
	&:hover {
		.Button {
			background: @colorOrange;
			border-color: @colorOrange;
			text-decoration: none;
			color: @colorWhite;
		}

		.Heading {
			color: currentColor;
		}
	}
}


.ColorTeaser--xSmall {
	.ColorTeaser-heading {
		.Heading--h2Size;
		margin-bottom: 12px;
	}

	.ColorTeaser-subHeading {
		.Heading--h3Size;
	}

	.ColorTeaser-text {
		@media @bpLarge {
			font-size: @textSmall;
		}
	}
}

//
.ColorTeaser--small {
	.ColorTeaser-heading {
		.Heading--h2Size;

		@media @bpLarge {
			.Heading--h1;
		}
	}

	.ColorTeaser-subHeading {
		.Heading--h3Size;
	}

	.ColorTeaser-text {
		@media @bpLarge {
			font-size: @textLarge;
		}
	}
}
//


.ColorTeaser--small, .ColorTeaser--xSmall {
	display: flex;
	flex-direction: column;

	.ColorTeaser-image {
		@media @bpLarge {
			aspect-ratio: 393/404;
		}
	}

	.ColorTeaser-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}
}

//
.ColorTeaser--medium {
	.Grid {
		height: 100%;
	}

	.ColorTeaser-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		padding: @spaceSmall;

		@media @bpLarge {
			padding: @spaceSmall @spaceLarge;
		}

		.ColorTeaser-heading {
			font-size: 14px;

			@media @bpMedium {
				font-size: 22px;
			}
		}

		.ColorTeaser--buttonWrapper {
			display: none;

			@media @bpMedium {
				display: block;
			}

			@media @bpLarge {
				display: none;
			}

			@media @bpXLarge {
				display: block;
			}
		}
	}

	.ColorTeaser-text {
		font-size: @textSmall;
		line-height: 15px;

		@media @bpMedium {
			line-height: 1.55;
		}
	}
}
//
.ColorTeaser--large {
	.ColorTeaser-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		padding: @spaceLarge;

		@media @bpLarge {
			padding: @spaceXLarge;
		}

		.Heading {
			font-size: @textLarge;

			@media @bpLarge {
				.Heading--h1Size;
			}
		}

		.ColorTeaser-text {
			font-size: @textXSmall;
			line-height: 15px;

			@media @bpLarge {
				font-size: @textLarge;
				line-height: 1.55;
			}
		}

		.ColorTeaser--buttonWrapper {
			display: none;

			@media @bpMedium {
				display: block;
			}
		}

		.ColorTeaser-subHeading {
			@media @bpLarge {
				.Heading--h3Size;
			}
		}
	}
}


//
.ColorTeaser--large, .ColorTeaser--medium {


	.ColorTeaser-imageWrapper {
		overflow: hidden;
		height: 100%;
	}

	.ColorTeaser-image {
		height: 100%;
	}
}

//

.ColorTeaser-content {
	padding: @spaceLarge;
}

.ColorTeaser-imageWrapper {
	// overflow: hidden;
}

.ColorTeaser-image {
	aspect-ratio: 1;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.ColorTeaser-price {
	margin-top: 0;

	span {
		display: block;
	}
}

.ColorTeaser-priceRecommended {
	font-size: @textXSmall;
	margin-top: 0;
	text-decoration: line-through;

	@media @bpLarge {
		font-size: @textSmall;
	}
}

.ColorTeaser-priceCampaign {
	.Heading--h2Size;
	color: @colorOrange !important;
	margin-bottom: 0;
}

.Package-priceCampaign {
	font-size: 22px;

	@media @bpLarge {
		font-size: 45px
	}
}


.ColorTeaser--buttonWrapper {
	margin-top: 12px;

	.Button {
		height: auto;
		min-height: 45px;
		width: 100%;

		@media @bpMedium {
			width: auto;
		}
	}
}

// Slider

.Slider-lybeck {
	background: @colorGrey;
	margin-bottom: @spaceMedium;

	.ColorTeaser-slider {
		max-width: 1220px;
		margin: 0 auto;
	}
}

.ColorTeaser-slider {
	padding: @spaceMedium 0;
	width: 100%;

	.ColorTeaser-sliderHeading {
		font-size: 22px;
		text-align: center;
		margin-bottom: 32px;
		color: @colorBlueRich;
	}

	.Slider-item {
		width: 100%;
	}

	.ColorTeaser-sliderButton {
		text-align: center;
		margin-top: @spaceLarge;
	}

	.ColorTeaser {
		height: 100%;
		.ColorTeaser--buttonWrapper {
			margin:0 auto;
			max-width:fit-content;
		}
	}



	.owl-buttons {
		display: none;
	}

	.owl-carousel .owl-item {
		display: block;
		align-items: stretch;
	}



	@media @bpMedium {
		padding: @spaceLarge 0;

		.ColorTeaser-sliderHeading {
			font-size: 40px;
		}

		.owl-pagination {
			display: none;
		}

		.owl-buttons {
			display: block;
		}

		.owl-wrapper {
			display: flex !important;
			align-items: stretch;
		}

		.Slider-item {
			margin: 0 10px;
		}

		.owl-carousel .owl-item {
			display: flex;
			align-items: stretch;
		}
	}
}
