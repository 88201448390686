/*#

Examples:

<div>

</div>

*/

@import "../../common/variables.less";

.Input--small{
    padding: .45em @spaceXSmall;
}

.Input--medium{
     padding: @spaceSmall @spaceXSmall;
}
