/*#

Dispaly how many items there is

Examples:
<div style="width:50px; position:relative; height:60px;">
    <span class="Quantity">38</span>
</div>
*/

@import "../../common/variables.less";

.Quantity{
        position:absolute;
        top: 2px;
        right: -1px;
        width:18px;
        height:18px;
        border-radius:100%;
        font-size: 12px;
        color: @colorWhite;
        background: @colorOrange; 
        display:flex;
        justify-content: center;
        align-items: center;
        font-family: @fontFamilyPrimary;
        z-index:10;
        transition: all .2s ease-in-out;
    @media @bpLarge{
        width:21px;
        height:21px;
        right: -12px;
        font-size: 14px;
        line-height:1.5em;
    } 
}

.Sticky-element.is-sticky {
    .ProductNav-header {
        .Quantity {
            background: @colorSoftBlack;
            color: @colorWhite;
        }
    }
}