.KitchenFeatureBlock {

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 0;
        margin-bottom: 38px;

        @media @bpMedium {
            padding: @spaceXXXLarge;
            margin-bottom: 0;
        }

        .KitchenBlockLink;
    }
}