﻿/*#
Ordinary price
<div style="width:390px;">
   <div class="Pricebox Pricebox--right">
            <ul class="List List--horizontal Pricebox-list u-marginAz">
                <li class="List-item Pricebox-price u-fontFamilyCondensed">2 490:-</li>
                <li class="List-item"><button class="Button">Köp</button></li>
            </ul>
        </div>

    Sale:

      <div class="Pricebox Pricebox--right">
        <ul class="List List--horizontal Pricebox-list u-marginAz">
            <li class="List-item u-fontFamilyCondensed"><span class="Pricebox-price Pricebox-recPrice">9 995:-</span> <span class="Pricebox-price PriceboxPrice--sale">7 490:-</span> </li>
            <li class="List-item"><button class="Button">Köp</button></li>
        </ul>

    </div>

    </div>
*/

@import "../../common/variables.less";
@priceBoxHeading: 1.625rem;

.Pricebox {
	/*border-top: @borderDotted @borderColor;
	border-bottom: @borderDotted @borderColor;*/
	padding: @spaceSmall 0;

	.List--horizontal {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		padding-left: 0;


		.List-item {
			padding: 0 @spaceMedium 0 0;
			-webkit-align-self: center;
			-ms-flex-item-align: center;
			align-self: center;
		}
	}

	&--empty {
		border-bottom: none;
		padding-bottom: 0;
	}

	&--noborder {
		border: none;
		padding: 0;

		.Energy {
			flex-direction: column;
			float: right;
		}

		.Energy-label {
			margin-left: 0 !important;
			margin-top: 2px;
		}

		.Pricebox-priceDesc {
			display: block;
			margin-top: 5px;
		}
	}

	&&--right {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		text-align: right;
	}
}

.Pricebox-list {
	font-family: @fontFamilyCondensed;
	margin: 0;

	.List-item {
		&:last-child {
			padding-right: 0;
			flex-shrink: 0;

			.Button {
				font-size: @textSizeSmall;
			}
		}
	}
}

.Pricebox-price {
	display: block;
	font-size: @priceBoxHeading;
	line-height: 1;
	white-space: nowrap;

	&.PriceboxPrice--sale {
		color: @colorOrange;
	}
}

.Pricebox-partPaymentPrice {
    font-family: @fontFamilyPrimary !important;
    font-size: @textSizeXSmall;
    display: block;
}

.Pricebox-recPrice {
	display: inline-block;
	font-size: @textSizeMedium;
	white-space: nowrap;
}

.Pricebox-priceDesc {
	color: @colorDimGrey;
	display: inline-block;
	font-family: @fontFamilyPrimary;
	font-size: @textSizeXSmall;
	white-space: nowrap;
}

.PriceboxList--small {
	.Pricebox-price {
		font-size: 1.5em;
	}

	.Pricebox-recPrice {
		font-size: .8em;
	}
}

.Pricebox-energyInfo {
	clear: both;
	display: inline-block;
	float: left;

	@media @bpXSmall {
		clear: none;

		+ & + & {
			clear: left;
		}
	}

	@media @bpMedium {
		clear: both;
	}
}

.Pricebox-energyFolder {
	float: left;
	clear: both;

	@media @bpLarge {
		clear: none;
		margin: @spaceSmall 0 0 0;
	}
}

@media print {
	.Pricebox-price {
		font-size: 16pt !important;
	}

	.Pricebox-recPrice {
		font-size: 10pt !important;
	}
}

.Pricebox-hsb {
    .Hsb-member {
        font-family: @fontFamilyPrimary;
        font-size: 10px;
        background: @colorOrange;
        padding: .2rem .4rem;
        color: @colorWhite;
        position: relative; 
        top: -4px;
    }

    .Hsb-price {
        display: inline-block;
    }

    .Hsb-save {
        font-family: @fontFamilyPrimary;
        font-size: 12px;
        display: block;
        color: @colorOrange;
        margin-top: .6rem;

        span {
            font-family: @fontFamilyCondensed;
        }
    }
}

.Product-info {
	.Hsb-recPrice {
		display: block;
	}
}