﻿@import "../../common/variables";

/*#

Listing content in either horizontal or vertical direction, while supporting all gutter sizes. It is often used in combination with a ``UL/OL`` tag but it is not required.

Examples:
 
<div>
    <b>List:</b>
    <ul class="List">
        <li class="List-item">Item 1</li>
        <li class="List-item">Item 2</li>
    </ul>
    
    <b>List--gutterVxlg:</b>
    <ul class="List List--gutterVxlg">
        <li class="List-item">Item 1</li>
        <li class="List-item">Item 2</li>
    </ul>
    
    <b>List-horizontal & List--center & List--bordered:</b>    
    <ul class="List List--bordered List--horizontal List--center">
        <li class="List-item"><a href="#">Item 1</a></li>
        <li class="List-item"><a href="#">Item 2</a></li>
        <li class="List-item"><a href="#">Item 3</a></li>
    </ul>
</div>
*/

@import "../../common/variables.less";

@List-gutterVerticalDefault: @spaceXSmall;
@List-gutterHorizontalDefault: @spaceMedium;
@List-borderColor: @colorBlueTint;
@List-stripedColor: lighten(@colorBlueTint, 10%);
@ListBullet-space: 0.5em;
@ListBullet-padding: 0.05em;

.List {
    padding-left: 0;
    list-style: none;
}

.List-item {
    padding-top: @List-gutterVerticalDefault/2;
    padding-bottom: @List-gutterVerticalDefault/2;

    .List &:first-child {
        padding-top: 0;
    }

    .List &:last-child {
        padding-bottom: 0;
    }
}

.List--center {
    text-align: center;
}

.List--right {
    text-align: right;
}

.List--bordered {
    & > .List-item {
        border-bottom: 1px solid @List-borderColor;

        &:last-child {
            border-bottom: none;
        }
    }
}

.List-bullet {
	& > .List-item {
		padding-top: @ListBullet-padding;
		padding-bottom: @ListBullet-padding;

		&:before {
			content: "";
			width: 10px;
			height: 10px;
			background-color: @colorMidGrey;
			border: 2px solid darken(@colorMidGrey, 5%);
			border-radius: 100%;
			display: inline-block;
			margin-right: @ListBullet-space;
		}
	}

	&.ListBullet-multirow {
		margin-left: 20px;

		> .List-item:before {
			margin-right: 0em;
			position: relative;
			left: -20px;
		}

		span {
			margin-left: -10px;
		}
	}

	&.List-bullet--small {
		& > .List-item {
			&:before {
				width: 5px;
				height: 5px;
				background-color: @colorSoftBlack;
				border: none;
				top: -3px;
				position: relative;
			}
		}
	}

	&.List-bullet--xsmall {
		& > .List-item {
			&:before {
				width: 3px;
				height: 3px;
				background-color: @colorSoftBlack;
				border: none;
				top: -3px;
				position: relative;
			}
		}
	}
}


    .List--horizontal {
        margin-left: -@List-gutterHorizontalDefault/2;
        margin-right: -@List-gutterHorizontalDefault/2;

        .List-item {
            display: inline-block;
            margin-right: -.25em; // remove inline-block space between elements. this may be inconsistent when changing font-family
            padding-right: @List-gutterHorizontalDefault/2;
            padding-left: @List-gutterHorizontalDefault/2;

            &:last-child {
                padding-right: 0;
            }
        }

        &.List--bordered {
            & > .List-item {
                border-right: 1px solid @List-borderColor;
                border-bottom: none;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    .List--equalSpaceBxsm {
        li {
            &:last-child {
                padding-bottom: @List-gutterVerticalDefault/2 !important;
            }
        }
    }
    /* 
    Vertical gutters (top, bottom) 
*/

    .List--gutterVxxs > .List-item {
        padding-top: 0.1em;
        padding-bottom: 0.1em;
    }

    .List--gutterVxsm > .List-item {
        padding-top: @spaceXSmall/2;
        padding-bottom: @spaceXSmall/2;
    }

    .List--gutterVsm > .List-item {
        padding-top: @spaceSmall/2;
        padding-bottom: @spaceSmall/2;
    }

    .List--gutterVmd > .List-item {
        padding-top: @spaceMedium/2;
        padding-bottom: @spaceMedium/2;
    }

    .List--gutterVlg > .List-item {
        padding-top: @spaceLarge/2;
        padding-bottom: @spaceLarge/2;
    }

    .List--gutterVxlg > .List-item {
        padding-top: @spaceXLarge/2;
        padding-bottom: @spaceXLarge/2;
    }
    /* 
    Horizontal gutters (top, bottom) 
*/
    .List--gutterHxsm {
        margin-right: -@spaceXSmall/2;
        margin-left: -@spaceXSmall/2;

        & > .List-item {
            padding-right: @spaceXSmall/2;
            padding-left: @spaceXSmall/2;
        }
    }

    .List--gutterHsm > .List-item {
        margin-right: -@spaceSmall/2;
        margin-left: -@spaceSmall/2;

        & > .List-item {
            padding-right: @spaceSmall/2;
            padding-left: @spaceSmall/2;
        }
    }

    .List--gutterHmd {
        margin-right: -@spaceMedium/2;
        margin-left: -@spaceMedium/2;

        & > .List-item {
            padding-right: @spaceMedium/2;
            padding-left: @spaceMedium/2;
        }
    }

    .List--gutterHlg {
        margin-right: -@spaceLarge/2;
        margin-left: -@spaceLarge/2;

        & > .List-item {
            padding-right: @spaceLarge/2;
            padding-left: @spaceLarge/2;
        }
    }

    .List--gutterHxlg {
        margin-right: -@spaceXLarge/2;
        margin-left: -@spaceXLarge/2;

        & > .List-item {
            padding-right: @spaceXLarge/2;
            padding-left: @spaceXLarge/2;
        }
    }
