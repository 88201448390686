/*#
Component for showing a modal window on page.
Can be used in conjunction with the "modal" js-module.

<div>
    <p><a href="#" class="Button" data-module="modal" data-modal="contentElement: '.js-demoModal'">Open modal</a></p>
    
    <section class="Modal js-demoModal">
        <div class="Modal-dialog">
            <h3>Modal header</h3>
            <a class="Modal-close js-close" href="#">&times;</a>
            
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean maximus eget ipsum id mattis. Aliquam nec erat sed justo ullamcorper congue eget id orci. Phasellus eleifend rhoncus lacinia.</p>
            
            <div class="Modal-footer">
                <a href="#" class="u-linkText u-marginRmd js-close">Cancel</a>
                <a href="#" class="Button Button--wide">Send</a>
            </div>
        </div>
    </section>
</div>
*/

@import "../../common/variables.less";

@Modal-width: 700px;
@Modal-space: @spaceMedium;
@Modal-footerBackground: lighten(@colorBlueTint, 10%);

.Modal {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 1em;
    z-index: 998;

    &:after {
        content: "";
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        backface-visibility: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 998;
    }

    &.is-active {
        display: block;

        .Modal-dialog {
            -moz-animation: slideIn .25s ease-out;
            -webkit-animation: slideIn .25s ease-out;
            animation: slideIn .25s ease-out;
        }
    }

    &&--dynamic {
        overflow-x: hidden;
        overflow-y: auto;

        .Modal-dialog {
            align-items: center;
            background: transparent;
            display: flex;
            left: auto;
            margin: 2em auto;
            max-height: none;
            min-height: ~"calc(100% - 2em * 2)";
            overflow: auto;
            padding: 0;
            position: relative;
            right: auto;
            top: auto;
            transform: none;
            width: 95%;

            @media @bpLarge {
                width: 100%;
            }
        }

        .Modal-content {
            background: @colorWhite;
            padding: @spaceMedium;
            position: relative;
        }

        .Modal-close {
            left: auto;
            position: absolute;
	        right: 10px;
            top: 10px;
            transform: none;
            
            @media @bpLarge {
            }
        }
    }
}

.Modal-dialog {
    position: fixed;
    top: 15%;
    right: 0;
    left: 0;
    background: #fff;
    margin: auto;
    z-index: 999;
    width: 98%;
    max-width: @Modal-width;
    max-height: 70%;
    padding: @Modal-space;
    overflow: auto;

    @media @bpMedium {
        max-height: 60%;
        top: 20%;
        width: 100%;
    }
}


.Modal-footer {
	margin: @Modal-space -@Modal-space -@Modal-space;
	padding: @Modal-space;
	text-align: right;
	background: @Modal-footerBackground;
	align-items: center;
}

.Modal-close {
    background: @colorMidGrey;
    border-radius: 50%;
    color: @colorSteel;
    position: absolute;
    right: 20px;
    text-decoration: none;
    top: 5px;
    transition: ease-in-out 0.4s;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;

    &:hover {
        background: @colorSteel;
        color: @colorWhite;
        text-decoration: none;
    }
    // fixed, based on Modal-dialog position and max-size
    left: 91vw;
    position: fixed;
    right: auto;
    top: 15%;
    transform: translateY(5px);
    z-index: 1;

    @media @bpMedium {
        left: auto;
        top: 20%;
        transform: translate(635px, 5px);
    }

    span {
        top: -1px;
        display: block;
        font-size: 1.6em;
        position: relative;
        line-height: 1.6em;
    }
}

.Modal--noPadding .Modal-dialog {
	padding:0;
}

.Modal-content {
	background-color: @colorSilkLight;
	display: inline-block;
	padding: @Modal-space @Modal-space 0;

	.Modal-close {
		
	}

	.Modal-contentImage {
		margin: 0 auto;

		@media @bpSmall {
			padding-top: @Modal-space;
			padding-left: @Modal-space;
			float: right;
		}
	}
}

/*Had to add modal no margin on top*/
.Modal-footer--noMargin {
    margin: 0 -@Modal-space -@Modal-space;
}

    /*Had to add no padding coz the one above wasn't working*/
.Modal--noPadding.Modal-dialog {
        padding: 0;
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: translateY(-100%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}

@-moz-keyframes slideIn {
    0% {
        -moz-transform: translateY(-100%);
    }

    100% {
        -moz-transform: translateY(0%);
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}


.Modal-ar-content {
    background: @colorBlueRich;
    padding: @Modal-space;
    color: #fff;
    height: 100%;
    border: 2px solid #041e50;

    ul {
        padding: 0;
        list-style: none;
    }
    .Heading{
        color: @colorWhite;
    }
}

.Modal-ar-qr { 
    text-align: center;
    padding: 1rem;
    height: 100%;
    border: 2px solid #041e50;
}
