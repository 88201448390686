@import "../../common/variables.less";

.inStore {
    /*&::-webkit-scrollbar {
        width: 0 !important
    }*/
    .js-more-words {
        display: none;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }

    &::-webkit-scrollbar-track {
        background: @colorSteel;
    }

    &::-webkit-scrollbar-thumb {
        background: #7b8a9c;
    }

    user-select: none;
    -webkit-user-select: none;

    .Page-main {
        margin-top: @spaceLarge;
    }

    .Card {
        transition: ease-in-out 0.2s;

        a {
            -webkit-tap-highlight-color: rgba(0,0,0,0);
        }

        &:active {
        }
    }

    .cardClickshadow {
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.35);
    }

    .ajaxLoader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.75);

        img, .Loader {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0 0 0 -58px;
        }
    }

    .Breadcrumb-itemLink {
        color: #848588;

        &:hover {
            color: #848588;
        }
    }

    .Breadcrumb-linkSign {
        color: #848588;
    }

    .lb-data {
        .lb-details {
            width: 100%;
        }

        .lb-close {
            width: auto;
            height: auto;
            text-align: right;
            opacity: 1;

            &:after {
                top: 0;
                font-size: 2rem;
                padding: 1rem;
                background: @colorBlack;
            }
        }
    }

    a.lb-next, a.lb-prev {
        opacity: 1;
    }

    .inStore-back {
        transition: ease-in-out 0.2s;
        position: fixed;
        z-index: 999;
        top: 7px;
        left: 10%;

        a {
            transition: ease-in-out 0.2s;
            display: block;
            font-size: @textSizeXLarge;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:before {
                font-family: HomeIcons;
                content: "\e920";
                margin-right: @spaceXXSmall;
                font-size: @textSizeXLarge;
            }
        }
    }

    .inStore-back.compare-back {
        top: 15px;

        a {
            color: white;
        }
    }

    &.Header-is-visible {
        .inStore-back {
            top: 15px;

            a {
                color: white;
            }
        }
    }

    .Gallery {
        margin-bottom: 6rem !important;
    }

    .ProductNav-List {
        .PageNav-item.is-active {
            .PageNav-itemLink {
                border-color: #fff;
            }
        }
    }

    .PageNav {
        display: inline-block;
    }

    .Instore-search {
        display: inline;

        .Icon--search {
            color: @colorWhite;
            border: none;
            margin-top: 0;
            font-size: 2rem;
            height: 0;
            padding: 0;
        }
    }

    .SearchMenu-startSearchText {
        p {
            margin-bottom: 0;
            padding: 3rem 0;
            font-size: 2rem;
        }
    }

    .Search-close, input.Search-input {
        font-size: 2rem;
    }

    input.Search-input{
        padding: .5rem;
    }
}


    