/*#

Employee

Examples:
 <div class="Employee">
            <div class="Media Media--top">
                <div class="Media-side">
                    <img class="Employee-image" src="https://images.cdn.yle.fi/image/upload/w_1000,h_1000,c_fill,g_center/v1556877474/13-1-3984445-1542018287632.jpg" />
                </div>
                <div class="Media-body">
                    <ul class="List List--gutterVxxs u-marginVz">
                        <li class="List-item"><h4 class="u-marginBz u-lineHeightsm ">Jana Parksson</h4></li>
                        <li class="List-item u-marginBxsm">Sakexpert</li>
                        <li class="List-item"><i class="Icon Icon--contact"></i> 0773882334</li>
                        <li class="List-item"><i class="Icon Icon--mail"></i> <a class="u-wordBreak" href="mailto:someone@example.com" target="_top">lars.mask@electrolux.se</a></li>
                    </ul>
                </div>
            </div>
        </div>
*/

@import "../../common/variables.less";


.Employee-image {
    width: 100%;
    max-height: none !important;
    aspect-ratio: 1;

    @media @bpMedium {
        width: 290px;
    }
}
