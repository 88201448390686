﻿@import "../../common/variables.less";

.StatusBar {
    background-color: @colorOrange;
    text-align: center;
    padding: @spaceSmall;
    color: @colorWhite;

    p {
        margin: 0;

        a {
            color: @colorWhite;
        }

        a:hover {
            border-color: @colorDarkGrey;
        }
    }

    &--info {
        background-color: @colorBlueLight;
    }

    &--warning {
        background-color: @colorDarkRed;
    }

    &--ok {
        background-color: @colorGreen;
    }

    &--clean {
        background-color: @colorBlack;
    }

    &--bold {
        p {
            font-family: @fontFamilyCondensed;
        }
    }
}
