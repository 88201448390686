﻿/*#
Benefit

<div style="width:400px; position:relative;">
  
<div class="Benefit">
             
    <div class="Benefit-item">
        <img class="Benefit-image" src="/Assets/Images/Icons/delivery.png" />
        <p class="Benefit-text"><span>Alltid fri</span> frakt online</p>
    </div>

    <div class="Benefit-item">
        <img class="Benefit-image" src="/Assets/Images/Icons/swan.png" />
        <p class="Benefit-text"><span>Svanen</span> miljömärkt</p>
    </div>

    <div class="Benefit-item">
        <img class="Benefit-image" src="/Assets/Images/Icons/fiveyearText.png" />
        <p class="Benefit-text"><span>5 års</span> garanti</p>
    </div>


    </div>
</div>

*/

@import "../../common/variables.less";

.Benefit {
    text-align: center;
}

.Benefit-item{
    display: inline-block;
    padding: 0 @spaceXSmall;

      @media @bpSmall{
         padding: 0 @spaceXSmall;
    }
    @media @bpLarge{
         padding: 0 @spaceMedium;
    }
}

.Benefit-text{
    text-transform:uppercase;
    font-size:15/16em;
    font-family: @fontFamilyCondensed;
    line-height: 1.2em;
    span{
        display: block;
    }
}
.Benefit-image{
    display: block;
    margin: 0 auto @spaceSmall;
}
