.KitchenCabinetDoorsAndDrawerFronts {

    &-Header {
        padding-left: 20px;
        padding-right: 20px;
        max-width: @widthContainerLarge;
        margin: 0 auto;
       
        @media @bpMedium {
            padding-left: 0;
            padding-right: 0;
        }

        &-Preamble {
            width: 100%;
            
            @media @bpMedium {
                width: 50%;
            }
        }

        &--verticalSpaceLarge {
            padding-top: 48px;
            padding-bottom: 48px;

            @media @bpMedium {
                padding-top: 72px;
                padding-bottom: 72px;
            }
        }
    }

    &-Content {
        padding-left: 20px;
        padding-right: 20px;
        max-width: @widthContainerLarge;
        margin: 0 auto;

        @media @bpMedium {
            padding-left: 0;
            padding-right: 0;
        }

        &--verticalSpaceLarge {
            padding-top: 48px;
            padding-bottom: 48px;

            @media @bpMedium {
                padding-top: 72px;
                padding-bottom: 72px;
            }
        }

        &--wide {
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;
        }

        &--grey {
            background-color: @colorGrey;
        }

        h2 {
            font-size: 22px;
            
            @media @bpMedium {
                font-size: 32px;
                line-height: 38px;
            }
        }

        h4 {
            font-size: 16px;
        }

        button {
            padding: 8px 36px 8px 0;
        }

        .Filter-buttonGroup {
            display: flex;
            align-items: baseline;
        }

        .Filter {
            background-color: transparent;
            border: none;

            .Filter-content {
                padding: 20px 0;
            }
        }

        .Filter-clear:after {
            font-size: 12px;
        }
    }
}

.KitchenCabinetDoorsAndDrawerFronts-Product {
    &-ImageWrapper {
        position: relative;
        background-color: @colorWhite;
        padding: 56px 0;
        display: flex;
        align-items: center;

        img {
            max-height: 186px;
            margin: 0 auto;
        }

        i {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }
}