﻿/*#

Form styling.

Examples:

<div style="max-width:450px;">
    <div class="Form">
    <label class="Form-label">Välj butik</label>
    <span class="Select Form-select u-sizeFull u-marginBmd">
        <select class="Select-input">
            <option>Götgatan 15, Stockholm</option>
            <option>Nacka, Stockholm</option>
            <option>Klarabergsviadukten, Stockholm</option>
        </select>
    </span>
    <div class="Grid Grid--gutterAsm">
        <div class="Grid-cell u-md-size1of3 Form-item Form-date Form-icon">
            <label class="Form-label">Önskat datum</label>
            <input type="text" class="Form-field" data-module="datepicker" placeholder="Välj datum" />
        </div>

        <div class="Grid-cell u-md-size1of3 Form-item Form-time Form-icon">
            <label class="Form-label">Önskad tid</label>
            <input type="text" class="Form-field" placeholder="T.ex 15:00" />
        </div>

        <div class="Grid-cell u-md-size1of1 Form-item">
            <label class="Form-label">Ditt namn</label>
            <input type="text" class="Form-field" placeholder="Ditt namn" />
        </div>

        <div class="Grid-cell u-md-size1of1 Form-item">
            <label class="Form-label">Din e-postadress</label>
            <input type="text" class="Form-field" placeholder="Din e-postadress" />
        </div>

        <div class="Grid-cell">
            <a class="u-linkBorder" href="#">Läs mer om vår köksrådgivning</a>
            <button class="Button Button--dark Button--small u-pullRight">Skicka bokningsförfråga</button>
        </div>

    </div>
</div>
</div>

*/

@import "../../common/variables.less";

@FormBoarder: #aaa;
@Form-labelPadding: 0.2em;
@Form-fieldPadding: 0.45em;
.Form {
    margin-bottom: @spaceMedium;
    width: 100%;
    
    &.Form--fill {
        background: lighten(@colorBlueTint, 10%);
        padding: @spaceMedium;
    }
    
    &.Form--bordered {
        border: 1px solid @colorBlueTint;
        border-radius: 3px;
        padding: @spaceMedium;
    }
}

/* Rectangle 1: */

.Form-item { 
    display: block;
    width: 100%;
    padding-bottom: @spaceMedium;
    position: relative;
}

.Form-label,
.Form-field { 
}

.Form-label, .Form__Element__Caption {
    padding-top: 0;
    padding-left: 0;
    display: block;
    font-size: @textSizeSmall;
      @media @bpMedium{
            padding: @Form-labelPadding;
      }
}

.Form-field, .FormTextbox__Input {
    width:100%;
    text-indent: @spaceXSmall;
    padding-top: @Form-fieldPadding;
    padding-bottom: @Form-fieldPadding;
    border: 1px solid @FormBoarder;
    -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow:inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    z-index:2;
    position:relative;
    background: transparent;
    height: 45px;
    font-size: @textSizeSmall;
}

.Form-field--button {
  width: 100%;
  text-indent: @spaceXSmall;
  padding-top: @Form-fieldPadding;
  padding-bottom: @Form-fieldPadding;
  border: 1px solid @FormBoarder;
  -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 2;
  position: relative;
  height: 45px;
}

.FormField--textarea{
    text-indent:0;
    max-height:300px;
    padding-left: 1.5%;
    padding-right: 1.5%;
    height:auto;
}

.Form-button {
    padding-bottom: 0.38rem;
    padding-top: 0.34rem;
    height: 45px;

    @media @bpMedium {
        position: relative;
        //top: -2px;
    }
}

.Form-buttonRight{
    margin-top: @spaceMedium;
    width:100%;
    @media @bpLarge{
        float: right;
        margin-top: 0;
        width: auto;
    }
}

aside .Form-controls {
	width: 100% !important;

	.Form-buttonRight {
		width: 100%;
	}

	@media @bpXLarge {
		
	}
}

.FormField--error{
    border-color: @colorRed!important;
} 


.Form-icon{
    &:after{
        font-family: HomeIcons;
        position:absolute;
        right: .6em;
        top: 1.6em;
        font-size: 1.3em;
        z-index:1;
            @media @bpMedium{
                top: 1.65em;
            }
    }
}

.Form-date{
    .Form-field {
         cursor: pointer;
    }
    &:after{
        content:"\ea70";
    }
}

.Form-time{
    &:after{
        content:"\e94e";
    }
}



@media @bpSmall {
    .Form--horizontal {
        display: table;
        caption-side: bottom;
        
        .Form-item {
            display: table-row;
            padding: 0;
            
            &.Form-item--submit {
                display: table-caption;
                width: 100%;
                padding-top: @spaceSmall;
                
                .Form-field {
                    display: block;
                    text-align: center;
                }
            }
        }
        
        .Form-label,
        .Form-field {
            display: table-cell;
            vertical-align: top;
            padding: @spaceSmall 0; 
        }
        
        .Form-label {
            padding-right: @spaceMedium;
        }
    }
}

//aside form

aside{
    Form{
        a{
            display: block;
            float: right;
            margin-bottom: @spaceSmall;
        }
    }
}

// epiForms

.EPiServerForms .Form__Element.FormTextbox {
    margin-bottom: @spaceSmall;
}

.EPiServerForms .Form__Status .Form__Success__Message{
    background-color: #87c020; //lighten(@colorGreen, 10%)!important
    color: @colorWhite!important;
    display: none;
}


.EPiServerForms .Form__Status .Form__Status__Message.hide{
    display: none;
}

.EPiServerForms .Form__Status .Form__Status__Message.Form__Success__Message{
    display: block;
}

.EPiServerForms{
    button{
        .Button;
    }
}