.KitchenImageMapBlock {
    position: relative;

    &-mapItem {
        color: @colorBlack;
        position: absolute;
        cursor: pointer;
        
        &-content {
            display: none;
            position: relative;
            background-color: @colorWhite;
            padding: 8px 16px;
            left: 48px;
            top: 50%;
            transform: translateY(-75%);
            min-width: 180px;

            span::after {
                background-position: 50%;
                bottom: 0;
                content: "";
                content: "\e921";
                font-family: HomeIcons;
                font-size: 12px;
                pointer-events: none;
                margin-left: 6px;
                right: 16px;
                top: 17px;
                width: 2.2em;
                z-index: 2;
            }

            hr {
                margin: 4px 0;
            }

            &--leftAligned {
                left: calc(-100% - 20px);
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    width: 20px;
                    height: 100%;
                    background: transparent;
                }
            }

        }
    }

    a {
        font-weight: bold;
    }

    a:hover {
        color: @colorOrange;

    }
    
    &-mapItem:hover {
        .KitchenImageMapBlock-mapItem-content {
            display: block;
        }
    }
}