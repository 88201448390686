﻿/*#

Display a page navigation that can be used for both vertical and horizontal navigations, e.g. header nav or sidebar nav.

Examples:

<div>
    <h4>Horizontal</h4>
    <nav class="PageNav PageNav--horizontal">
        <ul class="PageNav-list">
            <li class="PageNav-item">
                <a href="#" class="PageNav-itemLink is-active">Frontend Docs</a>
            </li>
            <li class="PageNav-item">
                <a href="#" class="PageNav-itemLink">Backend Docs</a>
            </li>
            <li class="PageNav-item">
                <a href="#" class="PageNav-itemLink">About Encore</a>
            </li>
        </ul>
    </nav>
    
    <h4>Vertical</h4>
    <nav class="PageNav PageNav--vertical">
        <ul class="PageNav-list">
            <li class="PageNav-item">
                <a href="#" class="PageNav-itemLink is-active">Frontend Docs</a>
            </li>
            <li class="PageNav-item">
                <a href="#" class="PageNav-itemLink">Backend Docs</a>
            </li>
            <li class="PageNav-item">
                <a href="#" class="PageNav-itemLink">About Encore</a>
            </li>
        </ul>
    </nav>
</div>

*/

@import "../../common/variables.less";

@PageNav-borderColor: @borderColor;
@PageNave-textHD: 25/16em;
@PageNave-spaceHD: 1.4em;

.PageNav {
    font-family: @fontFamilyHeading;
}

.PageNav-list {
    list-style: none;
    margin: 0;
    padding: 0.4em 0;
    display: inline-block;
}

.PageNavList--right {
    padding-top: 3px;
    padding-bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
}

.symbols-wrapper {
    display: inline-block;
    min-height: 32px;
    padding: 0;
    position: relative;
    top: 7px;

    .PageNav-item {
        margin-left: @spaceXSmall !important;

        &:first-child {
            margin-left: .3em !important;
        }
    }
}

.PageNavList--second {
    li:first-child {
        @media @bpMedium {
            border-left: @borderDotted @borderColor;
            margin-left: @spaceMedium;
            padding-left: @spaceMedium;
        }
    }

    li:last-child {
        @media @bpLarge {
            border-left: @borderDotted @borderColor;
            padding-left: @spaceMedium;
        }
    }

    .DropMenu-list {
        li:first-child {
            border-left: none;
            margin-left: 0;
            padding-left: 0;
        }

        li:last-child {
            border-left: none;
            padding-left: 0;
        }
    }

    .PageNav--logotype {
        margin-right: @spaceXXXXSmall !important;
    }
}

.PageNav-item {
    padding: 0;
}

.PageNav-itemLink {
    color: @colorBlueRich;
    text-decoration: none;

    &:hover {
        color: @colorText;
        text-decoration: none;
    }
}

.PageNav--horizontal {
    font-size: @textSizeSmall;

    .PageNav-item {
        display: inline-block;
        padding: 0;
        margin-right: @spaceSmall;

        @media @bpXLarge {
            margin-right: @PageNave-spaceHD;
            letter-spacing: -.05em;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.PageNav--vertical {
    .PageNav-item {
        .PageNav-list {
            margin-left: @spaceMedium;
        }
    }

    .PageNav-itemLink {
        display: block;
        padding: @spaceSmall 0;

        &.is-active {
        }
    }
}

.PageNav--large {
    .PageNav-itemLink {
        text-transform: none;
        padding-bottom: 0.05em;
        font-size: 15px;
        //border-bottom: 4px solid @colorWhite;

        &.is-active {
            border-bottom: 4px solid @brandColor;
            z-index: 15;
            position: relative;
            color: @colorText;
        }

        &:hover {
            color: @colorText;

            @media @bpLarge {
                //border-bottom: 4px solid @brandColor;
                z-index: 15;
                position: relative;
            }
        }
    }
}
.Header-secondary {
    .PageNavList--right {
        font-size: @textSmall;
    }
}


.PageNav--small {
    .PageNav-item {
        margin: 0 0 0 @spaceSmall;

        &:first-child, &:last-child {
            border: none;
        }

        &:last-child {
            padding: 0;
        }

        .PageNav-itemLink, &.PageNav-symbols a {
            font-family: @fontFamilyPrimary;

            @media @bpLarge {
                color: @colorBlueRich;
            }

            &:hover {
                color: @colorBlueMid;
            }

            .Icon--heart-filled {
                line-height: 0;
                font-size: 24px;
                top: -3px;
                position: relative;
            }
        }

        &.PageNavItem--border {
            border-right: @borderDotted @borderColor;
            padding-right: @spaceSmall;

            @media @bpMedium {
                border-right: @borderDotted @colorWhite;
            }
        }

        &.PageNavItem-border--right {
            border-left: @borderDotted @borderColor;
            padding-left: @spaceSmall;
        }
    }

    .Icon {
        line-height: 1.5em;
    }
}

.PageNav-symbols {
    font-size: @textSizeXXLarge;
    position: relative;
    padding: 0 !important;
    margin: 0;

    @media @bpLarge {
        top: 5px;
        font-size: 30px;

        .Sticky-element & {
            top: 0;
        }
    }
}

.HeaderPrimary-search {
    margin-top: @spaceXSmall;
    line-height: 1.5em;
    padding-left: @spaceMedium;
    padding-right: 0;
    border-left: @borderDotted @borderColor;

    @media @bpMedium {
        position: absolute;
        right: 0;
        height: 25px;
    }
}

.PageNav-image {
    max-width: 90px;
}
// ----------------- Mobile --------------------- //
.Header-mobile {
    .PageNav--large {
        .PageNav-itemLink {
            padding-top: @spaceXSmall;
            padding-bottom: @spaceXSmall;

            @media @bpMedium {
                padding-top: @spaceSmall;
                padding-bottom: @spaceSmall;
            }

            &.is-active {
                border-bottom: none;
                color: @brandColor;
            }
        }
    }
}

.HeaderSecondary--mobile {
    &.is-active {
        width: 100%;
        background: @colorWhite;
        min-height: 100vh;
    }
}

.is-stickyHidden {
    &.Sticky-hold {
        transform: translateY(0) !important;
    }
}

.Icon--subNav {
    display: inline-block;
    width: 25px;
    margin-right: .1em;
    text-align: center;
}

.Icon--mobMenu {
    display: inline-block;
    width: 20px;
    margin-right: .1em;
    text-align: center;
}

.Header-mobileTop {
    background: @colorBlueRich;
    padding: @spaceSmall;
    //display: none;

}

.HeaderPrimary--mobile {
    .PageNavListMobile--symbols {
        float: right;
        padding: 0;
        overflow: hidden;
    }

    .PageNav-symbols {
        .Icon {
            color: @colorBlueRich;
            font-size: @textSizeXXLarge;
        }
    }
}

.PageNavListMobile--menu {
    color: @colorText;
    background: @colorWhite;
    width: 100%;
    border-bottom: @borderDotted @borderColor;
}

.PageNavLarge--mobile {
    ul {
        display: block;
        width: 100%;

        &:first-child {
            padding-top: 0;
        }

        .PageNav-item {
            margin: 0 @spaceSmall;
            border-bottom: @borderDotted @borderColor;

            &:last-child {
                border-bottom: none;
            }

            .PageNav-itemLink {
                font-size: @textSizeMedium;
                display: inline-block;
                width: 65%;
            }

            .PageNavItem-parent {
                text-align: right;
                display: inline-block;
                width: 32%;
                color: @colorSoftBlack;
                position: relative;
                top: 7px;
                line-height: 32px;
                font-size: @textSizeXXLarge;
                //float:right;
                &.PageNavItemParent--noLink {
                    width: 100%;

                    .PageNav-noLink {
                        display: inline-block;
                        float: left;
                        font-size: 18px;
                        top: -7px;
                        padding: @spaceXSmall 0;
                        position: relative;

                        @media @bpMedium {
                            padding-bottom: @spaceXSmall;
                        }
                    }
                }
            }
        }

        ul {
            margin: -1em 0 0;
            padding-left: @spaceXSmall;

            .PageNav-item {
                padding: 0;
                margin: 0;

                .PageNav-itemLink {
                    padding-top: 0.2em;
                    padding-bottom: 0.2em;

                    @media @bpMedium {
                        padding-top: @spaceXSmall;
                        padding-bottom: @spaceXSmall;
                    }
                }

                ul {
                    margin-top: -.5em;
                    padding-left: 2.2em;

                    .PageNav-item {
                        a {
                            font-size: @textSizeSmall;
                        }
                    }

                    ul {
                        margin-top: -.5em;
                        padding-left: @spaceXSmall;
                    }
                }

                &:last-child {
                    border: none;
                }

                .PageNav-itemLink {
                    text-transform: none;
                }
            }
        }
    }



    .PageNavList--second {
        padding-bottom: 0;

        .PageNav-item {
            &:first-child {
                border: none;
                padding-left: 0;
            }
        }

        ul {
            .PageNav-item {
                &:first-child {
                    border-bottom: @borderDotted @borderColor;
                }
            }
        }
    }
}

.PageNav--small {
    .PageNavListMobile--menu {
        display: flex;
        justify-content: space-between;
        padding: 5px @spaceDefault;

        .PageNav-item {
            margin: 0;
            button {
                padding: 0;
            }

            .Icon, a {
                font-size: @textSizeLarge;


                &.Icon--search {
                    font-size: 1.6em;
                }

                &.Icon--location {
                    font-size: 1.3em;
                    margin-right: .1em;
                }
            }

            &.PageNavItem-location {
                max-width: 90%;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                word-wrap: normal !important;
                display: inline-block;
            }
        }
    }
}

.locationText {

    &:hover {
        color: @colorText;

        @media @bpLarge {
            color: @colorWhite;
        }
    }

    &:after {
        content: "\e91a";
        margin-left: 1em;
        font-family: HomeIcons;
        font-size: .2em;
        position: relative;
        top: -2px;
    }
}

.PageNavList--footer {
    background: @colorBlueTint;
    padding-bottom: @spaceXLarge;
    overflow: hidden;
}
// mob Hamburger Icon
/**/
.c-hamburger {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 21px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
    background: transparent;
    top: 5px;
}

.c-hamburger:focus {
    outline: none;
}

.c-hamburger span {
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    height: 4px;
    background: @colorBlueRich;
}

.c-hamburger span::before,
.c-hamburger span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: @colorBlueRich;
    content: "";
}

.c-hamburger span::before {
    top: -7px;
}

.c-hamburger span::after {
    bottom: -7px;
}

.c-hamburger--htx span {
    transition: background 0s 0.3s;
}

.c-hamburger--htx span::before,
.c-hamburger--htx span::after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
}

.c-hamburger--htx span::before {
    transition-property: top, transform;
}

.c-hamburger--htx span::after {
    transition-property: bottom, transform;
}
/* active state, i.e. menu open */
.c-hamburger--htx.is-active span {
    background: none;
}

.c-hamburger--htx.is-active span::before {
    top: 0;
    transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.c-hamburger--htx.is-active span::before,
.c-hamburger--htx.is-active span::after {
    transition-delay: 0s, 0.3s;
}



@media all and (max-width: 1000px ) and (min-width: 750px) {
    .Header {
        .PageNav--large {
            font-size: @textSizeLarge;
        }
    }
}

@media all and (max-width: 845px ) and (min-width: 768px) {
    .Header {
        .PageNav--large {
            font-size: @textSizeMedium;
        }
    }
}


