@import "../../../../assets/Styles/common/variables.less";

.LinteItems {

}
.LineItem {
	overflow-y: auto;

	.LineItem-row {
		display: flex;
		justify-content: space-between;
	}

	.LineItem-price {
		font-size: @textSizeMedium;

		&.LineItem-price-normal {
			text-decoration: line-through;
		}

		&.LineItem-price-discounted {
			color: @colorDarkRed;
		}
	}
}