@import (reference) "../../common/variables.less";

@Heading-color: @colorBlack;
@Heading-size1: 45px;
@Heading-size2: 32px;
@Heading-size3: 22px;
@Heading-size4: 18px;
@Heading-size5: 15px;
@Heading-size6: 14px;

.Heading {
    display: block;
    margin-top: 0;
    margin-bottom: .3em;
    color: @Heading-color;
    font-weight: 200;
    font-family: @fontFamilyHeading;
    letter-spacing: -0.015em;

    a:hover & {
      //  color: @colorLinkHover;
    }
}

.Heading--h1, h1 {
    &:extend(.Heading all);
    font-size: 2em;
    line-height: 30px;

    @media @bpLarge {
        font-size: unit(@Heading-size1/@baseFontSize, em);
        line-height: 50px;
    }
}



.Heading--h2, h2 {
    &:extend(.Heading all);
    font-size: 1.5em;
    line-height: 24px;

    @media @bpLarge {
        font-size: unit(@Heading-size2/@baseFontSize, em);
        line-height: 1.18em;
    }
}

.Heading--h3, h3 {
    &:extend(.Heading all);
    font-size: 1.2em;
    line-height: 1.25em;

    @media @bpLarge {
        font-size: unit(@Heading-size3/@baseFontSize, em);
        line-height: 1.18em;
    }
}

.Heading--h4, h4 {
    &:extend(.Heading all);
    font-size: unit(@Heading-size4/@baseFontSize, em);
}

.Heading--h5, h5 {
    &:extend(.Heading all);
    font-size: unit(@Heading-size5/@baseFontSize, em);
    text-transform: uppercase;
}

.Heading--h6, h6 {
    &:extend(.Heading all);
    font-size: unit(@Heading-size6/@baseFontSize, em);
}


//

.Heading--size {
    display: block;
    margin-top: 0;
    margin-bottom: .3em;
    font-weight: 200;
    font-family: @fontFamilyHeading;
    letter-spacing: -0.015em;
}

.Heading--h1Size {
    &:extend(.Heading--size all);
    font-size: 2em;
    line-height: 30px;

    @media @bpLarge {
        font-size: unit(@Heading-size1/@baseFontSize, em);
        line-height: 50px;
    }
}


.Heading--h2Size {
    &:extend(.Heading--size all);
    font-size: 1.5em;
    line-height: 24px;

    @media @bpLarge {
        font-size: unit(@Heading-size2/@baseFontSize, em);
        line-height: 1.18em;
    }
}

.Heading--h3Size {
    &:extend(.Heading--size all);
    font-size: 1.2em;
    line-height: 1.25em;

    @media @bpLarge {
        font-size: unit(@Heading-size3/@baseFontSize, em);
        line-height: 1.18em;
    }
}

.Heading--h4Size {
    &:extend(.Heading--size all);
    font-size: unit(@Heading-size4/@baseFontSize, em);
}