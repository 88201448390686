﻿/*#


*/

@import "../../common/variables.less";

.Chat {
    position: relative;
}
#CentionChatBody {
    border-left: 1px solid #f1f1f1 !important;
    border-right: 1px solid #f1f1f1 !important;
}
.Chat-content {
    background: @colorBlack;
    padding: @spaceLarge;
    border-radius: 8px;
    position: relative;
}

.Chat-image {
    position: absolute;
    bottom: 0px;
}

.Chat-arrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid @colorBlack;
    position: absolute;
    bottom: 60px;
    right: -10px;
}

#CentionChat, #CentionChat input, #CentionChat textarea{
    font-family: @fontFamilyPrimary!important;
}

#CentionChat {
	#CentionChatHeader {
		width: 12% !important;
		right: 0;
		border-radius: 0 !important;
		padding: @spaceXSmall 0;
		height: 45px;

		@media @bpXLarge {
			right: @spaceLarge;
			width: auto !important;
			height: auto;
			border-top-left-radius: 5px !important;
			border-top-right-radius: 5px !important;
			padding-bottom: 0;
		}

		&.maximized {
			right: 15px;
			width:347px !important;
		}
	}
}

#CentionChatHeader #textTitle {
    padding: 0;
    width: auto !important;
    max-width: 70%; /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important; /* 2 */
    display: none;

    @media @bpXLarge {
        width: 120px !important;
        display: inline-block;
    }
}

#CentionChatHeader #toggleChatHeader {
    display: none;

    @media @bpXLarge {
        max-width: 70%; /* 1 */
        display: inline-block;
        padding-bottom: 0;
        padding-left: 0;
        padding-top: .3em !important;
    }
}

#CentionChatHeader > .icon {
    padding-bottom: 0;
}

#CentionChatHeader.maximized #toggleChatHeader {
    padding-top: .3em;
}

#CentionChatHeader.maximized #toggleChatHeader,
#CentionChatHeader.maximized #textTitle {
    display: inline-block;
}

#CentionChatHeader .icon,
#CentionChatHeader .text,
#CentionProactiveHeader .icon,
#CentionProactiveHeader .text,
#toggleChatHeader.fa-chevron-up {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

#CentionChatHeader .icon {
    text-align: center;
    width: 100%;
    display: block;

    @media @bpLarge {
        text-align: left;
        display: inline-block;
        width: auto;
    }
}

#CentionChatHeader.maximized .icon {
    text-align: left;
    display: inline-block;
    width: auto;
}
