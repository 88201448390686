@import "../../../../Assets/Styles/common/variables.less";

.StoreCampaign {
	> .Page-container > div > .Grid-cell {
		.u-paddingVlg;
	}

	.heading--verticalSpaceLarge {
		padding-top: 48px;
		padding-bottom: 48px;

		@media @bpMedium {
			padding-top: 72px;
			padding-bottom: 72px;
		}
	}

	&-hero {
		> .Page-container > .Grid {
			min-height: 440px;

			@media @bpMedium {
				min-height: 540px;
			}
		}

		.Page-container {
			.u-paddingVmd;
		}

		&-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&-cta {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: space-between;

			.Button {
				display: none;

				@media @bpMedium {
					display: inline-flex;
				}
			}

			p {
				.Heading--h5;
				background: @colorOrange;
				color: @colorBlack !important;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				padding: 20px;
				transform: rotate(13deg);
				width: 128px;
				height: 128px;
				border-radius: 64px;

				@media @bpMedium {
					.Heading--h3;
					width: 200px;
					height: 200px;
					border-radius: 100px;
				}
			}
		}
	}
}
