/*#

Maps

Examples:
<div style="max-width:400px;">
    <div class="Map" data-module="map" data-map="data: { lat: 59.31392, lng: 18.07326 }"></div>         
</div>
*/

@import "../../common/variables.less";

.Map {
    width: 100%;
    height: 150px;

    @media @bpMedium {
        height: 270px;
    }

    @media @bpLarge {
        width: 90%;
    }

    &.Map-lg {
        width: 100%;
        height: 300px;

        @media @bpMedium {
            height: 600px;
        }
    }
}
