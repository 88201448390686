﻿@import (reference) "../../../../Assets/Styles/common/variables.less";

.KitchenMaterialBlock {
    &-color {
        display: inline-block;
        position: relative;

        &-content {
            display: none;
            position: absolute;
            background: @colorWhite;
            min-width: 220px;
            min-height: 150px;
            box-shadow: 0 4px 18px 0 rgba(0,0,0,0.1);
            z-index: 100;
            bottom: 32px;
            right: 1px;

            > div {
                padding: 8px 16px;
            }

            &-title {
                border-bottom: 1px solid @colorGrey;
                /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
            }

            &-info {
                > div {
                    width: 188px;
                    height: 51px;
                    flex-shrink: 0;
                }
            }
        }

        &:hover {
            .KitchenMaterialBlock-color-content {
                display: block;
            }
        }
    }
}
