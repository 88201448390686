﻿.IndexUSP {
    display: flex;
    padding: @spaceSmall 0;

    span {
        font-size: 22px;
        color: @colorBlueRich;
        font-family: @fontFamilyCondensed;
        display: none;

        &:first-child {
            display: block;
        }
    }

    @media @bpMedium {
        padding: @spaceLarge 0;

        span {
            display: block;
        }
    }
}
