@import "../../common/variables.less";

	.Suit {
		
	}

.Suit-card {
    background: @colorBlueMid;
    display: flex;
    padding: @spaceSmall;
    height: 100%;

    @media @bpMedium {
        padding: @spaceMedium;
    }

    @media @bpLarge {
        min-height: 320px;
        padding: @spaceLarge @spaceXLarge @spaceXLarge @spaceLarge;
    }
}


    .Suit-icon {
        display: none;
        position: absolute;

        @media @bpMedium {
            display: block;
            height: 30px;
        }

        @media @bpLarge {
            height: 35px;
        }

        @media @bpXLarge {
            height: 40px;
        }
    }

.SuitCard-heading {
    // color: @colorMossMid;
    color: #ffa989;
    font-size: 1.5em;
    font-family: @fontFamilyHeading--light;
    align-self: flex-end;
    text-align: center;
    margin-bottom: 0;
    width: 100%;


    @media @bpMedium {
        text-align: right;
        font-size: 2.5em;
        line-height: 42px;
        padding-left: 1em;
    }

    @media @bpLarge {
        font-size: 3em;
        padding-left: 1.8em;
        line-height: 50px;
    }

    @media @bpXLarge {
        font-size: 3.5em;
        padding-left: 2em;
        line-height: 56px;
    }
}