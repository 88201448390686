.KitchenPromoBlock {
    position: relative;
    
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    a {
        span {
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: @colorWhite;
            border: 2px solid @colorWhite;
            padding: 11px 32px;
        }
    }

    a:hover {
        span {
            color: @colorOrange;
            border-color: @colorOrange;
        }
    }
    
}