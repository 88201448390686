﻿
.Sticky, .StickyWrapper{
	//overflow: hidden;
    &.is-active{
    height: 100vh!important;
    overflow-y: scroll;
    z-index: 999;
    position:fixed;
    width: 100%;
    }

    @media print {
        display: none;
    }
      
}
.Sticky-element {
	transition: transform .5s .1s ease-in-out;
	will-change: transform;
    z-index:20;
    position:relative;
}

.Sticky-element.is-sticky {
	position: fixed;
	top: 0;
	left: auto;
	right: auto;
	z-index: 100;
	backface-visibility: hidden;
}

.Sticky-element.is-stickyHidden {
	transform: translateY(-100%);
}

.Sticky-element.is-stickyBottom {
	position: absolute !important;
	top: auto !important;
	bottom: 0;
}

.Sticky--visibleWhenSticky .Sticky-element {
	position: fixed;
	top: 0;
	opacity: 0;
	transform: translateY(-100%);            
}

.Sticky--visibleWhenSticky .Sticky-element.is-sticky {
	opacity: 1;
	transform: translateY(0);
}

.editMode{
    .Sticky-element{
        max-width:100%!important;
    }
}

.Sticky-zeroHeight-header {
	height: 0;

	header {
		
		.Header-primary,
		.Header-secondary {
			-webkit-transition: all 200ms ease-in-out;
			-moz-transition: all 200ms ease-in-out;
			-ms-transition: all 200ms ease-in-out;
			-o-transition: all 200ms ease-in-out;
			transition: all 200ms ease-in-out;

			a, a.is-active  {
				border-bottom: 4px solid transparent;
			}

		}
	}
}

.Sticky-transparent-header {
	
	header {
		position: fixed;
		top: 0;
		left: auto;
		right: auto;
		z-index: 100;
		backface-visibility: hidden;
		border-bottom: none;
		.Header-mobile,
		.Header-primary,
		.Header-secondary {
			background: transparent;
			
			a, a.is-active  {
				border-bottom: 4px solid transparent;
			}

			.PageNav-item {
				a {
					color: @colorWhite;
				}
			}
			
			.PageNav--large .PageNav-itemLink.is-active {
				color: @colorOrange;
			}

			a, i {
				color: @colorWhite;
			}

			.Header-logo {
				img {
					filter: brightness(0) invert(1);
				}
			}
			.Search-field,
			input.Search-input {
				background-color: rgba(0, 0, 0, 0.3);
				color: @colorWhite!important;
			}

			input.Search-input:-ms-input-placeholder {
				color: @colorWhite!important;
			}
			input.Search-input::placeholder {
				color: @colorWhite!important;
			}

			.DeviceNav-wrapper .DeviceNav-headerList {
				background: transparent;
			}

			.DeviceNav-wrapper .toggle span,
			.DeviceNav-wrapper .toggle span:before,
			.DeviceNav-wrapper .toggle span:after {
				transition: none;
				background-color: @colorWhite;
			}
		}
	}
}