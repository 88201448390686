﻿/*#

Custom styling for radio buttons. Supports focus and disabled states.

Examples:

<div>
    <span class="Radio u-marginRsm">
        <input id="rb1" type="radio" name="priority" class="Radio-input">
        <label for="rb1" class="Radio-label">Low Priority</label>
    </span>

    <span class="Radio u-marginRsm">
        <input id="rb2" type="radio" name="priority" class="Radio-input">
        <label for="rb2" class="Radio-label">Medium Priority</label>
    </span>

    <span class="Radio u-marginRsm">
        <input id="rb3" type="radio" name="priority" class="Radio-input">
        <label for="rb3" class="Radio-label">High Priority</label>
    </span>
</div>

*/

@import "../../common/variables.less";

@Radio-checkedShadow: inset 0 0 0 .2em @colorWhite;

.Radio {
    .Radio-input:disabled + .Radio-label,
    &.is-disabled .Radio-label,
    &.is-disabled.Radio-label
     {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.Radio-input {
    opacity: 0;
    position: absolute;
    
    &:focus + .Radio-label:before {
        box-shadow: 0 0 1px 1px @colorOrange;
    }
    
    &:checked:focus + .Radio-label:before {
        box-shadow: 0 0 1px 1px @colorOrange, @Radio-checkedShadow;
    }
}

.Radio-input, .Radio-label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.Radio-label {
  position: relative;

  &:before {
    content: '';
    background: #fff;
    border: 1px solid @colorSoftBlack;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    padding: .25em;
    margin-right: .3em;
    text-align: center;
    border-radius: 50%;
    vertical-align: -0.1em;
  }
}

.Radio-input:checked + .Radio-label:before {
    background: @colorOrange;
    box-shadow: @Radio-checkedShadow;
}