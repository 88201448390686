﻿/*#

Component for the header section.

Examples:

<div style="max-width:1400px;">
    <header class="Header">
                <div class="Header-secondary u-hidden u-lg-block">
            <div class="Page-container">
                <nav class="PageNav PageNav--horizontal PageNav--small Header-secondaryNav">
                    <div class="Header-logo">
                        <a href="/"><img src="/assets/images/logo/logo.png" class="Header-logoImage" width="163" alt="Logo" /></a>
                    </div>
                    <ul class="PageNav-list PageNavList--right">

                        <li class="PageNav-item PageNavItem--border">
                            <a href"#" class="PageNav-itemLink"><i class="Icon Icon--question u-marginRxsm"></i>Kundtjänst</a>
                        </li>

                        <li class="PageNav-item PageNavItem--border PageNavItem-location  u-posRelative">
                            <a href="#" class="PageNav-itemLink" data-module="menu" data-menu="contentElement: '.js-StoreInfo', cssClass: 'is-active'"><span class="locationText"><i class="Icon Icon--location u-marginRxsm"></i>Din butik Stockholm/Sickla köpkvarter</span></a>
                        </li>

                        <li class="PageNav-item PageNavItem--border">
                            <a href="#" class="PageNav-itemLink"><i class="Icon Icon--head u-marginRxsm"></i>Login</a>
                        </li>
                        <li class="PageNav-item PageNav-symbols">
                            <a href="#"><i class="Icon Icon--heart-filled"></i><span class="Quantity">2</span></a>
                        </li>
                        <li class="PageNav-item PageNav-symbols">
                            <a href="#"><i class="Icon Icon--cart"></i><span class="Quantity">38</span></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="Header-primary u-hidden u-lg-block">
            <div class="Page-container Header-primaryContainer">
                <nav class="PageNav PageNav--horizontal PageNav--large Header-primaryNav">
                    <ul class="PageNav-list">
                        <li class="PageNav-item">
                            <a class="PageNav-itemLink" href="/dammsugare/">Dammsugare</a>
                        </li>
                        <li class="PageNav-item">
                            <a class="PageNav-itemLink" href="/vitvaror/">Vitvaror</a>
                        </li>
                        <li class="PageNav-item">
                                <a class="PageNav-itemLink" href="/hushallsapparater/">Hemmiljö</a>
                        </li>
                        <li class="PageNav-item">
                            <a class="PageNav-itemLink" href="/hushallsapparater/">Hushållsapparater</a>
                        </li>
                        <li class="PageNav-item">
                            <a class="PageNav-itemLink" href="/koksredskap/">Köksgeråd</a>
                        </li>
                    </ul>
                    <ul class="PageNav-list PageNavList--second">
                        <li class="PageNav-item">
                            <img src="/Assets/Images/Logo/sentens.png" />
                        </li>
                        <li class="PageNav-item">
                            <a class="PageNav-itemLink" href="#">Kök</a>
                        </li>
                        <li class="PageNav-item">
                            <a class="PageNav-itemLink" href="#">Förvaring</a>
                        </li>
                        <li class="PageNav-item">
                            <a class="PageNav-itemLink" href="#">Bad</a>
                        </li>
                        <li class="PageNav-item"></li>
                    </ul>
                    <button class="Icon Icon--search HeaderPrimary-search Icon--search-bold PageNavItem--border" data-module="toggler" data-toggler="contentElement: '.js-showSearch', cssClass: 'is-active', addClassToSelf: true"></button>
                </nav>
            </div>
        </div>

    </header>
</div>
 



Mobile menu
<div style="max-width:600px;">

   <header class="Header">
        
    <div class="Header-mobile">
    <div class="Header-primary HeaderPrimary--mobile">
        <nav class="PageNav PageNav--horizontal PageNav--small Header-secondaryNav">
            <div class="Header-mobileTop">
                <div class="Header-logo">
                    <a href="/"><img src="/assets/images/logo/logo.png" class="Header-logoImage" width="90" alt="Logo" /></a>
                </div>
                <ul class="PageNav-list PageNavList--mobile PageNavListMobile--symbols">
                    <li class="PageNav-item PageNav-symbols">
                        <a href="#"><i class="Icon Icon--heart-filled"><span class="Quantity">38</span></i></a>
                    </li>
                    <li class="PageNav-item PageNav-symbols">
                        <a href="#"><i class="Icon Icon--cart"></i></a>
                    </li>
                </ul>
            </div>

            <ul class="PageNav-list PageNavList--mobile PageNavListMobile--menu">
                <li class="PageNav-item">

                    <button class="c-hamburger c-hamburger--htx" data-module="menu" data-menu="contentElement: '.js-showMenu'">
                        <span>toggle menu</span>
                    </button>

                </li>

                <li class="PageNav-item PageNavItem--border PageNavItem-border--right">
                    <button class="Icon Icon--search Icon--search-bold u-transitionAmd" data-module="toggler" data-toggler="contentElement: '.js-showSearchMob', cssClass: 'is-active', addClassToSelf: true"></button>
                </li>
                <li class="PageNav-item PageNavItem-location">
                    <a href="#" class="PageNav-itemLink" data-module="menu" data-menu="contentElement: '.js-StoreInfoMob'"><span class="locationText"><i class="Icon Icon--location"></i>  Din butik: Stockholm/Sickla köpkvarter</span></a>
                </li>
            </ul>

        </nav>
    </div>
    <div class="Search Search--mobile js-showSearchMob Header-Search u-transitionMHmd u-showHide">
        <div class="Page-container">
            <label class="Search-heading">Sök</label>
            <input class="Search-input Input Input--medium" type="search" />
        </div>
    </div>

    <div class="HeaderSecondary--mobile u-lg-hidden js-showMenu u-transitionMHmd u-showHide">
        <div class="">
            <nav class="PageNav PageNav--vertical PageNav--large PageNavLarge--mobile Header-secondaryNav" data-module="mobile-menu" data-mobile-menu="toggler: '.js-iconToggler'">
                <ul class="PageNav-list">
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/dammsugare/">Dammsugare</a>
                        <div class="PageNavItem-parent IconToggler IconToggler--plusMinus js-iconToggler"></div>

                        <ul class="List List--vertical js-subMenu">
                            <li class="List-item PageNav-item ">
                                <a class="PageNav-itemLink" href="#"><i class="Icon Icon--vacuum-original Icon--subNav"></i> <span>Golvdammsugare</span></a>
                                <div class="PageNavItem-parent IconToggler IconToggler--plusMinus js-iconToggler"></div>
                                <ul class="List List--vertical js-subMenu">
                                    <li class="List-item PageNav-item">
                                        <a class="PageNav-itemLink" href="#">Veckostädning</a>
                                    </li>
                                    <li class="List-item PageNav-item">
                                        <a class="PageNav-itemLink" href="#">Snabbstädning</a>
                                    </li>
                                    <li class="List-item PageNav-item">
                                        <a class="PageNav-itemLink" href="#">Semi-professionell</a>
                                        <div class="PageNavItem-parent IconToggler IconToggler--plusMinus js-iconToggler"></div>
                                        <ul class="List List--vertical js-subMenu">
                                            <li class="List-item PageNav-item">
                                                <a class="PageNav-itemLink" href="#">Med hantag</a>
                                            </li>
                                            <li class="List-item PageNav-item">
                                                <a class="PageNav-itemLink" href="#">Utan sladd</a>
                                            </li>
                                            <li class="List-item PageNav-item">
                                                <a class="PageNav-itemLink" href="#">Ljudlös</a>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li class="List-item PageNav-item">
                                <a class="PageNav-itemLink" href="#"><i class="Icon Icon--vacuum-handheld Icon--subNav"></i> <span>Handdammsugare</span></a>
                            </li>
                            <li class="List-item PageNav-item">
                                <a class="PageNav-itemLink" href="#"><i class="Icon Icon--vacuum-central Icon--subNav"></i> <span>Centraldammsugare</span></a>
                            </li>
                            <li class="List-item PageNav-item">
                                <a class="PageNav-itemLink" href="#"><i class="Icon Icon--vacuum-pro Icon--subNav"></i> <span>Grovdammsugare</span></a>
                            </li>
                            <li class="List-item PageNav-item">
                                <a class="PageNav-itemLink" href="#"><i class="Icon Icon--vacuum-accessories Icon--subNav"></i> <span>Tillbehör</span></a>
                            </li>
                        </ul>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/vitvaror/">Vitvaror</a>
                        <div class="PageNavItem-parent"></div>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/hushallsapparater/">Hushållsapparater</a>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/koksredskap/">Köksgeråd</a>
                    </li>
                </ul>
                <ul class="PageNav-list PageNavList--second">
                    <li class="PageNav-item">
                        <img src="~/Assets/Images/Logo/sentens.png" />
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="#">Kök</a>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="#">Förvaring</a>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="#">Bad</a>
                    </li>
                    <li class="PageNav-item"></li>
                </ul>
                <ul class="PageNav-list PageNavList--footer">
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/dammsugare/">Logga in</a>
                        <div class="PageNavItem-parent"></div>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/dammsugare/">Kontakta kundtjänst</a>
                        <div class="PageNavItem-parent"></div>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/dammsugare/">Följ oss</a>
                        <div class="PageNavItem-parent"></div>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/dammsugare/">Om oss</a>
                    </li>
                    <li class="PageNav-item">
                        <a class="PageNav-itemLink" href="/dammsugare/">Hjälp</a>
                        <div class="PageNavItem-parent"></div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>


    </header>


</div>

*/

@import "../../common/variables.less";

.Header {
    font-family: @fontFamilyCondensed;
     @media @bpMedium{
         //border-bottom: @borderDotted @borderColor;
    }
}

.Header-secondary {
    padding: @spaceXSmall;
    background: @colorWhite;
}

.Header-primary {
    color: @brandColor;
    padding: 10px 18px;
    background-color: @colorWhite;
    .PageNav-itemLink {
        color: @brandColor;
    }
}

.Header-transparent {
    .Header-primary, .Header-mobile {
        background-color: transparent;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        .PageNav-itemLink {
            color: @colorWhite;
        }
        &:hover {
            background-image: none;
            background-color: @colorWhite !important;
            .PageNav-itemLink {
                color: @brandColor;
            }
            .Header-logo {
                img {
                    filter: none;
                }
            }
            .Icon--cart {
                filter: none;
            }
            .link-border {
                border-color: @brandColor;
                background-color: @brandColor;
                color: @colorWhite;
            }
            .Toggle-wrapper {
                .toggle {
                    span {
                        background-color: @brandColor;

                        &:after, &:before {
                            background-color: @brandColor;
                        }
                    }
                }
            }

        }
        .Header-logo {
            img {
                filter: brightness(0) invert(1);
            }
        }

        .Icon--cart {
            filter: brightness(0) invert(1);
        }

        .Toggle-wrapper {
            .toggle {
                span {
                    background-color: @colorWhite;

                    &:after, &:before {
                        background-color: @colorWhite;
                    }
                }
            }
        }
        .link-border {
            border-color: @colorWhite;
            background-color: transparent;
            color: @colorWhite;

        }
    }

}
.Header-primary {
    .link-border {
        border: 2px solid @brandColor;
        background-color: @brandColor;
        color: @colorWhite;
        border-radius: 8px;
        padding: 6px 8px;
    }

    .Icon--cart {
        color: @brandColor;
    }
}

.Header-search-icon {
    visibility: hidden;
    cursor: pointer;
}
.search-hidden {
    .Header-search-icon {
        visibility: visible;
    }
}

.is-sticky {
    .BookMeeting {
        .Header-search-icon {
            visibility: visible;
        }
    }
}

.Header-transparent {
    .BookMeeting {
        .Header-search-icon {
            &:hover {
                filter: brightness(0) invert(1);
            }
        }
    }
}

.is-sticky {
    .Header-search-icon {
            visibility: visible;
    }
}

.search-hidden {
    .Header-search-icon {
       //display: none;
    }
}

.hero {
    .Header-primaryContainer {
        max-width: 1920px;
    }
}

.Header-primaryContainer {
    display: grid;
    position: relative;
    max-width: 1220px;
    margin: auto;
    grid-template-columns: 4fr 1fr 4fr;

    .grid-item-1 {
        grid-column-start: 1;
    }
    .grid-item-2 {
        grid-column-start: 2;
        display: flex;
        justify-content: center;
    }
    .grid-item-3 {
        grid-column-start: 3;
        display: flex;
        justify-content: right;
        align-items: center;
    }
}



.Header-logo {
    color: @brandColor;
}

.Header-primaryNav {
    flex: 1 1 auto;
}

// mobile

.Header-mobile{
    background:@colorWhite;
    //  &.is-active{
    //        position: fixed;
    //        z-index: 999;
    //        width: 100%;
    //        height: 100vH;
    //        overflow-y: scroll;
    // }
}

.Header-notice {
    color: @colorWhite;
    margin-left: @spaceSmall;
    position: relative;
    font-size: 10px;
    top: 5px;


    @media @bpMedium {
        top: 8px;
        font-size: 14px;
    }
}
