/*#

Icon component displaying icons using an Icomoon.io generated icon web font.

To add or remove icons navigate to https://icomoon.io/app/ and import "selection.json" from the Styles/Fonts/Icons folder.

**Usage**  
To use an icon in a different component start by importing the Icon.less file:  
@import (reference) "../Atoms/Icon.less";

Then extend the CSS rule with .Icon and set "content" to the icon you're looking for:  
.MyComponent {
    &:after {
        &:extend(.Icon);
        content: @Icon--checkmark;
    }
}

Examples:

<div>
    <span class="Icon Icon--checkmark"></span>    
</div>

*/

@import "../../common/variables.less";

@Icon-fontFamily: 'HomeIcons';

@font-face {
	font-family: 'HomeIcons';
	src: url('/Assets/Fonts/Icons/HomeIcons.eot?wyybw8');
	src: url('/Assets/Fonts/Icons/HomeIcons.eot?wyybw8#iefix') format('embedded-opentype'), url('/Assets/Fonts/Icons/HomeIcons.ttf?wyybw8') format('truetype'), url('/Assets/Fonts/Icons/HomeIcons.woff?wyybw8') format('woff'), url('/Assets/Fonts/Icons/HomeIcons.svg?wyybw8#Icon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.Icon {
	font-family: @Icon-fontFamily !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.IconWrapperSM {
	width: 30px;
	text-align: center;
	display: inline-block;
}
/* Icon size */
.Icon--text {
	font-size: 7px;
	top: -1px;
	position: relative;
}

.Icon--large {
	font-size: 2em;
}

.Icon--small{
	font-size:0.8em;
}

/* Icon colors */
.Icon--green-range {
	color: @colorGreen;
}

.Icon--wish-empty {
	color: @brandColor;
}

.Icon--wish-active {
    color: @colorOrange;
}

.Icon--search-bold {
    font-weight: 700;
}

.Icon--in-stock--gray {
	color: @colorBlueTint;
}

/* START icon variables */

//
@Icon--coffee: "\e941";
@Icon--messenger: "\e900";
@Icon--file-pdf: "\eadf";
@Icon--file-text2: "\e938";
@Icon--file-picture: "\e93b";
@Icon--file-music: "\e93c";
@Icon--file-video: "\e93d";
@Icon--file-zip: "\e93e";
@Icon--file-word: "\eae1";
@Icon--file-excel: "\eae2";
@Icon--connection: "\e937";
@Icon--online: "\e9c9";
@Icon--home: "\e936";
@Icon--ac: "\e93a";
@Icon--windowCleaner: "\e939";
@Icon--Robot_vaccum_cleaner: "\e935";
@Icon--stick_vacuum_cleaner: "\e934";
@Icon--accessories_vacuum_cleaner: "\e931";
@Icon--central_vaccume_cleaner: "\e932";
@Icon--Handheld_vacuum_cleaner: "\e933";
@Icon--accessories_appliances: "\e911";
@Icon--climate_ac: "\e912";
@Icon--cloth_care: "\e913";
@Icon--cooktops: "\e914";
@Icon--dishwasher: "\e915";
@Icon--fridge_freezer: "\e925";
@Icon--hood: "\e926";
@Icon--inspiration: "\e927";
@Icon--kitchen_appliances: "\e928";
@Icon--laundry_dryer: "\e929";
@Icon--microwave_oven: "\e92a";
@Icon--oven: "\e92b";
@Icon--pots_pans: "\e92c";
@Icon--small_appliances: "\e92d";
@Icon--stove: "\e92e";
@Icon--utensils: "\e92f";
@Icon--vacuum_cleaner: "\e930";
@Icon--compare: "\e924";
@Icon--bin: "\e9ad";
@Icon--info: "\e908";
@Icon--outofstock: "\ea0f";
@Icon--instock: "\ea10";
@Icon--mail: "\e916";
@Icon--quote: "\e90d";
@Icon--print: "\e954";
@Icon--time: "\e94e";
@Icon--tv: "\e95b";
@Icon--calendar: "\e942";
@Icon--googleplus: "\ea8c";
@Icon--twitter: "\ea96";
@Icon--arrow-left: "\e920";
@Icon--arrow-right: "\e921";
@Icon--download: "\e922";
@Icon--filter: "\e923";
@Icon--five: "\e91f";
@Icon--close: "\e919";
@Icon--arrow-down: "\e91a";
@Icon--arrow-up: "\e91b";
@Icon--clock: "\e91c";
@Icon--contact: "\e91d";
@Icon--facebook: "\e90b";
@Icon--instagram: "\e91e";
@Icon--pinterest: "\e917";
@Icon--youtube: "\e918";
@Icon--plus: "\e90c";
@Icon--minus: "\e90f";
@Icon--menu: "\e910";
@Icon--wish-empty: "\e90e";
@Icon--wish-active: "\e90a";
@Icon--head: "\e909";
@Icon--delivery: "\e906";
@Icon--readMore: "\e907";
@Icon--cart: "\e901";
@Icon--heart-filled: "\e905";
@Icon--location: "\e902";
@Icon--lock-closed: "\e903";
@Icon--question: "\e904";
@Icon--search: "\e93f";
@Icon--laundry_dryer2: "\e8fa";
@Icon--whatsapp: "\ea93";
@Icon--linkedin: "\eac9";
@Icon--dryer: "\e943";


/* ---------- Icons in edit -----------------------  */


@Icon--ac-edit: @Icon--ac;
@Icon--Robot_vaccum_cleaner-edit: @Icon--Robot_vaccum_cleaner;
@Icon--accessories_appliances-edit: @Icon--accessories_appliances;
@Icon--calendar-edit: @Icon--calendar;
@Icon--climate_ac-edit: @Icon--climate_ac;
@Icon--cloth_care-edit: @Icon--cloth_care;
@Icon--cooktops-edit: @Icon--cooktops;
@Icon--dishwasher-edit: @Icon--dishwasher;
@Icon--fridge_freezer-edit: @Icon--fridge_freezer;
@Icon--hood-edit: @Icon--hood;
@Icon--inspiration-edit: @Icon--inspiration;
@Icon--kitchen_appliances-edit: @Icon--kitchen_appliances;
@Icon--laundry_dryer-edit: @Icon--laundry_dryer;
@Icon--microwave_oven-edit: @Icon--microwave_oven;
@Icon--oven-edit: @Icon--oven;
@Icon--pots_pans-edit: @Icon--pots_pans;
@Icon--small_appliances-edit: @Icon--small_appliances;
@Icon--stove-edit: @Icon--stove;
@Icon--utensils-edit: @Icon--utensils;
@Icon--vacuum_cleaner-edit: @Icon--vacuum_cleaner;
@Icon--coffee-edit: @Icon--coffee;

@Icon--accessories_vacuum_cleaner-edit: @Icon--accessories_vacuum_cleaner;
@Icon--central_vaccume_cleaner-edit: @Icon--central_vaccume_cleaner;
@Icon--Handheld_vacuum_cleaner-edit: @Icon--Handheld_vacuum_cleaner;
@Icon--stick_vacuum_cleaner-edit: @Icon--stick_vacuum_cleaner;

@Icon--tv-edit: @Icon--tv;
@Icon--question-edit: @Icon--question;
@Icon--location-edit: @Icon--location;
@Icon--head-edit: @Icon--head;

@Icon--windowCleaner-edit: @Icon--windowCleaner;
@Icon--dryer-edit: @Icon--dryer;

/* END icon variables */
/* START icon classes */
//
.Icon--coffee {
    &:before {
        content: @Icon--coffee;
    }
}

.Icon--connection {
    &:before {
        content: @Icon--connection;
    }
}

.Icon--online {
    &:before {
        content: @Icon--online;
    }
}

.Icon--ac:before {
	content: @Icon--ac;
}

.Icon--windowCleaner:before {
	content: @Icon--windowCleaner;
}

.Icon--Robot_vaccum_cleaner {
	&:before {
		content: @Icon--Robot_vaccum_cleaner;
	}
}

.Icon--accessories_vacuum_cleaner {
	&:before {
		content: @Icon--accessories_vacuum_cleaner;
	}
}

.Icon--central_vaccume_cleaner {
	&:before {
		content: @Icon--central_vaccume_cleaner;
	}
}

.Icon--Handheld_vacuum_cleaner {
	&:before {
		content: @Icon--Handheld_vacuum_cleaner;
	}
}

.Icon--stick_vacuum_cleaner {
	&:before {
		content: @Icon--stick_vacuum_cleaner;
	}
}


.Icon--accessories_appliances {
	&:before {
		content: @Icon--accessories_appliances;
	}
}

.Icon--climate_ac {
	&:before {
		content: @Icon--climate_ac;
	}
}

.Icon--cloth_care {
	&:before {
		content: @Icon--cloth_care;
	}
}

.Icon--cooktops {
	&:before {
		content: @Icon--cooktops;
	}
}

.Icon--dishwasher {
	&:before {
		content: @Icon--dishwasher;
	}
}

.Icon--fridge_freezer {
	&:before {
		content: @Icon--fridge_freezer;
	}
}

.Icon--hood {
	&:before {
		content: @Icon--hood;
	}
}

.Icon--inspiration {
	&:before {
		content: @Icon--inspiration;
	}
}

.Icon--kitchen_appliances {
	&:before {
		content: @Icon--kitchen_appliances;
	}
}

.Icon--laundry_dryer {
	&:before {
		content: @Icon--laundry_dryer;
	}
}

.Icon--microwave_oven {
	&:before {
		content: @Icon--microwave_oven;
	}
}

.Icon--oven {
	&:before {
		content: @Icon--oven;
	}
}

.Icon--pots_pans {
	&:before {
		content: @Icon--pots_pans;
	}
}

.Icon--small_appliances {
	&:before {
		content: @Icon--small_appliances;
	}
}

.Icon--stove {
	&:before {
		content: @Icon--stove;
	}
}

.Icon--utensils {
	&:before {
		content: @Icon--utensils;
	}
}

.Icon--vacuum_cleaner {
	&:before {
		content: @Icon--vacuum_cleaner;
	}
}

.Icon--laundry_dryer2 {
    &:before {
        content: @Icon--laundry_dryer2;
    }
}
//

.Icon--compare {
	&:before {
		content: @Icon--compare;
	}
}

.Icon--bin {
	&:before {
		content: @Icon--bin;
	}
}

.Icon--info {
	&:before {
		content: @Icon--info;
	}
}

.Icon--outofstock {
	&:before {
		content: @Icon--outofstock;
	}
}

.Icon--instock {
	&:before {
		content: @Icon--instock;
	}
}

.Icon--mail {
	&:before {
		content: @Icon--mail;
	}
}

.Icon--quote {
	&:before {
		content: @Icon--quote;
	}
}

.Icon--print {
	&:before {
		content: @Icon--print;
	}
}

.Icon--time {
	&:before {
		content: @Icon--time;
	}
}

.Icon--tv {
	&:before {
		content: @Icon--tv;
	}
}

.Icon--calendar {
	&:before {
		content: @Icon--calendar;
	}

  &--inHeader {
    transform: translateY(2px);
    margin-right: 6px;
  }
}

.Icon--googleplus {
	&:before {
		content: @Icon--googleplus;
	}
}

.Icon--twitter {
	&:before {
		content: @Icon--twitter;
	}
}

.Icon--arrow-left {
	&:before {
		content: @Icon--arrow-left;
	}
}

.Icon--arrow-right {
	&:before {
		content: @Icon--arrow-right;
	}
}

.Icon--download {
	&:before {
		content: @Icon--download;
	}
}

.Icon--filter {
	&:before {
		content: @Icon--filter;
	}
}

.Icon--five {
	&:before {
		content: @Icon--five;
	}
}

.Icon--close {
	&:before {
		content: @Icon--close;
	}
}

.Icon--arrow-down {
	&:before {
		content: @Icon--arrow-down;
	}
}

.Icon--arrow-up {
	&:before {
		content: @Icon--arrow-up;
	}
}

.Icon--clock {
	&:before {
		content: @Icon--clock;
	}
}

.Icon--contact {
	&:before {
		content: @Icon--contact;
	}
}

.Icon--facebook {
	&:before {
		content: @Icon--facebook;
	}
}

.Icon--instagram {
	&:before {
		content: @Icon--instagram;
	}
}

.Icon--pinterest {
	&:before {
		content: @Icon--pinterest;
	}
}

.Icon--youtube {
	&:before {
		content: @Icon--youtube;
	}
}

.Icon--plus {
	&:before {
		content: @Icon--plus;
	}
}

.Icon--minus {
	&:before {
		content: @Icon--minus;
	}
}

.Icon--menu {
	&:before {
		content: @Icon--menu;
	}
}

.Icon--wish-empty {
	&:before {
		content: @Icon--wish-empty;
	}

	@media print {
		display: none;
	}
}

.Icon--wish-active {
	&:before {
		content: @Icon--wish-active;
	}

	@media print {
		display: none;
	}
}

.Icon--head {
	&:before {
		content: @Icon--head;
	}
}

.Icon--delivery {
	&:before {
		content: @Icon--delivery;
	}
}

.Icon--readMore {
	&:before {
		content: @Icon--readMore;
	}
}

.Icon--cart {
	&:before {
		content: @Icon--cart;
	}
}

.Icon--heart-filled {
	&:before {
		content: @Icon--heart-filled;
	}
}

.Icon--location {
	&:before {
		content: @Icon--location;
	}
}

.Icon--lock-closed {
	&:before {
		content: @Icon--lock-closed;
	}
}

.Icon--question {
	&:before {
		content: @Icon--question;
	}
}

.Icon--search {
	&:before {
		content: @Icon--search;
	}
}

.Icon--home {
	&:before {
		content: @Icon--home;
	}
}

.Icon--online {
	&:before {
		content: @Icon--online;
	}
}

.Icon--messenger {
    &:before {
        content: @Icon--messenger;
    }
}

.Icon--whatsapp {
    &:before {
        content: @Icon--whatsapp;
    }
}

.Icon--linkedin {
    &:before {
        content: @Icon--linkedin;
    }
}

.Icon--dryer {
  &:before {
    content: @Icon--dryer;
  }
}

/* END icon classes */
