﻿@import "../../common/variables.less";

.ProductFilter {
    text-align: center;
    margin-bottom: @spaceMedium;

    &-Counter {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        font-size: 12px;
        color: @colorWhite;
        background: @colorOrange;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: @fontFamilyPrimary;
        z-index: 10;
        transition: all .2s ease-in-out;
        margin-left: @spaceSmall;
        aspect-ratio: 1;

        @media @bpLarge {
            width: 21px;
            height: 21px;
            right: -12px;
            font-size: 14px;
            line-height: 1.5em;
        }
    }

    .Button {
        font-size: 15px;
        width: 49%;
        margin-bottom: @spaceSmall;
        justify-content: space-between;

        @media @bpMedium {
            width: auto;
            margin-right: @spaceSmall;
        }
    }

    .ProductFilter-subButtonWrapper {

        .Button {
            justify-content: flex-start;
        }
    }

    .Icon {
        margin-right: @spaceSmall;
    }
}
.ProductFilter-selectWrapper { 
    display: flex;
    justify-content: flex-end;
    padding: 0 @spaceMedium;
}

.Toggler-readMore {
    color: @colorBlueRich;
    display: inline-block;
    padding: 0.2em 0;
    text-decoration: underline;
}