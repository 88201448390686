﻿/*#
Filter
<div style="width:350px; position:relative;">
</div>
*/

@import "../../common/variables.less";

.Filter {
    background: @colorWhite;
    border-radius: 2px;


    &.is-active {
        overflow: visible;
        margin-bottom: @spaceLarge;
        border: 1px solid @colorSilkRich;
    }
}

.Filter-content{
        padding: @spaceMedium;
    .Grid-cell{
        padding-top: 5px!important;
        padding-bottom: 5px!important;

        @media @bpMedium{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.Filter-label{
  margin-bottom:0;
}

.Filter-option {
    border-top: 1px solid @colorSilkRich;
    position: relative;
    color: @colorText;

    label {
        color: @colorText;
    }

    &:hover {
        border-color: @colorSilkMid;
    }
}

.Filter-grid{
    align-items:stretch;
    display: flex;
}
.Filter-cell{
    flex-basis: 20%;
    flex-grow: 1;
    padding: 10px;
}

.FilterDrop-list {
  border-top: 1px solid @colorBlueTint;
  position: absolute;
  background: @colorWhite;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
  border-radius: 1px;
  z-index: 100;
  margin-top: 0;
  display: none;
  width: 100%;
  padding-top: @spaceSmall;
  padding-bottom: @spaceSmall;
  /*border: @spaceSmall solid @colorWhite;*/
  &.is-active {
    display: block;
    overflow-y: auto;
  }

  @media @bpMedium {
    width: 120%;
  }
}
.FilterDropList-item{
    padding-left: @spaceSmall!important;
    padding: @spaceSmall;
    &:first-child{

    }
}

.FilterOption-heading{
    margin: 0;
    cursor: pointer;
    color: @colorText;
    font-family: @fontFamilyPrimary;
    &:after{
            float: right;
            font-size: .5em;
            font-family: HomeIcons;
            content: "\e91a";
            padding-top: .85em;
            padding-right: 2px;
    }

    &.is-active{
        &:after{
            content: "\e91b";   
        }
    }

    @media @bpMedium{
           margin-top: 0.2em;
    }
}

.active-filters{
    margin-top:0;
    margin-bottom: 0;
}

.activeFilters-item{
    cursor: pointer;
    display: block;
    &:before{
         
         font-family: HomeIcons;
         content: "\e919";
         font-size: .8em;
         margin-right: @spaceSmall;
    }

    &:hover{
        background: @colorBlueTint;
    }
}

.Filter-panel{
    border-top: @borderDotted @borderColor;
    padding-top: @spaceSmall;
}

.js-filterOverflow{
    display:none!important;
    &.is-active{
        display: inline-block!important;
    }
}

.Filter-button{
     &:after{
        content:"Filter";
    }

    &.is-active{
          &:after{
        content:"Dölj filter";
    }
    }
}
//

.Icon--filter{
    font-size: @textSizeXSmall;
}

.Filter-clear {
    display: block;
    font-weight: bold;
    cursor: pointer;
    position: relative;

    &:after {
        content: "\e919";
        font-family: HomeIcons;
        margin-right: @spaceXXSmall;
        position: relative;
        top: 1px;
        left: 8px;
    }

    @media @bpMedium {
        display: inline-block;
        top: 1.5px;
        /*margin: 0px 0 0 @spaceLarge;*/
    }

    &:hover {
        color: @colorDarkRed;
    }
}