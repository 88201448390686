.KitchenStepsBlock {
    
    &-infoArea {
        display: flex;
        flex-direction: column;
        margin-bottom: 28px;

        &-buttonGroup {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            
            .KitchenBlockLink;

            a {
                width: 100%;
                margin-top: 16px;
                text-align: center;
            }

            @media @bpMedium {
                flex-direction: row;
                gap: 16px;
                margin-top: 0;
                a {
                    width: fit-content;
                    margin-top: 32px
                }
            }
        }

        @media @bpMedium {
            margin-bottom: 0;
        }
    }

    &-stepsArea {

        &-steps {
            row-gap: 22px;
        }
    }
}