/*#

Component for dividing content into different tab panels on page.
Uses the "tabs" js-module.



*/

@import "../../common/variables.less";

.RangeSlider-slider{
    height: 35px;
    width: 88%;
    left: 7%;
    position: relative;}

.RangeSlider-valuebox{
        margin-bottom: @spaceLarge;
        overflow: hidden;
}
.RangeSlider-value{
    padding: @spaceXSmall;
    border: 1px solid @colorBlueTint;
    text-align:center;
    font-family: @fontFamilyCondensed;
    display: inline-block;
    margin-right:.2em;
    color: @colorText;
}
.RangeSliderValue-type{
    margin-left: .2em;
}
.RangeSliderValue-min{ 
    float: left;
}
.RangeSliderValue-max{
    float: right;
}