.KitchenHeroBlock {
	position: relative;
	overflow: hidden;

	&-mediaContainer {

		img,
		video {
			object-fit: cover;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			min-height: 71dvh;
			max-height: 75dvh;
			min-width: 100%;
			@media @bpSmall {
				max-height: 65dvh;
				min-height: 60dvh;
			}

		}
	}

	&-onPageEditFix img,
	&-onPageEditFix video {
		min-height: auto;
		max-height: auto;
	}

	&-mediaContainer-dark {
		filter: brightness(0.5);
	}

	&-content {
		position: absolute;
		display: flex;
		width: 100%;
		padding: 0 20px;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		h1 {
			text-align: center;
			color: @colorWhite;
			margin-bottom: 32px;
		}

		a {
			font-size: 14px;
			font-weight: 600;
			color: @colorWhite;
			padding: 11px 32px;
			border: 2px solid @colorWhite;

			&:hover {
				border-color: @colorOrange;
				color: @colorOrange;
			}
		}

		@media @bpMedium {
			flex-direction: row;
			padding: 0 110px;

			h1 {
				margin-bottom: 0;
			}
		}
	}

	&-bottomAlign {
		bottom: 50%;
		transform: translateY(50%);

		@media @bpMedium {
			bottom: 58px;
		}
	}

	&-centerAlign {
		top: 50%;
		transform: translateY(-50%);
		flex-direction: column;

		h1 {
			margin-bottom: 32px;
		}
	}

	&-buttonGroup {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@media @bpMedium {
			flex-direction: row;
		}
	}
}
