

@import "../../common/variables.less";

.Favorites-border {
	border: 1px solid lighten(@colorMidGrey, 10%);
	max-width: 100% !important;
	max-height: 100% !important;
	height: 100% !important;
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Favorites-padded {
	padding: @spaceSmall;
}

.Favorites-imageWrapper {
	display: flex;
	justify-content: center;

	img {
		align-self: center;
			position: relative !important;
	}
}

.Favorites-share{
	margin-bottom: @spaceSmall;
	display: block;
	@media @bpMedium{
	position: absolute;
	right: 0;
	margin-bottom: 0;
	}

	.st_email{
		position: relative;
		top:-1px;
	}

}
