﻿/*#

Styling for Specification list.

Examples:

<div style=""max-width:1200px;>
 <div class="Specification u-marginGrid">
        <div class="Grid Grid--gutterHxlg">
            <div class="Grid-cell u-md-size5of8">
                <h4 class="Specification-heading">Mått och installation</h4>
                <dl class="Specification-List List">
                    <dt class="List-item SpecificationList-item">Produktmått H x B x D, mm</dt>
                    <dd class="List-item SpecificationList-item">840 x 595 x 647</dd>
                    <dt class="List-item SpecificationList-item">Dörrhängning</dt>
                    <dd class="List-item SpecificationList-item">Höger och omhängbar</dd>
                    <dt class="List-item SpecificationList-item">Avstånd till sidoväg, mm</dt>
                    <dd class="List-item SpecificationList-item">20</dd>
                    <dt class="List-item SpecificationList-item">Sladdlängd, (ca) m</dt>
                    <dd class="List-item SpecificationList-item">2,4</dd>
                    <dt class="List-item SpecificationList-item">Spänning, V</dt>
                    <dd class="List-item SpecificationList-item">230</dd>
                    <dt class="List-item SpecificationList-item">Total effekt, W</dt>
                    <dd class="List-item SpecificationList-item">120</dd>
                    <dt class="List-item SpecificationList-item">Klimatklass</dt>
                    <dd class="List-item SpecificationList-item">SN-N-ST-T</dd>
                    <dt class="List-item SpecificationList-item">Min. omgivande temperatur, °C</dt>
                    <dd class="List-item SpecificationList-item">10</dd>
                </dl>

                <h4 class="Specification-heading">Energi</h4>
                    <dl class="Specification-List List">
                        <dt class="List-item SpecificationList-item">Energieffektivitetsklass</dt>
                        <dd class="List-item SpecificationList-item">A++</dd>
                        <dt class="List-item SpecificationList-item">Energiförbrukning, kWh per år</dt>
                        <dd class="List-item SpecificationList-item">242</dd>
                    </dl>
               
                <h4 class="Specification-heading">Prestanda</h4>
                <dl class="Specification-List List">
                    <dt class="List-item SpecificationList-item">Förvaringsvolym kyl, liter</dt>
                    <dd class="List-item SpecificationList-item">226</dd>
                    <dt class="List-item SpecificationList-item">Förvaringsvolym frys, liter</dt>
                    <dd class="List-item SpecificationList-item">92</dd>
                </dl>
            </div>

            <div class="Grid-cell u-md-size3of8">
                <h4 class="u-borderBsolid u-borderLarge u-borderDark">Dokumentation</h4>
                <ul class="List">
                    <li class="List-item"><i class="Icon Icon--download u-marginRxsm"></i><a class="u-linkBorder" href="#">Produktinformation</a></li>
                    <li class="List-item"><i class="Icon Icon--download u-marginRxsm"></i><a class="u-linkBorder" href="#">Produktmanual</a></li>
                    <li class="List-item"><i class="Icon Icon--download u-marginRxsm"></i><a class="u-linkBorder" href="#">Informationsblad enligt EU-direktiv</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

*/

@import "../../common/variables.less";

.Specification {
}

.List {
	&.Specification-List {
		margin-top: 0;
		margin-bottom: @spaceXLarge;
		
        .SpecificationList--Full {
			width:100% !important;
		}

		.SpecificationList-item {
			width: 40%;
			margin: 0;
			display: inline-block;
			border-bottom: @borderDotted @borderColor;
			word-break: break-all;
			padding: 0.3125em 0 !important;
			@media @bpMedium {
				word-break: normal;
			}
			@media @bpLarge {
				width: 59%;
			}

            &:nth-child(even) {
                text-align: right;
                padding-right: @spaceSmall !important;

                @media @bpSmall {
                    text-align: initial;
                    padding-right: 0 !important;
                }
            }

			&:nth-child(odd) {
				margin-right: -3px;
				width: 59%;
				@media @bpLarge {
					width: 40%;
				}
			}
		}
	}
}

.Specification-heading {
    border-bottom: @borderDotted @borderColor;
    max-width: 99%;
    margin-bottom: 0;
}

.List {
    &.Specification-List {
        &.SpecificationList--clean {
            .SpecificationList-item {
                border: none;
                vertical-align: top;
            }

            dt.SpecificationList-item {
                width: 30%;
                @media @bpMedium{
                      width: 20%;
                }
            }

            dd.SpecificationList-item {
                width: 65%;
                 @media @bpMedium{
                      width: 60%;
                }
            }
        }
    }
}
