/*#

Allows to create a slider with any type of content.

<div style="max-width:400px;">
    <div class="Slider Slider--full u-marginBottom Slider--pagingBelow owl-carousel owl-theme" data-module="slider" data-slider="itemClass:''">
        <div class="Slider-item">
            <figure class="Figure u-sizeFull">
                <img src="/Assets/Images/Temp/P040673_506339723.png" class="Figure-object" />
            </figure>
        </div>
        <div class="Slider-item">
            <figure class="Figure u-sizeFull">
                <img src="/Assets/Images/Temp/P040225_2060133118.png" class="Figure-object" />
            </figure>
        </div>
        <div class="Slider-item">
            <figure class="Figure u-sizeFull">
                <img src="/Assets/Images/Temp/P040661_755085954.png" class="Figure-object" />
            </figure>
        </div>
    </div>
</div>
*/

@import "../../common/variables.less";


.Slider-wrapper {
    margin-top: @spaceMedium;

    .Teaser {
        height: auto !important;

        @media @bpMedium {
            height: 197px !important;
        }

        @media @bpLarge {
            height: 270px !important;
        }

        @media @bpXLarge {
            height: 340px !important;
        }

        &.Teaser--transform .Teaser-content {
            background: @colorWhiteTransparent;
            padding-left: @spaceMedium;
            padding-right: @spaceMedium;
        }
    }

    .Slider {
        height: auto;

        @media @bpMedium {
            height: auto;
        }

        @media @bpXLarge {
            height: 340px;
            overflow: hidden;
        }
    }
}

.Slider--multi {
    .Slider-item {
        padding: 10px;

        .Card {
            height: 440px;
        }
    }
}

.Slider-item {
    position: relative;

    img {
        display: block;
        height: auto;
        max-width: 60%;
        max-height: 200px;
        margin: auto;

        @media print {
            margin: inherit;
            width: 350px;
           /* height: 350px;*/
     
        }

        @media @bpLarge {
            max-width: 400px;
            max-height: 440px;
        }
    }

    video {
        max-width: 480px;
        width: 100%;
        margin: 10px auto 0;
        display: block;

        @media @bpMedium {
            margin-top: 60px;
            max-width: 575px;
        }

        @media @bpLarge {
            max-width: 480px;
        }

        &.articlevideo {
            margin-top: 0;
            max-width: 100%;
            height: auto;
        }
    }

    .Teaser--plain {
        height: 265px;

        @media @bpLarge {
            height: 413px;
        }

        .Teaser-content {
            line-height: 1.8;
        }
    }

    .Teaser-heading {
        margin-top: 0;
    }

    .Card-prisjakt{
        right: -@spaceMedium;
    }
}

.owl-controls {
    text-align: center;

    @media print {
        display: none !important;
    }

    .owl-page {
        background: @colorMidGrey;
        width: 25px;
        height: 5px;
        display: inline-block;
        margin: 0 .1em;

        @media @bpLarge {
            width: 38px;
            height: 6px;
        }

        &.active {
            background: @colorDimGrey;
        }
    }
}

.Slider--solidArrows {
    .owl-next, .owl-prev {
        background: transparent;

        &:hover {
            background: transparent;

            &:after {
                color: @colorOrange;
            }
        }

        &:after {
            color: @colorSoftBlack;
            font-size: 2rem;
        }
    }
}

    .owl-buttons {
        font-family: HomeIcons;
        position: absolute;
        top: 45%;
        width: 100%;
        font-size: @textSizeXLarge;
    }

    .owl-next, .owl-prev {
        position: absolute;
        background: @colorBlueRich;
        width: 36px;
        height: 36px;
        border-radius: 100%;

        &:after {
            color: @colorWhite;
            font-size: 1rem;
        }

        &:hover {
            background: @colorBlueMid;
        }
    }

    .owl-prev {

        &:after {
            content: "\e920";
            padding-top: 5px;
            display: block;
            position: relative;
            right: 2px;
        }
    }

    .owl-next {
        right: 0;

        &:after {
            content: "\e921";
            padding-top: 5px;
            display: block;
            position: relative;
            left: 2px;
        }
    }

    .Slider--buttonOutside {
        .owl-buttons {
            .owl-next, .owl-prev {
                position: absolute;
                background: rgba(0, 0, 0, 0.6);
                width: 40px;
                height: 40px;
                border-radius: 100%;

                &:after {
                    color: @colorWhite;
                }
            }
        }

        .owl-next {
            right: -10px;
        }

        .owl-prev {
            left: -10px;
        }

        @media all and (min-width: 1380px) {

            .owl-next {
                right: -40px;
            }

            .owl-prev {
                left: -40px;
            }
        }
    }
    // indexPage
    .Slider--full {
        .Slider-item {
            img {
                max-width: 100%;
                height: auto;
                max-height: 440px;
            }

            .campaignCode {
                img {
                    height: 39px;
                }
            }

            .Card-logo {
                width: auto;
                float: left;
                position: relative;
                top: 2px;
            }

            .CardMedia--center {
                img {
                    width: 100%;
                }
            }
        }

        .owl-wrapper {
            line-height: 0;

            .Card {
                line-height: 1.8;
            }
        }

        .owl-pagination {

            @media @bpMedium {
                display: inline-block;
                position: absolute;
                bottom: @spaceDefault;
                // background: rgba(255, 255, 255, 0.5);
                border-radius: 8px;
                padding: 0 8px;
                left: 0;
                right: 0;
            }
        }




        .owl-next, .owl-prev {
            background: rgba(0, 0, 0, 0.6);
            color: @colorTextWhite;
            opacity: 0;
            -webkit-transition: ease-in-out 0.4s;
            -moz-transition: ease-in-out 0.4s;
            -o-transition: ease-in-out 0.4s;
            transition: ease-in-out 0.4s;
        }

        .owl-next {
            right: @spaceSmall;
        }

        .owl-prev {
            left: @spaceSmall;
        }

        &:hover {
            .owl-next, .owl-prev {
                opacity: 1;
            }
        }
    }


    .Slider-item {
        .Teaser {
            &:hover {
                .Teaser-content {
                    transform: translateY(100%) !important;
                    bottom: 2.7em !important;
                }
            }
        }
    }

    .Article {
        .Slider {
            .owl-pagination {
                bottom: 3em;
            }
        }
    }

    .is-no-appearance .Slider--full .owl-pagination {
        left: 46%;
    }

    .is-no-flexbox {
        .Slider-wrapper {
            margin-bottom: 10px;
        }
    }
    /*videos in the slider on article page*/
    .figureWrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
    }

    .iframeResize {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

