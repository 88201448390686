.KitchenMaterialBlock {
    ul, li {
        list-style-type: none;
        margin: 0;
        padding: 8px 0;
    }

    li {
        border-top: 1px solid @colorBlack;

        &:last-child {
            border-bottom: 1px solid @colorBlack;
        }
    }
    
    &-listItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-cta {
        .KitchenBlockLink;
    }
}