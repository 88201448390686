@import (reference) "../common/variables.less";

/*
    Change the default spacing between and inside components.

    Example HTML: 

    <div class="u-marginTlg u-marginBmd"> 			// large margin-top and medium margin-bottom
        <h1 class="u-marginTz">Hello World</h1>		// zero margin-top
    </div>

    Directions:
        A = all
        T = top 
        B = bottom
        R = right
        L = left
        H = horizontal (right and left)
        V = vertical (top and bottom)

    Sizes:
        z  = zero
        xsm = xsmall
        sm  = small
        md  = medium
        lg  = large
        xlg = xlarge
*/

/** 
 * Margins, .u-margin{direction}{size}
 */
 
.u-marginGrid{ 
    margin-bottom: @spaceMedium!important; 
    @media @bpLarge{
    margin-bottom: @spaceLarge!important; }
} 

// zero margins
.u-marginAz { margin: 0 !important; }
.u-marginTz { margin-top: 0 !important; }
.u-marginBz { margin-bottom: 0 !important; }
.u-marginRz { margin-right: 0 !important; }
.u-marginLz { margin-left: 0 !important; }
.u-marginHz { margin-left: 0 !important; margin-right: 0 !important; }
.u-marginVz { margin-top: 0 !important; margin-bottom: 0 !important; }

// auto margins
.u-marginAa { margin: auto !important; }
.u-marginTa { margin-top: auto !important; }
.u-marginBa { margin-bottom: auto !important; }
.u-marginRa { margin-right: auto !important; }
.u-marginLa { margin-left: auto !important; }
.u-marginHa { margin-left: auto !important; margin-right: auto !important; }
.u-marginVa { margin-top: auto !important; margin-bottom: auto !important; }

.u-space(~"marginA", margin);           // .u-marginA{size}
.u-spaceHorizontal(~"marginH", margin); // .u-marginH{size}
.u-spaceVertical(~"marginV", margin);   // .u-marginV{size}
.u-space(~"marginT", margin-top);       // .u-marginT{size}
.u-space(~"marginB", margin-bottom);    // .u-marginB{size}
.u-space(~"marginR", margin-right);     // .u-marginR{size}
.u-space(~"marginL", margin-left);      // .u-marginL{size}

.u-marginTNxsm{ margin-top: -0.2em!important}
.u-marginTNsm{ margin-top: -0.5em!important}
.u-marginTNmd{ margin-top: -0.8em!important}
.u-marginTNlg{ margin-top: -1.2em!important}
.u-marginTxxlg{ margin-top:5em;}
.u-marginBxxlg{ margin-bottom:5em;}
/** 
 * Paddings, .u-padding{direction}{size}
 */

// zero paddings
.u-paddingAz { padding: 0 !important; }
.u-paddingTz { padding-top: 0 !important; }
.u-paddingBz { padding-bottom: 0 !important; }
.u-paddingRz { padding-right: 0 !important; }
.u-paddingLz { padding-left: 0 !important; }
.u-paddingHz { padding-left: 0 !important; padding-right: 0 !important; }
.u-paddingVz { padding-top: 0 !important; padding-bottom: 0 !important; }

.u-space(~"paddingA", padding);             // .u-paddingA{size}
.u-spaceHorizontal(~"paddingH", padding);   // .u-paddingH{size}
.u-spaceVertical(~"paddingV", padding);     // .u-paddingV{size}
.u-space(~"paddingT", padding-top);         // .u-paddingT{size}
.u-space(~"paddingB", padding-bottom);      // .u-paddingB{size}
.u-space(~"paddingR", padding-right);       // .u-paddingR{size}
.u-space(~"paddingL", padding-left);        // .u-paddingL{size}

/** 
 * Collapsing margins
 * Negative margins to pull content beyond paddings, using e.g. margin-top: -1em;
 */
.u-spaceHorizontal(~"pullH", margin, -1); // horizontal (left & right), .u-pullH{size}
.u-spaceVertical(~"pullV", margin, -1);   // vertical (top & bottom),   .u-pullV{size}

// pull top
.u-pullTxxsm { margin-top: -@spaceXXSmall; }
.u-pullTxsm { margin-top: -@spaceXSmall; }
.u-pullTsm {  margin-top: -@spaceSmall;  }
.u-pullTmd, 
.u-pullT {    margin-top: -@spaceMedium; }
.u-pullTlg {  margin-top: -@spaceLarge;  }
.u-pullTxlg { margin-top: -@spaceXLarge; }


//mobile specific 

.u-md-paddingRsm{
    @media @bpMedium{
        padding-right: @spaceSmall;
    }
}

.u-paddingAxlg {
    padding: 32px;

    @media @bpMedium {
        padding: 50px!important;
    }
}
/**
 * Mixins used to generate classes.
 */
.u-space(@className, @property: margin, @multiplier: 1) { 
		.u-@{className}xxsm{
			@{property}: @spaceXXSmall * @multiplier !important; 
		}
    .u-@{className}xsm {
        @{property}: @spaceXSmall * @multiplier !important; 
    }
    .u-@{className}sm { 
        @{property}: @spaceSmall * @multiplier !important; 
    }
    .u-@{className}md,
    .u-@{className} { 
        @{property}: @spaceMedium * @multiplier !important; 
    }
    .u-@{className}lg { 
        @{property}: @spaceLarge * @multiplier !important; 
    }
    .u-@{className}xlg { 
        @{property}: @spaceXLarge * @multiplier !important; 
    }
}

.u-spaceHorizontal(@className, @property: margin, @multiplier: 1) { 
    .u-@{className}xsm {
        @{property}-left: @spaceXSmall * @multiplier !important;
        @{property}-right: @spaceXSmall * @multiplier !important; 
    }
    .u-@{className}sm { 
        @{property}-left: @spaceSmall * @multiplier !important;
        @{property}-right: @spaceSmall * @multiplier !important; 
    }
    .u-@{className}md, 
    .u-@{className} { 
        @{property}-left: @spaceMedium * @multiplier !important;
        @{property}-right: @spaceMedium * @multiplier !important;
    }
    .u-@{className}lg { 
        @{property}-left: @spaceLarge * @multiplier !important;
        @{property}-right: @spaceLarge * @multiplier !important;
    }
    .u-@{className}xlg {
        @{property}-left: @spaceXLarge * @multiplier !important;
        @{property}-right: @spaceXLarge * @multiplier !important;
    }
}

.u-spaceVertical(@className, @property: margin, @multiplier: 1) { 
    .u-@{className}xsm {
        @{property}-top: @spaceXSmall * @multiplier !important;
        @{property}-bottom: @spaceXSmall * @multiplier !important;  
    }
    .u-@{className}sm { 
        @{property}-top: @spaceSmall * @multiplier !important;
        @{property}-bottom: @spaceSmall * @multiplier !important; 
    }
    .u-@{className}md,
    .u-@{className} { 
        @{property}-top: @spaceMedium * @multiplier !important;
        @{property}-bottom: @spaceMedium * @multiplier !important; 
    }
    .u-@{className}lg { 
        @{property}-top: @spaceLarge * @multiplier !important;
        @{property}-bottom: @spaceLarge * @multiplier !important; 
    }
    .u-@{className}xlg {
        @{property}-top: @spaceXLarge * @multiplier !important;
        @{property}-bottom: @spaceXLarge * @multiplier !important; 
    }
}

// For mobile devices

@media only screen and (max-width:400px) {
    //margins
    
    //paddings

      .u-sd-paddingVz{
          padding-top:0!important;
          padding-bottom:0!important;
      }

        .u-sd-paddingTz{
          padding-top:0!important;
      }

          .u-sd-paddingBz{
          padding-bottom:0!important;
      }

    .u-sd-paddingVsm{
          padding-top:5px!important;
          padding-bottom:5px!important;
    }

    .u-sd-paddingHz{
          padding-left:0!important;
          padding-right:0!important;
    }

		.u-sd-marginBz{
			margin-bottom:0!important;
		}

		.u-sd-paddingBmd{
			padding-bottom:@spaceMedium;
		}

    .u-sd-paddingHsm {
        padding-left: 1rem!important;
        padding-right: 1rem !important;
    }
}

/*reduced bottom margin for kitchen page sentensgrouppages*/

.reduced-margin {
    margin-bottom: 0.75em !important;
}