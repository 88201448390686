﻿/*#

  


*/

@import "../../common/variables.less";

.SEOtext {

    .Grid-cell {


        @media @bpMedium {
            padding-top: inherit;
            padding-bottom: 0 !important;
        }

        p {
            margin-bottom: 0;

            @media @bpMedium {
                margin-bottom: 1em;
            }
        }

        h3, h2 {
            font-size: 1.2em;
            line-height: 1.25em;
            margin-top: 1em;

            @media @bpMedium {
                margin-top: 0;
            }

            @media @bpLarge {
                font-size: 22/16em;
                line-height: 1.18em;
            }
        }
    }
}
