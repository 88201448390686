
.TextToggler {
    display: block;

    .TextToggler-primery {
        display: inline-block;
    }

    .TextToggler-secondary {
        display: none;
    }

    &.is-active {
        .TextToggler-primery {
            display: none;
        }

        .TextToggler-secondary {
            display: inline-block;
        }
    }
}

