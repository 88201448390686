/*#

Ajaxloader

Examples:
<div class="ajaxLoader" style="display:block; position: relative; margin:0; max-width:150px; left:0; top: 0; position: relative;">
	<img src="/Assets/Images/loader.gif" alt="Laddar..." />
</div>
*/

@import "../../common/variables.less";

	.ajaxLoader {
		display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 99999;
		background: rgba(255,255,255,0.85);
		border: 1px solid  @colorMidGrey;
		border-radius: 5px;
		margin-left: -100px;
		margin-top: -100px;
	}