/*#

Ajaxloader

Examples:

*/

@import "../../common/variables.less";

	.Centertext{
		position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
	}

    .CenterText-content{
        font-size: @textSizeLarge;
        font-family: @fontFamilyCondensed;
        color: @colorWhite;
        background: @colorSoftBlack;
        border-radius: 28px;
        padding: @spaceSmall @spaceMedium;

        &:hover{
            background: @colorSilkLight;
        }

    }