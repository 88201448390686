@import "../../../../assets/Styles/common/variables.less";
@import "../../../../assets/Styles/common/mixins.less";

.MiniCartV2 {
    display: flex;
    flex-direction: column;
    background: @colorGrey;
    z-index: 990;
    width: 100%;
    -webkit-box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
    margin-left: -@spaceLarge;
    right: -100vw;
    top: 0; 
    position: fixed; 
    min-height: 100dvh; 
    max-height: 100dvh;
    padding-inline: 20px;
    
    -webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -ms-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    
    hr {
        margin-block: 14px;
        color: #d6d6d7;
    }

    .Heading {
        color: #333;
    }

    .ArticleNumber {
        font-size: 13px;
    }

    @media @bpMedium {
        width: 375px;
        right: -375px;
        margin-left: 0;
    }
    
    &.is-open {
        right: 0;
    }

    
    &.is-active {
        max-height: 95vh;
        overflow: auto;

        @media @bpMedium {
            overflow: hidden;
        }
    }

    .CheckoutItem-text {
        font-family: @fontFamilyPrimary;
        font-size: 14px;
    }

    .MiniCart-headings {
        margin-bottom: 32px;
        
        a {
            &:last-of-type {
                margin-left: 32px;
            }
            h3 {
                &.is-active {
                    border-bottom: 3px solid @colorBlack;
                    padding-bottom: 8px;
                }
            }
        }
    }

    .MiniCart-header,
    .MiniCart-headings {
        display: flex;
        align-items: flex-start;
    }

    .MiniCart-header {
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 20px;

    }

    .PickupStore-info {
        margin-bottom: 6px;
    }

    .Pickup-Store {
        display: inline-block;
    }

    

    .SummaryImage {
        
        padding-right: 15px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        
        img {   
            mix-blend-mode: multiply;
            filter: contrast(1);
            height: 56px;       
        }
    }

    .Summary-numberOfArticlesWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 6px;

        a {
            text-decoration: none;
            display: flex;
            align-items: center
        }
    
        .Icon {
            margin: 0;
        }
    
        .Summary-numberOfArticles {
            margin-inline: 10px;
        }
    }

    .MiniCart-close {
        padding: 16px;
        position: relative;
        bottom: 16px;
        left: 16px;
    }

    .MiniCart-head {
        border-bottom: 1px solid @borderColor;
    }

    .MiniCart-content {
        padding: @spaceSmall @spaceSmall 0;
    }

    .MiniCart-newItem {
        text-align: center;
        background: @colorOrange;
        font-family: @fontFamilyHeading;
        margin: -0.83333333em -0.83333333em 0;
        display: none;
        color: @colorWhite;

        span {
            display: block;
            padding: .4em 0;
        }
    }

    .is-opend {
        .MiniCart-newItem {
            display: none !important;
            height: 0;
        }
    }


    .is-newItem {
        .MiniCart-newItem {
            display: block;
        }
    }

    .MiniCart-lineItems {
        overflow-y: auto;
        padding-right: 20px;
    }

    .MiniCart-price {
        font-size: @textSizeMedium;
    }

    .MiniCart-shipping {
        padding: .4em 0
    }

    .MiniCart-relatedProducts {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 12px 0 16px 0;

        div > .owl-buttons {
            top: -42px;
            display: none;

            @media @bpMedium {
                display: block;
            }
    
            .owl-prev {
                right: 18px;
            }

            .owl-prev,
            .owl-next {
                background: none;

                &:after {
                    color: @colorBlueRich;
                }
            }
        }
    }

    .MiniCart-relatedProduct {
        background-color: @colorWhite;
        display: flex;
        height: 88px;
        //width: 300px !important;

        .MiniCart-relatedProduct-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: space-between;
            padding: 12px;
            
            > p {
                margin: 0 0 8px;
            }
    
            > span {
                line-height: normal;
                margin: 0;
            }
        }
    
        .MiniCart-relatedProduct-info {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            min-width: 100%;
    
            p {
                margin: 0;
            }
        }
        
        .MiniCart-relatedProduct-image {
             display: flex;
             align-items: center;
             justify-content: center;
             img {
                width: 64px;
                height: 64px;
             }
        }
    }

    .MiniCart-paymentAlt {
        display: inline;
    }

    .MiniCart-paymentAltList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 4px;
        padding-inline: 0;
        margin: 16px 0 20px;
    }

    .MiniCart-sum {
        > span {
            font-size: @textSizeSmall;
        }
    }
    .MiniCart-sumTotal {
        margin-bottom: 1rem;
    }

    .MiniCart-footer {
        background-color: initial;
        margin-top: auto;

        > span {
            line-height: inherit;
            color: inherit;
        }
    }
    /*
    .Header {
        &.Sticky-element {
            .MiniCart-position {
                right: 0;
                margin-right: 0;

                @media @bpMedium {
                    position: absolute;
                }
            }

            .MiniCart {
                position: absolute;
                top: -51px;
                right: 0;
            }

            &.is-stickyHidden {
                .MiniCart {
                    top: 0px;
                    right: 0;
                }
            }
        }
    }

    .Header {
        .MiniCart-position {
            @media @bpMedium {
                position: absolute;
                right: 0;
                margin-right: 395px;
            }
        }

        .MiniCart {
            position: fixed;
            top: 0px;

            @media @bpMedium {
                .ProductNav-is-sticky & {
                    top: 46px;
                }

                .Header-is-visible & {
                    position: absolute;
                    top: -49px;
                }
            }

            @media @bpLarge {
                .ProductNav-is-sticky & {
                    top: 50px;
                }

                .Header-is-visible & {
                    top: -51px;
                }
            }
        }
    }
    */
}

