﻿.KitchenLoanBlock {
	table {
		border: none;

		th, td {
			border: none;
			border-top: solid 1px;
		}

		tr:nth-of-type(1) td {
			font-weight: bold !important;
			border-top:none!important;
		}
	}
}
