﻿/*#

Component displaying a navigation breadcrumb.

Examples:

<div>
    <div class="Breadcrumb u-hidden u-lg-block">
        <ol class="Breadcrumb-list">
            <li class="Breadcrumb-item"><a href="#" class="Breadcrumb-itemLink">Home</a></li>
            <li class="Breadcrumb-item"><a href="#" class="Breadcrumb-itemLink">Dammsugare</a></li>
            <li class="Breadcrumb-item"><span>Golvdammsugare</span></li>
        </ol>
    </div>
</div>

*/

@import "../../common/variables.less";

@Breadcrumb-textColor: @colorText;
@Breadcrumb-padding: 0.2em;
.Breadcrumb {
    color: @Breadcrumb-textColor;
    font-size: 14/16em;
    border-bottom: @borderDotted @borderColor;
    margin-bottom: @spaceSmall;
    @media @bpLarge{
        margin: @Breadcrumb-padding 0 @spaceLarge;
        margin-left: 0;
    }

}

.Breadcrumb-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Breadcrumb-item {

display: none;
    &:nth-child(n+2) {
    display: inline-block; 
}

    margin-right: 0.4em;

    
    &:after {
        content: '»';
        margin-left: 0.6em;
    }
    
    &:last-child {
        margin-right: 0;
        
        &:after {
            content: none;
        }
    }

    @media @bpMedium{
            display: inline-block;
    }

}

.Breadcrumb-itemLink {
    color: @Breadcrumb-textColor;
    display: inline-block;
    padding: @Breadcrumb-padding 0;
    text-decoration: underline;
    &:hover{
        text-decoration:none;
    }
}

.Breadcrumb-transparent {
    position: absolute;
    width: 100%;
    z-index: 1;
    margin: 124px auto 0;
    color: @colorWhite;
    border-bottom: none;

    a {
        color: @colorWhite;
    }
}