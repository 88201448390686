﻿/*#

Display a cookie consent dialog to let users know that this website is using cookies.

Examples:

<div>
  <div class="CookieConsent CookieConsent--bottom">
        <div class="CookieConsent-dialog">
            <div class="Page-container">
                <div class="Grid Grid--gutterA">
                    <div class="Grid-cell u-size9of12">
                        <span class="CookieConsent-content">
                            <span class="CookieConsent-message">Denna webbsida använder cookies. Genom att fortsätta att surfa på webbplatsen utan att ändra din webbläsares cookie-inställningar samtycker du till vår användning av cookies. </span>
                            <a href="#" class="CookieConsent-learnMore">Ta reda på mer genom att läsa våra cookie-riktlinjer.</a>
                        </span>
                    </div>
                    <div class="Grid-cell u-size3of12">
                        <button class="Button CookieConsent-accept u-pullRight">Jag förstår</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

*/

@import "../../common/variables.less";


@CookieConsent-color: inherit;
@CookieConsent-padding: @spaceMedium;
@CookieConsent-shadow: 0 0 8px rgba(0,0,0,0.15);
@CookieConsent-topHeight: 60/16rem;
@CookieConsent-bottomRightWidth: 22em;

.CookieConsent {
    position: relative;
    z-index: 1000;
}

.CookieConsent-dialog {
    color: @CookieConsent-color;
    display: flex;
    align-items: center;
    background: @colorBlueTint;
    padding: @CookieConsent-padding;
    font-size: @textSizeXSmall;
    box-shadow: @CookieConsent-shadow;
    
    @media @bpSmall {
        font-size: @textSizeSmall;
    }
  @media @bpLarge {
        font-size: @textSizeMedium;
    }

  a{
      border-bottom: @borderDotted @borderColor;
  }

}

.CookieConsent-content {
    flex: 1 1 auto;
    line-height: 1.4em;
}

.CookieConsent-dismiss {
    white-space: nowrap;
}

.CookieConsent--top {
    height: @CookieConsent-topHeight;
    margin-top: -@CookieConsent-topHeight;
    transition: margin-top .8s ease-in-out;
    
    &.is-active {
        margin-top: 0;
        
        .CookieConsent-dialog {
            transform: translateY(0);
            box-shadow: @CookieConsent-shadow;
        }
    }
    
    .CookieConsent-dialog {
        height: @CookieConsent-topHeight;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        box-shadow: none;
        
        transition: .8s ease-in-out;
        transition-property: transform, box-shadow;
        
        @media @bpSmall {
            position: fixed;
            transform: translateY(-100%);
        }
    }
}

.CookieConsent--bottom, 
.CookieConsent--bottomRight {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(120%);
    transition: transform .8s ease-in-out;

    &.is-active {
        transform: translateY(0);        
    }
}

.CookieConsent--bottomRight {
    @media @bpSmall {
        left: inherit;
        right: @CookieConsent-padding;
        bottom: @CookieConsent-padding;
        
        .CookieConsent-dialog {
            max-width: @CookieConsent-bottomRightWidth;
            flex-direction: column;
        }
        
        .CookieConsent-content {
            display: block;
            margin-bottom: 1em;
            padding-right: 0;
        }
        
        .CookieConsent-dismiss {
            width: 100%;
        }
    }
}