﻿@import "../../common/variables.less";

/*#
  **Core grid component**
 
  The Grid-cell should be combined with a utils-size class for the specific breakpoint you want to target.
  
  DO NOT apply dimension or offset utilities to the `Grid` element. All cell
  widths and offsets should be applied to child grid cells.
  
  **Example uses:**
 
  <div class="Grid Grid--withGutter [Grid--alignCenter|Grid--alignRight|Grid--withGutter]">
    <div class="Grid-cell [Grid-cell--center] u-xsm-size1of2">1of2</div>
    <div class="Grid-cell u-xsm-size1of2">1of2</div>
    <div class="Grid-cell u-xsm-size1of3">1of3</div>
    <div class="Grid-cell u-xsm-size2of3">2of3</div>
  </div>
 */

@Grid-gutterXsm: unit(@spaceXSmall * @baseFontSize, px);
@Grid-gutterSm:  unit(@spaceSmall  * @baseFontSize, px);
@Grid-gutterMd:  unit(@spaceMedium * @baseFontSize, px);
@Grid-gutterLg:  unit(@spaceLarge  * @baseFontSize, px);
@Grid-gutterXlg: unit(@spaceXLarge * @baseFontSize, px);

@Grid-gutterDefault: @Grid-gutterMd;

.Grid {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.Grid-reverse {
	@media @bpMedium {
		flex-direction: row-reverse !important;
	}
}

.Grid-cell {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    box-sizing: inherit;
    min-width: 0; // Test of fix for widths inside flex containers (2016-04-14)
}

.Grid-cell--fit {
    -webkit-flex: 0 0 auto !important;
    -ms-flex: 0 0 auto !important;
    flex: 0 0 auto !important;
}

.Grid-cell--center {
    display: block !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

.Grid--alignCenter {
    justify-content: center;
}

.Grid--alignRight {
    justify-content: flex-end;
}

.Grid--alignMiddle {
    align-items: center;
}

.Grid--alignBottom {
    align-items: flex-end;
}

.Grid-sm--spaceBetween {
    justify-content: space-between;
    @media @bpMedium {
        justify-content: initial;
    }
}

.Grid--fit > .Grid-cell {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
}

.Grid--equalHeight > .Grid-cell {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

/** 
 * Browser fallback for Flexible Box Layout. 
 * Can also be forced using .Grid--simple. 
 */
 
.is-no-flexbox .Grid,
.Grid--simple {
    display: block;
    font-size: 0;
    margin: 0;
    padding: 0;
    text-align: left;
    
    & > .Grid-cell, u-md-flex {
        flex: none;
        box-sizing: border-box;
        display: inline-block;
        font-size: 16px;
        font-size: 1rem;
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
        text-align: left;
        vertical-align: top;
        width: 100%;
    }
    
    &.Grid--alignCenter {
        text-align: center;
    }
    
    &.Grid--alignRight {
        text-align: right;
    }
    
    &.Grid--alignMiddle > .Grid-cell {
        vertical-align: middle;
    }
    
    &.Grid--alignBottom > .Grid-cell {
        vertical-align: bottom;
    }
    
    &.Grid--fit {
        display: table;       
        table-layout: fixed;
        width: 100%;
        margin: 0 !important;
        
        & > .Grid-cell {
            display: table-cell;
            width: auto;
            
            &:first-child {
                padding-left: 0;
            }
            
            &:last-child {
                padding-right: 0;
            }
        }
    }
}

/**
 * Grid Gutter Sizes
 * Wrap in .Grid to increase specificity. 
 * All gutters are in Pixels to support both Flexbox and Simple mode.
 * 
 * Format: Grid--gutter[direction:A|V|H][size:xsm|sm|md|lg|xlg]
 * Examples: Grid--gutterA (All), Grid--gutterH (Horizontal), Grid--gutterHlg (large horizontal gutter)
 * 
 * Gutter directions:
 *	  A = all (right, left, top, bottom)
 *    H = horizontal (right, left)
 *    V = vertical (top, bottom)
 *	
 * Gutter sizes:
 *    xs = xsmall
 *	  s  = small
 *	  m  = medium
 *	  l  = large
 *	  xl = xlarge
 */
 
.Grid {
    /**
     * Horizontal gutters (left & right)
     */
    .Grid--gutterHorizontal(@gutter) {
        margin-left: (-0.5 * @gutter);
        margin-right: (-0.5 * @gutter);
        
        & > .Grid-cell {
            padding-left: (0.5 * @gutter);
            padding-right: (0.5 * @gutter);
        }
    }
    
    // Gutter Horizontal Default    
    &.Grid--gutterH, &.Grid--gutterA { 
        .Grid--gutterHorizontal(@Grid-gutterDefault);
    }
    
    // Gutter Horizontal  XSmall
    &.Grid--gutterHxsm, &.Grid--gutterAxsm { 
        .Grid--gutterHorizontal(@Grid-gutterXsm);
    }
    
    // Gutter Horizontal  Small
    &.Grid--gutterHsm, &.Grid--gutterAsm { 
        .Grid--gutterHorizontal(@Grid-gutterSm);
    }
    
    // Gutter Horizontal  Medium
    &.Grid--gutterHmd, &.Grid--gutterAmd { 
        .Grid--gutterHorizontal(@Grid-gutterMd);
    }
    
    // Gutter Horizontal  Large
    &.Grid--gutterHlg, &.Grid--gutterAlg { 
        .Grid--gutterHorizontal(@Grid-gutterLg);
    }
    
    // Gutter Horizontal  XLarge
    &.Grid--gutterHxlg, &.Grid--gutterAxlg { 
        @media @bpMedium{
            .Grid--gutterHorizontal(@Grid-gutterXlg);
        }
    }
    
    /**
     * Vertical gutters (top & bottom)
     */
    .Grid--gutterVertical(@gutter) {
        margin-top: (-0.5 * @gutter);
        margin-bottom: (-0.5 * @gutter);
        
        & > .Grid-cell {
            padding-top: (0.5 * @gutter);
            padding-bottom: (0.5 * @gutter);
        }
    }
    
    // Gutter Vertical Default
    &.Grid--gutterV, &.Grid--gutterA {
        .Grid--gutterVertical(@Grid-gutterDefault);
    }
    
    // Gutter Vertical XSmall
    &.Grid--gutterVxsm, &.Grid--gutterAxsm {
        .Grid--gutterVertical(@Grid-gutterXsm);
    }
    
    // Gutter Vertical Small
    &.Grid--gutterVsm, &.Grid--gutterAsm {
        .Grid--gutterVertical(@Grid-gutterSm);
    }
    
    // Gutter Vertical Medium
    &.Grid--gutterVmd, &.Grid--gutterAmd {
        .Grid--gutterVertical(@Grid-gutterMd);
    }
    
    // Gutter Vertical Large
    &.Grid--gutterVlg, &.Grid--gutterAlg {
        .Grid--gutterVertical(@Grid-gutterLg);
    }
    
    // Gutter Vertical XLarge
    &.Grid--gutterVxlg, &.Grid--gutterAxlg {
        .Grid--gutterVertical(@Grid-gutterXlg);
    }
}

//fix for storeblock on article page

.Grid.Grid--gutterA.u-marginBsm.storeblock {
    font-size:14px;
}

.Grid.Grid--gutterA.u-marginBsm.storeblock .StoreInfo-content {
    padding-bottom: 0px;
}

.Grid.Grid--gutterA.u-marginBsm.storeblock .StoreInfoList--bar {
    padding-left: 0!important;
}

@media screen and (min-width: 960px) and (max-width: 1244px){
    .Grid.Grid--gutterA.u-marginBsm.storeblock .StoreInfoList--bar {
        margin-bottom: 50px;
    }

}
    .Grid.Grid--gutterA.u-marginBsm.storeblock .StoreInfo-content .StoreInfoList-item {
        margin: 6px 0 0 6px !important;
        padding: 0 5px 0 5px;
    }

    .Grid.Grid--gutterA.u-marginBsm.storeblock .StoreInfo-content .StoreInfoList-item:first-child {
        margin: 0 !important;
    }

    .Grid.Grid--gutterA.u-marginBsm.storeblock .StoreInfoChange--bar {
        top: auto;
    }

    @media screen and (min-width: 960px) {
        .Grid.Grid--gutterA.u-marginBsm.storeblock .Select-input {
            width: 150px;
        }
    }


    .Grid.Grid--gutterA.u-marginBsm.storeblock .u-marginBz {
        margin-top: 8px;
    }
