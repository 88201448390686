﻿/*#

The Media component is used for splitting content into a fixed width column and a fluid column that works nicely with for different screen widths.

Based on the Flag object by CSS Wizardry, but renamed to Media because it's more common name for this layout.
http://csswizardry.com/2013/05/the-flag-object/

<div style="max-width:300px;">
    <div class="Media Media--top">
        <div class="Media-side">
            <img src="/Assets/Images/Temp/dog.jpg" width="70" height="40" />
        </div>
        <div class="Media-body">
            <p>Avstitt 1 Familjen Linds resa mot drömköket</p>
        </div>
    </div>

    <div class="Media Media--top">
        <div class="Media-side">
            <img src="/Assets/Images/Temp/header_pannkaka.jpg" width="70" height="40" />
        </div>
        <div class="Media-body">
            <p>Avstitt 2 Familjen Linds resa mot drömköket</p>
        </div>
    </div>
</div>
*/

@import "../../common/variables";

@Media-gutter: @spaceMedium;

.Media {
    display: table;
    width: 100%;
}

.Media-side,
.Media-body {
    display: table-cell;
    vertical-align: middle;

    .Media--top & {
        vertical-align: top;
    }

    .Media--bottom & {
        vertical-align: bottom;
    }
}

.Media--right {
    .Media-side {
        display: table-cell;
    }
}

.Media-side {
    padding-right: @Media-gutter;

    > img {
        display: block;
        max-width: none;
    }

    .Media--right & {
        padding-right: 0;
        padding-left: @Media-gutter;
    }  

    &.MediaSide--small{
        padding-right: @spaceXSmall;
    }

}

.Media-body {
    width: 100%;
    
    .Media--equal & {
        width: auto;
    }
}