.StoreSelector {
	-webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-ms-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);

	-webkit-box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
	-moz-box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);
	box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.4);

	display: flex;
	flex-direction: column;
	position: fixed;
	width: 100%;
	min-height: 100dvh;
	max-height: 100dvh;
	top: 0;
	right: -100vw;
	z-index: 100;
	background-color: @colorGrey;
	padding-inline: 20px;

	@media @bpMedium {
		width: 375px;
		right: -375px;
		margin-left: 0;
	}

	h3 {
		font-size: 22px;
	}

	h5 {
		text-transform: none;
		font-weight: 600;
	}

	&--is-open {
		right: 0;
	}

	&-header {
		display: flex;
		margin: 48px 0 0;
		justify-content: space-between;
		align-items: flex-start;
	}

	&-close {
		cursor: pointer;
		padding: 16px;
		position: relative;
		bottom: 16px;
		left: 16px;
	}

	&-searchField {
		position: relative;

		.StoreSelector-input,
		input {
			height: 40px;
			padding-left: 26px;
			background-color: @colorWhite !important;
		}

		.StoreSelector-input--grey {
			background-color: @colorGrey !important;
		}

		&::before {
			font-family: 'HomeIcons';
			content: url(/Assets/Images/Icons/gps.svg);
			;
			position: absolute;
			top: 6px;
			left: 12px;
			font-size: 18px;
		}

		&::after {
			font-family: 'HomeIcons';
			content: "\e93f";
			position: absolute;
			top: 6px;
			right: 12px;
			font-size: 18px;
		}
	}

	&-card {

		&--withStoreLink {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		border-top: 1px solid @colorMidGrey;
	}

	&-card,
	&-selectedCard {
		padding: 12px;

		strong {
			font-size: 15px;
			font-weight: 600;
		}

		span {
			font-size: 14px;
		}
	}

	&-selectedCard {
		background-color: @colorWhite;
	}

	&-storeCards {
		overflow-y: auto;
	}

	&-card,
	&-selectedCard,
	&-cardLink,
	&-storeCard {
		cursor: pointer;
		-webkit-transition: all 600ms ease-out;
		-moz-transition: all 600ms ease-out;
		-ms-transition: all 600ms ease-out;
		-o-transition: all 600ms ease-out;
		transition: all 600ms ease-out;

		&:hover {
			background-color: @colorWhite;
		}
	}

	&-cardLink {
		opacity: .6;
	}

	&-cardLink:hover {
		opacity: 1;
	}
}
