﻿/*#

Styling for lead text in an article.

Examples:

<div>
    <div class="Lead">
        <p>Lorem ipsum dolor sit amet.</p>
    </div>    
</div>

*/

@import "../../common/variables.less";

.Lead {
    p{
        font-size: @textSizeLarge;
            line-height:1.4;
    @media @bpMedium{
        font-size: @textSizeXLarge;
        line-height:1.4;
        }
    }
}

aside{
    .Lead {
    p{
    font-size: @textSizeMedium;
    line-height:1.4;
    }
}
}