﻿.u-posAbsolute {
    position: absolute !important;
}

/**
 * Pins to all corners by default. But when a width and/or height are
 * provided, the element will be centered in its nearest relatively-positioned
 * ancestor.
 */

.u-posAbsoluteCenter {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
}

.u-posAbsoluteRight{
	right:0;
}
/**
 * 1. Make sure fixed elements are promoted into a new layer, for performance
 *    reasons.
 */

.u-posFixed {
    position: fixed !important;
    backface-visibility: hidden; /* 1 */
}

.u-posRelative {
  position: relative !important;
}

.u-posStatic {
  position: static !important;
}

 .u-flexEnd{
      align-self: flex-end!important;
 } 
 
 .u-flexStart {
     align-self: flex-start !important;
 }

  .u-alignselfCenter{
      align-items: center!important;
 } 

.u-flexAuto{
    flex:auto!important;
    -webkit-flex: auto!important;
    -ms-flex-: auto!important;
}

.u-justifySB {
    justify-content: space-between;
}



.u-zindexSm {
    position: relative;
    z-index: 1;
}