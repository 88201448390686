@import "../../common/variables.less";

.Checkout {
}

.Checkout-head {
    border-bottom: 1px solid @borderColor;
}

.Checkout-Padding {
    @media @bpMedium {
        padding: 0 @spaceMedium;
    }
}

.CheckoutHead-heading {
    margin-bottom: 0;
}

.Checkout-paymentImage {
    margin-left: @spaceXXSmall;
    margin-bottom: @spaceXXXXSmall;
}

.Checkout-paymentInfo{
  position:relative;
  top:3px;
}

.Checkout-item {
    border-bottom: 2px solid @borderColor;
    margin-bottom: @spaceMedium;

    @media @bpMedium {
        padding: @spaceMedium;
        margin-bottom: 0;
    }

    &:last-child {
        border: none;
    }
}

.CheckoutItem-text {
    font-family: @fontFamilyHeading;
}

.Checkout-remove {
    cursor: pointer;
    opacity: 1;

    &:hover {
        opacity: 0.6;
    }
}

.CheckoutList-fade {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 2.8em;

    &.is-active {
        display: none;
    }

    @media @bpMedium {
        height: 2em;
        /*display: none;*/
    }
}

.CheckoutList-showHide {
    max-height: 4.5em;
    overflow: hidden;
    -webkit-transition: max-height ease-in-out 0.2s;
    -moz-transition: max-height ease-in-out 0.2s;
    -o-transition: max-height ease-in-out 0.2s;
    transition: max-height ease-in-out 0.2s;

    @media @bpMedium {
        max-height: 3em;
    }

    &.CheckoutListShowHide--lg {
        max-height: 4.3em;

        @media @bpMedium {
            max-height: 4em;
        }
    }

    &.is-active {
        max-height: 2000px;
        overflow: visible;
    }
}

.Checkout-list {
    .List-item {
        padding: 0;

        &:last-child {
            border: none;
        }
    }
}

.CheckoutList-trigger {
    font-weight: bold;
    border-bottom: @borderDotted @borderColor;
    cursor: pointer;
    margin: 0.4em 0;
    display: inline-block;

    @media @bpMedium {
        margin: 0.4em 0 0;
    }

    &:hover {
        color: @colorLink;
    }
}

.Checkout-heading {
    @media @bpMedium {
        padding-top: @spaceSmall;
        margin-bottom: @spaceSmall;
    }
}

.Checkout-summaryWrapper {
    background: @colorSilkTint;
    padding: @spaceSmall;

}

.Checkout-price {
    float: right;
    font-family: @fontFamilyHeading;
}

.Checkout-summarybox {
    border-top: 2px solid @colorMidGrey;
    padding-top: @spaceMedium;
}

.Checkout-totalPrice {
    font-family: @fontFamilyHeading;
    font-size: 2em;
    float: right;
}


.CheckoutSummarybox-heading {
    font-size: 2em;
}


/*

*/
.Checkout-stickyPrice {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    z-index: 11;

    * {
        color: @colorWhite;
    }

    .CheckoutSticky-Wrapper {
        display: flex;
        justify-content: center;

        @media @bpLarge {
            justify-content: flex-end;
        }
    }

    .CheckboxStickyPrice-heading {
        align-self: center;
    }

    .CheckoutSticky-totalPrice {
        font-size: 1.8em;
        align-self: center;
        display: inline-flex;
        font-family: @fontFamilyHeading;
    }

    &.fadeOut {
        opacity: 0;
        z-index: 0;
    }
}

.Checkout-Options {
    label {
        line-height: 1.2;

        @media @bpMedium {
            line-height: 1.8;
        }
    }

    span {
        font-size: @textSizeSmall;

        @media @bpMedium {
            font-size: @textSizeMedium;
        }
    }
}

.Checkout-coupon {
    .coupon {
        cursor: pointer;
        font-family: @fontFamilyHeading;
        &:hover{
            color: @colorText;
            text-decoration: underline;
        }
    }

    .hide {
        display: none;
    }
}


.Checkout-giftCard{
   
}

.CheckoutGiftCard-remove {
    color: @colorRed;
    margin-left: .4rem;

    &:after {
        content: "\e919";
        font-family: HomeIcons;
        font-size: 12px;
        margin-left: .2rem;
    }
}