﻿.StorePageCampaignBlock {
    &--oneThird img {
        @media @bpMedium {
            aspect-ratio: 1;
            object-fit: cover;
            max-height: 393px;
        }
    }

    &--preamble, &--heading {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--half {
        .StorePageCampaignBlock--content {
            align-self: flex-end;
        }

        @media @bpMedium {
            aspect-ratio: 600/400;
        }
    }

    &--full {
        .StorePageCampaignBlock--content {
            align-self: flex-end;
        }

        @media @bpMedium {
            aspect-ratio: 1220/400;
        }
    }

    &--heading {
        text-shadow: 0px 4px 50px rgba(0, 0, 0, 0.50);
    }

    &--cta {
        position: relative;
        display: flex;
        justify-content: flex-end;

        .StorePageCampaignBlock--ctaText {
            .Heading--h5;
            background: @colorOrange;
            color: @colorBlack !important;
            display: flex !important;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 20px;
            transform: rotate(13deg);
            width: 128px;
            height: 128px;
            border-radius: 64px;

            @media @bpMedium {
                .Heading--h3;
                width: 200px;
                height: 200px;
                border-radius: 100px;
            }
        }
    }
}
