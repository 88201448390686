.KitchenAccordionBlock {
    
    &-infoArea {
        display: flex;
        flex-direction: column;
        margin-bottom: 28px;

        &-buttonGroup {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            
            .KitchenBlockLink;

            a {
                width: 100%;
                margin-top: 16px;
            }

            @media @bpMedium {
                flex-direction: row;
                gap: 16px;
                margin-top: 0;
                a {
                    width: fit-content;
                    margin-top: 32px
                }
            }
        }

        @media @bpMedium {
            margin-bottom: 0;
        }
    }

    &-accordionArea {
        > .Accordion {

            > .Accordion-header {
                background-color: @colorGrey;
                padding: 12px;
                margin-bottom: 12px;
                cursor: pointer;
            }

            > .Accordion-panel {
                padding: 0 12px;
            }
        }
    }
}