/*#

Dispaly how many items there is

Examples:
<div style="width:50px; position:relative; height:60px;">
    <span class="Quantity">38</span>
</div>
*/

@import "../../common/variables.less";

.SummaryBox {
    background: @colorSilkRich;
    padding: 0 @spaceMedium;

    * {
        color: @colorWhite;
    }
}

.SummaryBox-price{
    font-size: 2em;
}


@media print {
    .SummaryBox{
            padding: 0;
            .SummaryBox-price{
    font-size: 14pt!important;
}
    }
}