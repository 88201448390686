/*#

Shows when you have scrolled down on the page

Examples:
<div>
     <div class="Circle Circle--dark js-scrollTop ScrollTop" style="opacity:1; position:relative; top:15px; left:15px;" data-module="scroll-top">
                <span class="u-alignSelfCenter"><i class="Icon Icon--arrow-up Icon--scrollTop"></i></span>
            </div>
</div>
*/

@import "../../common/variables.less";

@scrollSpacingMobile: 4.2rem;
@scrollSpacing: 4.5rem;

.ScrollTop {
    display: flex;
    position: fixed;
    right: 1em;
    bottom: 3.5em;
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
    z-index: 101;
    border: 2px solid @colorBlueTint;

    @media @bpLarge {
        right: @spaceLarge;
        bottom: 3.8em;
        border: none;
        bottom: @spaceLarge;
    }

    @media @bpXLarge {
        right: @spaceLarge;
        bottom: 3em;
        border: none;
    }

    &.is-active {
        opacity: 0.8;

        &:hover {
            opacity: 1;
            cursor: pointer;

            .Icon--scrollTop, .Icon--cart {
                color: @colorOrange;
            }
        }
    }

    .Icon--scrollTop {
        position: relative;
        top: -1.2px;
        align-self: center;
        font-size: 0.8em;
        color: @colorWhite;

        @media @bpMedium {
            font-size: 1em;
            top: inherit;
        }
    }

    &.Cart {
        margin-bottom: 9rem;
        color: @colorWhite
    }
}


.ScrollTop {
    &.Cart {
        opacity: 1;
        visibility: visible;
        position: fixed;
        right: 1em;
        bottom: 3.5rem;
        @media @bpLarge {
            visibility: hidden;
        }
    }
}


    .is-no-flexbox {
        .Icon--scrollTop {
            position: relative;
            top: 18px;
        }
    }


.QuestionButton {
    margin-bottom: @scrollSpacingMobile;

    @media @bpMedium {
        margin-bottom: @scrollSpacing;
    }
}