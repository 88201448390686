﻿@import (reference) "../common/variables.less";

.u-borderAz{
    border:none!important;
}

.u-borderBz{
    border-bottom:none!important;
}

.u-borderTz{
    border-top:none!important;
}

.u-borderA{
    border: 1px solid @colorBlueTint;
}


.u-linkBorder {
    border-bottom: 1px solid @borderColor;

    &:hover {
        border-bottom: 1px solid @colorOrange;
    }
}

.u-borderBdotted{
    border-bottom: @borderDotted @borderColor;
}

.u-borderTdotted{
    border-top: @borderDotted @borderColor;
}

.u-borderAdotted{
	border:@borderDotted @borderColor;
}

.u-borderBsolid{
    border-bottom: 1px solid @borderColor!important;
}

.u-borderTsolid {
  border-top: 1px solid @borderColor !important;
}

.u-borderLsolid {
    border-left: 1px solid @borderColor !important;
}

.u-borderAsolid{
    border: 1px solid @borderColor!important;
}

.u-borderLarge{ 
    border-width: 4px!important;
}

.u-borderDark{
    border-color: @colorText!important;
}