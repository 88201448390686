/*#

Give a short description of the component's use case and its options. 
Markdown is allowed for rich editing.

Examples:

<div>
    <!-- replace this comment with example HTML -->    
</div>

*/

@import "../../common/variables.less";

.Section {
    padding: @spaceSmall;
    border-top: 1px solid #dce7f1;
    border-bottom: 1px solid #dce7f1;
    margin-bottom: @spaceGrid;
    overflow: hidden;

    @media @bpLarge {
        padding: @spaceMedium;
    }

    .Page-container {
        padding: 0;
    }

    &--paddingSm {

     /*   .Page-container {
            padding: @spaceLarge;

            @media @bpMedium {
                padding: 0;
            }
        }*/
    }

    &.Section--lightBlue {
        overflow: visible;
    }
}

    .Section-heading {
        text-align: center;
    }

    .Section--lightBlue {
        background: @colorSection;

        .SplitBorder-text {
            background: @colorSection;
        }
    }

.Section--alt1 {
    background: @colorMossTint;
}

.Section--alt2 {
    background: @colorMahoganyTint;
}

.Section--alt3 {
    background: @colorGrey;

    .SplitBorder-text {
        background: @colorGrey;
    }
}