﻿/*#

Search area

Examples:


  <div class="Search js-showSearch Header-Search u-transitionMHmd">
    <div class="Page-container">
        <div class="Search-content">
            <div class="Grid Grid--gutterHlg">
                <div class="Grid-cell u-size1of1 u-md-size2of5">
                    <label class="Search-heading">Sök på electrolux home</label>
                    <div class="Search-field">
                        <input type="search" class="Search-input" data-module="menu" data-menu="contentElement: '.js-autocomplete', cssClass: 'is-active'" />
                        <button class="Icon Icon--search SearchInput-btn"></button>
                        <div class="Search-autocomplete js-autocomplete">
                            <ul class="ui-autocomplete List SearchAutocomplete-list">
                                <li class="ui-menu-item List-item" role="presentation"><a href="#" class="ui-corner-all">kylskåp</a></li>
                                <li class="ui-menu-item List-item" role="presentation"><a href="#" class="ui-corner-all">kyl</a></li>
                                <li class="ui-menu-item List-item" role="presentation"><a href="#" class="ui-corner-all">kyl och frys</a></li>
                                <li class="ui-menu-item List-item" role="presentation"><a href="#" class="ui-corner-all">kyl och frys paket</a></li>
                                <li class="ui-menu-item List-item" role="presentation"><a href="#" class="ui-corner-all">kyl frys</a></li>
                            </ul>
                            <div class="Search-realated">
                                <p class="Search-heading">
                                    Realaterat sök för kyl
                                </p>
                                <ul class="List SearchRealated-list  List--horizontal">
                                    <li class="List-item"><a href="#" class="u-textTruncate">Kylar frysar frysboxar</a></li>
                                    <li class="List-item"><a href="#" class="u-textTruncate">Rätt kyl frys</a></li>
                                    <li class="List-item"><a href="#" class="u-textTruncate">Välj rätt kyl</a></li>
                                    <li class="List-item"><a href="#" class="u-textTruncate">Kombinerad kyl frys kombo, alltid redo för kyld dryck</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Grid-cell u-md-size3of5 u-posRelative u-hidden u-md-block">
                    <i class="Icon Icon--close Search-close" data-module="toggler" data-toggler="contentElement: '.js-showSearch', cssClass: 'is-active'"></i>
                    <label class="Search-heading">Vanliga sökningar</label>
                    <a href="#" class="Tag">Erbjudanden</a>
                    <a href="#" class="Tag">Boka möte</a>
                    <a href="#" class="Tag">Boka möte</a>
                    <a href="#" class="Tag">Kök</a>
                    <a href="#" class="Tag">Dammsugare</a>
                    <a href="#" class="Tag">Frys</a>
                    <a href="#" class="Tag u-hidden u-transitionMHmd js-tags">Sentense</a>
                    <a href="#" class="Tag u-hidden u-transitionMHmd js-tags">Vitvaror</a>
                    <a href="#" class="Tag u-hidden u-transitionMHmd js-tags">Dammsugare</a>
                    <a href="#" class="Tag u-hidden u-transitionMHmd js-tags">Frys</a>
                    <a href="#" class="Tag  u-hidden u-transitionMHmd js-tags">Sentense</a>
                    <a href="#" class="Tag u-hidden u-transitionMHmd js-tags">Vitvaror</a>
                     <a href="#" data-module="toggler" class="Search-tabLoad" data-toggler="contentElement: '.js-tags', cssClass: 'u-inlineBlock', addClassToSelf: true"></a>
                </div>
            </div>
        </div>
    </div>
  </div>


*/

@import "../../common/variables.less";

.Header-Search {
    .Page-container {
        padding-left: @spaceSmall;
        padding-right: @spaceSmall;

        @media @bpMedium {
            padding-left: @spaceMedium;
            padding-right: @spaceMedium;
        }

        @media @bpXLarge {
            padding-left: 0;
            padding-right: 0;
        }
    }

    display: inline-block;
    width: 100%;

    &.is-active {
        .Search-close {
            display: inline-block;
        }

        .Device-SearchClose {
            display: none;
        }
    }
}

.Search {
   width: 100%;
   display: flex;
   justify-content: center;
}

.Search-content {
    padding: @spaceSmall;
    position: relative;
    width: 342px;
    height: 48px;
}



.is-active {
    
}

.SearchContent--opacityfull {
    opacity: 1;
}

.Search.u-showHide.is-active {
    overflow: visible;
}

.Search-heading {
    font-size: @textSizeSmall;
    text-transform: uppercase;
    font-family: @fontFamilyPrimary;
    display: block;
    margin-bottom: @spaceXSmall;

}

input.Search-input {
    margin: 0;
    font-size: @textSmall;
    text-indent: @spaceXSmall;
    background: rgba(0,0,0,0);
    min-height: 48px;
    padding-left: 0;
    border: none;
    outline: none;
    box-shadow: none;
    z-index: 3;
    position: relative;

    @media @bpLarge {
    }
}

.is-sticky {
    .Search {
        display: none;
        &.Search-visible {
            display: flex;
        }
    }
}

.search-hidden {
    .Search {
        visibility: hidden;
    }
}

.hide {
    display: none;
}



.Search-field {
    position: relative;
    border-radius: 24px;
    padding: 0 24px;
		background-color: #aaa;
		opacity:70%;
    @media @bpMedium {
      
    }

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        opacity: 70%;
        background-color: @colorWhite;
        border-radius: 24px;
        z-index: 1;
    }
}

.Device-SearchClose{
    display: none;
}

.Search-tabLoad {
    font-family: @fontFamilyPrimary;
    border-bottom: @borderDotted @borderColor;
    margin-left: @spaceXSmall;

    &:after {
        content: "Visa fler";
    }

    &.u-inlineBlock {
        &:after {
            content: "Dölj";
        }
    }
}
.SearchContent-noClose {
    .Search-close {
        display: none;
    }

    .Search-field {
        width: 100%;
    }
}

.Search-icon {
    position: absolute;
    top: 55%;
    right: 15px;
    cursor: pointer;
    font-size: 1.2em;
    margin-top: -.6em;
}


.Search-close {
    position: absolute;
    top: 50%;
    right: -30px;
    cursor: pointer;
    font-size: 1.2em;
    margin-top: -.6em;
    display: none;
}

.SearchPage-close {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    font-size: 1.2em;
    margin-top: -.6em;
}

.Search-realated {
    background: @colorBlueTint;
    padding: @spaceMedium;
    border-top: @borderDotted @borderColor;
}

.SearchRealated-list {
    margin: 0;

    .List-item {
        width: 50%;
        padding: 0 @spaceSmall;
        display: inline-block;

        a {
            display: inline-block;
            border-bottom: @borderDotted @borderColor;
        }
    }
}


//
.is-no-flexwrap {
    input[type=search].Search-input {
        padding: 5px;
        border-color: #ddd;
    }
}
