﻿.u-transitionAsm{
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition:  ease-in-out 0.2s;
    -o-transition:  ease-in-out 0.2s;
    transition:  ease-in-out 0.2s;
}

.u-transitionAmd {
    -webkit-transition: ease-in-out 0.5s;
    -moz-transition:  ease-in-out 0.5s;
    -o-transition:  ease-in-out 0.5s;
    transition:  ease-in-out 0.5s;
}

.u-transitionAlg {
    -webkit-transition: ease-in-out 0.9s;
    -moz-transition:  ease-in-out 0.9s;
    -o-transition:  ease-in-out 0.9s;
    transition:  ease-in-out 0.9s;
    /*-webkit-transition-delay: 2s;
    transition-delay: 2s;*/
}

.u-transitionMHsm{
    -webkit-transition: max-height ease-in-out 0.2s;
    -moz-transition: max-height ease-in-out 0.2s;
    -o-transition: max-height ease-in-out 0.2s;
    transition: max-height ease-in-out 0.2s;
}

.u-transitionMHmd{
    -webkit-transition: max-height ease-in-out 0.5s;
    -moz-transition: max-height ease-in-out 0.5s;
    -o-transition: max-height ease-in-out 0.5s;
    transition: max-height ease-in-out 0.5s;
}


.u-transitionPaddingmd{
    -webkit-transition: padding ease-in-out 0.5s;
    -moz-transition: padding ease-in-out 0.5s;
    -o-transition: padding ease-in-out 0.5s;
    transition: padding ease-in-out 0.5s;
}

.u-showHide{
    max-height:0;
    overflow:hidden;
    &.is-active{
        max-height:800px;

        &.showHidelg{
            max-height: 2000px;
        }
    }
}

