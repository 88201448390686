@media print {
	.u-printHide {
		display: none !important;
	}

	.u-printShow {
		display: block !important;
	}

	.u-printSizeFull {
		width: 100% !important;
		display: block !important;
	}

	h1, .Heading--h1 {
		font-size: 22pt !important;
	}

	h2, .Heading--h2 {
		font-size: 16pt !important;
	}

	h3, .Heading--h3 {
		font-size: 14pt !important;
	}

	h4, .Heading--h4 {
		font-size: 12pt !important;
	}

	h5, h6, .Heading--h5, .Heading--h6, .ProductCard-heading {
		font-size: 10pt !important;
	}

	p, ul li, dl dt, dl dd {
		font-size: 10pt !important;
	}

	span {
		font-size: 10px !important;
	}

	span.u-printKeepPrice {
		font-size: 16pt !important;
	}

	.Card-ProductPage .Slider-item img {
		object-fit:contain !important;
		margin: 0 !important;
		width: 250px !important;
		height: 250px !important;
	}

	.u-textLarge * {
		font-size: 8pt !important;
	}

	.Section, .u-colorBGlightBlue {
		background: #fff;
		border: none !important;
	}

	/*don't dispalay those items*/

	iframe,
	.EPiServerForms,
	.Article-share {
		display: none !important;
	}
}
