.KitchenArticleList {
    
    &-Article {
        padding: 16px 0;
        border-top: 1px solid @colorBlack;
        
        &:last-child {
            border-bottom: 1px solid @colorBlack;
        }
    }
}