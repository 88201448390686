﻿/*#
Examples:

<div>
   <p class="Property">
        <span class="Property-key">Öppettider:</span>
        <span class="Property-value">måndag - torsdag 09:00 - 17:00, fredag 10:00 - 16:00<br />Julafon Stängt</span>
   </p>
</div>

*/

@import "../../common/variables.less";

.Property{

}

.Property-key{ 
    display:inline-block;
    margin-right: @spaceXSmall;
}

.Property-value{ 
    display:inline-block;
    vertical-align:top;
}