﻿@import (reference) "../common/variables.less";

/**
 * Text font size.
 */

.u-textXSmall {
    font-size: @textSizeXSmall !important;
}
.u-textSmall {
    font-size: @textSizeSmall !important;
}
.u-textDefault {
    font-size: @textSizeDefault !important;
}
.u-textMedium, 
.u-textNormal {
    font-size: @textSizeMedium !important;
}
.u-textLarge {
    font-size: @textSizeLarge !important;
}
.u-textXLarge {
    font-size: @textSizeXLarge !important;
}
.u-textXXLarge
{
    font-size: @textSizeXXLarge!important;
}
/**
 * Text font weights
 */

.u-textWeightThin {
    font-weight: 100 !important;
}
.u-textWeightLight {
    font-weight: 200 !important;
}
.u-textWeightNormal {
    font-weight: normal !important;
}
.u-textWeightMedium {
    font-weight: 500 !important;
}

.u-textWeightSemiBold {
    font-weight: 600 !important;
}

.u-textWeightBold {
    font-weight: bold !important;
}


/*font family*/
.u-fontFamilyElux--light {
    font-family: @fontFamilyHeading--light;
}
.u-fontFamilyElux--regular {
    font-family: @fontFamilyPrimary;
}
.u-fontFamilyElux--semiBold {
    font-family: @fontFamilyHeading;
}
.u-fontFamilyCondensed {
    font-family: @fontFamilyHeading !important;
    letter-spacing: -.05em;
}
    /**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */
    .u-textBreak {
        word-wrap: break-word !important;
    }

    .u-wordBreak {
        word-break: break-word !important;
    }
    /**
 * Horizontal text alignment
 */
    .u-textCenter {
        text-align: center !important;
    }

    .u-textLeft {
        text-align: left !important;
    }

    .u-textRight {
        text-align: right !important;
    }

    .u-textCenterDesktop {
        text-align: left !important;

        @media @bpMedium {
            text-align: center !important;
        }
    }


    .u-textUppercase {
        text-transform: uppercase !important;
    }

    .u-textTransformnone {
        text-transform: none !important;
    }

    .u-textUnderline{
      text-decoration:underline!important;
    }
    /**
 * Prevent whitespace wrapping
 */
    .u-textNoWrap {
        white-space: nowrap !important;
    }
    /**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
    .u-textTruncate {
        max-width: 100%; /* 1 */
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        word-wrap: normal !important; /* 2 */
    }
    /*
 * Inherit the ancestor's text color.
 */
    .u-textInheritColor {
        color: inherit !important;
    }

    .u-lineHeightsm {
        display: block;
        line-height: 1em;
    }

    .is-no-flexbox {
        .u-textCenterDesktop.u-alignCenter {
            display: block !important;
        }
    }

.u-textUnderline {
    text-decoration: underline;
}

.u-fs13 {
    font-size: @fs-13;
}