﻿@-webkit-keyframes boom {
	25% {
		-moz-transform: scale(1.25);
		-ms-transform: scale(1.25);
		-webkit-transform: scale(1.25);
		transform: scale(1.25);
	}
}

@-moz-keyframes boom {
	25% {
		-moz-transform: scale(1.25);
		-ms-transform: scale(1.25);
		-webkit-transform: scale(1.25);
		transform: scale(1.25);
	}
}

@-ms-keyframes boom {
	25% {
		-moz-transform: scale(1.25);
		-ms-transform: scale(1.25);
		-webkit-transform: scale(1.25);
		transform: scale(1.25);
	}
}

@-o-keyframes boom {
	25% {
		-moz-transform: scale(1.25);
		-ms-transform: scale(1.25);
		-webkit-transform: scale(1.25);
		transform: scale(1.25);
	}
}

@keyframes boom {
	25% {
		-moz-transform: scale(1.25);
		-ms-transform: scale(1.25);
		-webkit-transform: scale(1.25);
		transform: scale(1.25);
	}
}

@keyframes lds-spinner {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
